import { React, useState, useEffect, useRef } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { reduxSelectValueFor } from '../redux/selectors';
import { maybePopulateField } from '../redux/common';
//import i18n from 'i18next';


export default function ImeField(props) {
    const store = useStore();

    const [fidPrefix, setFidPrefix] = useState(typeof(props.prefix) === 'string' ? props.prefix : '');

    const initIsReadOnly = (typeof (props.isReadOnly) === 'undefined' || props.isReadOnly === false) ? false : true;
    const [isReadOnly, setIsReadOnly] = useState(initIsReadOnly);
    const [inputClasses, setInputClasses] = useState(initIsReadOnly ? 'valid' : 'validate');
    
    const imeInput = useRef(null);
    const priimekInput = useRef(null);

    const { t, i18n } = useTranslation(['translation', 'validation', 'flashes']);

    const handleImeChange = (event) => {
       
        props.onImeChange(event);
    };

    const handlePriimekChange = (event) => {
        props.onPriimekChange(event);
    };

    useEffect(() => {
        let storeData = store.getState();

        maybePopulateField(
            imeInput, 
            reduxSelectValueFor(storeData, imeInput)
        );

        maybePopulateField(
            priimekInput,
            reduxSelectValueFor(storeData, priimekInput)
        );
        
    }, []);

    return (
        <>
            <div className="input-field col s12 m6">
                <input ref={imeInput} id={fidPrefix + "ime"} type="text" name={fidPrefix + "ime"} 
                    required 
                    className={inputClasses} 
                    readOnly={isReadOnly} 
                    onChange={handleImeChange} 
                />
                <label htmlFor={fidPrefix + "ime"}>
                    <Trans>field.label.firstName</Trans>
                </label>
                <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
            </div>
            <div className="input-field col s12 m6">
                <input ref={priimekInput} id={fidPrefix + "priimek"} type="text" name={fidPrefix + "priimek"} 
                    required 
                    className={inputClasses} 
                    readOnly={isReadOnly}
                    onChange={handlePriimekChange} 
                />
                <label htmlFor={fidPrefix + "priimek"}>
                    <Trans>field.label.lastName</Trans>
                </label>
                <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
            </div>
        </>

    );
    
}
