import { parseFieldName } from '../common';
import { ZAVAROVANEC_SOGLASJE_UPDATED } from '../types/zavarovanaOsebaType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case ZAVAROVANEC_SOGLASJE_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        default:
            return state;
    }
};