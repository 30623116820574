import { parseFieldName } from '../common';
import naslovReducer from './naslovReducer';
import osebniDokumentReducer from './osebniDokumentReducer';
import amTrustReducer from './amTrustReducer';
import { ZAVAROVALEC_UPDATED, ZAVAROVALEC_NASLOV_UPDATED, ZAVAROVALEC_OSEBNI_DOKUMENT_UPDATED,
    ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED, ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED } from '../types/zavarovalecType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case ZAVAROVALEC_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case ZAVAROVALEC_NASLOV_UPDATED:
            let naslov = {};
            if (typeof (state.naslov) !== 'undefined') {
                naslov = state.naslov;
            }

            return { ...state, naslov: naslovReducer(naslov, action) };
        
        case ZAVAROVALEC_OSEBNI_DOKUMENT_UPDATED:
            let osDok = {};
            if (typeof(state.osebniDokument) !== 'undefined') {
                osDok = state.osebniDokument;
            }
            return {...state, osebniDokument: osebniDokumentReducer(osDok, action)}
        
        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED:
        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED:
            let gdpr = {};
            if (typeof (state.amTrust) !== 'undefined') {
                gdpr = state.amTrust;
            }

            return { ...state, amTrust: amTrustReducer(gdpr, action) };
        default:
            return state;
    }
};