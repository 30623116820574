import { parseFieldName } from '../common';
import zavarovanaOsebaPristopAbiIzjavaGdprReducer from './zavarovanaOsebaPristopAbiIzjavaGdprReducer';
import {
    ZAVAROVANEC_PRISTOP_ABI_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED } from '../types/zavarovanaOsebaType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case ZAVAROVANEC_PRISTOP_ABI_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED:
        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED:
            let gdpr = {};
            if (typeof (state.izjavaGDPR) !== 'undefined') {
                gdpr = state.izjavaGDPR;
            }

            return { ...state, izjavaGDPR: zavarovanaOsebaPristopAbiIzjavaGdprReducer(gdpr, action) };

        default:
            return state;
    }
};


