import { parseFieldName } from '../common';
import { NACIN_PLACILA_UPDATED } from '../types/nacinPlacilaType';

const INITIAL_STATE = {
    nacin: "SEPA",
};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case NACIN_PLACILA_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        default:
            return state;
    }
};