import { Link } from 'react-router-dom';

export default function TySklenjenoZavarovanjePrvaDiagnozaRoute() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h4>Vaše zavarovanje je v obdelavi</h4>

                <p>
                    Veseli smo, da ste se nam pridružili v skrbi za svoje zdravje in zdravje vaših najbližjih.
                    <br /><br />
                    Vso dokumentacijo boste prejeli po elektronski pošti.
                </p>

                <Link to={"/medicare-izbira-produkta"}>
                    <span className="btn-large eureko-green eureko-blue-text eureko-form-action-btn">
                        Dodaj zavarovano osebo
                    </span>
                </Link>
            </div>
        </div>

    );
}
