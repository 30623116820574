import React from "react";
import { Link } from "react-router-dom";

export default function HeaderDrBestSi(props) {
    return (
        <nav>
            <div className="nav-wrapper indigo darken-4 " style={{background: '#0F2B62', display: 'flex' }}>
                <ul id="nav-mobile" className="left" style={{ display: 'flex' }}>
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                        <img 
                            src="/img/logo.png" 
                            alt="Dr Best logo" 
                            style={{ display: 'flex', maxHeight: '48px', padding: '10px' }} 
                        />
                    </li>
                </ul>
            </div>
        </nav>
    );
}

