import { parseFieldName } from '../common';
import amTrustIzjavaGdprReducer from './amTrustIzjavaGdprReducer';
import { ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED, ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED } from '../types/zavarovalecType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED:
        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED:
            let gdpr = {};
            if (typeof (state.izjavaGDPR) !== 'undefined') {
                gdpr = state.izjavaGDPR;
            }

            return { ...state, izjavaGDPR: amTrustIzjavaGdprReducer(gdpr, action) };

        default:
            return state;
    }
};


