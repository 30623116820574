import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AgentLinkValidator from '../other/AgentLinkValidator';
import { reduxSelectMetaAgentParams } from '../redux/selectors';

export default function IzbiraPotiRoute() {
    const selectAgentParams = useSelector(reduxSelectMetaAgentParams);
    const [disabledClass, setDisabledClass] = useState('disabled');

    useEffect(() => {
        if (typeof(selectAgentParams) !== 'string' || selectAgentParams === '') {
            setDisabledClass('');
        }
    }, []);

    return (
        <AgentLinkValidator>
            <div className="row">
                <div className="col s12 center">
                    <h3>Izbira poti</h3>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m4 offset-m2">

                    <div className="card center">
                        <span className="card-title ">
                            <br />
                            Sklepanje na daljavo
                        </span>
                        <Link to={"/vstop" + selectAgentParams} ><span className={"btn-large btn-floating halfway-fab waves-effect waves-light green disabled"}><i className="material-icons">arrow_forward</i></span></Link>

                        <div className="card-content center">
                            <p>Oddajte privolitev za svetovanje in v spremstvu našega agenta sklenite zavarovanje prilagojeno vam!</p>
                            <br />
                        </div>
                    </div>

                </div>

                <div className="col s12 m4">

                    <div className="card center">
                        <span className="card-title center">
                            <br />
                            Sklepanje v prisotnosti svetovalca
                        </span>
                        <Link to={"/vstop-s-svetovalcem" + selectAgentParams}>
                            <span className={"btn-large btn-floating halfway-fab waves-effect waves-light green " + disabledClass}>
                                <i className="material-icons">arrow_forward</i>
                            </span>
                        </Link>

                        <div className="card-content center">
                            <p>Oddaj privolitev za svetovanje in v spremstvu našega agenta sklenite zavarovanje prilagojeno vam!</p>
                            <br />
                        </div>
                    </div>

                </div>
            </div>
        </AgentLinkValidator>
    );

}