import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import metaReducer from './reducers/metaReducer';
import zavarovalecReducer from './reducers/zavarovalecReducer';
import zavarovanaOsebaReducer from './reducers/zavarovanaOsebaReducer';
import produktReducer from './reducers/produktReducer';
import idd2Reducer from './reducers/idd2Reducer';
//import otherReducer from './reducers/otherReducer';
//import izjavaReducer from './reducers/izjavaReducer';
//import gdprReducer from './reducers/gdprReducer';
import privolitvenaEurekoReducer from './reducers/privolitvenaEurekoReducer';
import sepaReducer from './reducers/sepaReducer';
import rootPonudbaReducer from './reducers/rootPonudbaReducer';
import nacinPlacilaReducer from './reducers/nacinPlacilaReducer';
import produktZavarovanaOsebaArrayReducer from './reducers/produktZavarovanaOsebaArrayReducer';
import appCoreReducer from './app.core/reducers/appCoreReducer';
import currentFlowReducer from './reducers/currentFlowReducer';


const preloadedState = {
    meta: {
        agentParams: '',
        jobSessionId: ''
    }
};

const allReducers = combineReducers({
    rootPonudba: rootPonudbaReducer,

    zavarovalec: zavarovalecReducer,

    produkt: combineReducers({
        rootProdukt: produktReducer,
        zavarovanaOseba: zavarovanaOsebaReducer,  // prefix zavarovanec_ , premija prefix: zavarovanec_premija_
        zavarovanaOsebaArray: produktZavarovanaOsebaArrayReducer,
        opredelitevPotrebInZahtev: idd2Reducer,  // prefix idd2_ (used on fields), but the structure is: produkt_opredelitevpotrebinzahtev_
        nacinPlacila: nacinPlacilaReducer,
        
    }),

    privolitvenaEureko: privolitvenaEurekoReducer,

    sepa: sepaReducer,

    meta: metaReducer,

    appCore: appCoreReducer,

    currentFlow: currentFlowReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'ROOT_APP_RESET') {
        state = undefined;
    }

    return allReducers(state, action);
}


export default configureStore({
    reducer: rootReducer,
    preloadedState
});
