import React from 'react';


export default function Footer(props) {
    return (
        <footer className="page-footer indigo darken-4 eureko-footer">
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <div className="center-align eureko-logo-wrapper">
                            <a href="https://dr-best.si/">
                                <img className="eureko-logo-footer" src="/img/mc-logo-white-text.png" alt="Medicalcare logo" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 m4">

                        <div className="row">
                            <div className="col s12 center">
                                <h5>PODJETJE</h5>
                            </div>
                            <div className="col s12 grey-text text-lighten-4 center">
                                EUREKO, d.o.o.<br />
                                Leskoškova 9E<br />
                                1000 Ljubljana<br />
                            </div>
                        </div>

                    </div>

                    <div className="col s12 m4">
                        
                        <div className="row">
                            <div className="col s12 center">
                                <h5>POSLANSTVO</h5>
                            </div>
                            <div className="col s12 grey-text text-lighten-4 center">
                                Ljudi, ki se soočajo z zdravstvenimi težavami, povezujemo z vodilnimi svetovnimi zdravstvenimi strokovnjaki.
                            </div>
                        </div>

                    </div>

                    <div className="col s12 m4">
                        <div className="col s12 center">
                            <h5>KONTAKT</h5>
                        </div>
                        <div className="col s12 grey-text text-lighten-4 center">
                            Telefon: 080 88 12<br />
                            Email: info@eureko.si
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col s12">
                        <hr />
                    </div>
                    <div className="col s12 m6">
                        <p>
                            Axeria iard (“Axeria”) je francoska zavarovalnica, registrirana v poslovnem registru družb v Lyonu s številko 352 893 200 in s sedežem na naslovu 26 rue du Général Mouton Duvernet, 
69003 Lyon, Francija. Zavarovalnica je regulirana s francosko zavarovalno zakonodajo in deluje pod nadzorom francoskega regulatorja Autorite de Controle Prudentiel et de Resolution 
(“ACPR”), 4 place de Budapest – CS 92459 – 75436 PARIS – CEDEX 09 ter je pri ACPR registrirana pod številko 221942. Zavarovalnica ima, skladno s prostim pretokom storitev znotraj 
EU, dovoljenje za opravljanje zavarovalnih poslov v Sloveniji in je pri ACPR vpisana z referenčno številko 221049
                        </p>
                    </div>
                    <div className="col s12 m6">
                        <p>
                            Further Underwriting International SLU (“Further”), je družba, registrirana v Španiji z davčno številko B83644484 in s sedežem na Paseo de Recoletos 12, Madrid, Španija. Družba 
Further je registrirana pri španskem regulatorju Dirección General de Seguros y Fondos de Pensiones kot neekskluzivni zastopnik zavarovalnice Axeria z licenco številka AJ0241 in je 
pooblaščena za odločanje o zahtevkih v zvezi z zavarovalno pogodbo.
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col s12 center">
                            &copy; {new Date().getFullYear()} MEDICAL CARE
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );    
}

