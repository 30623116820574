import { React } from 'react';
import DaNeField1 from '../../field/DaNeField1';
import PodpisField1 from '../../field/PodpisField1';
import CheckboxMultipleField from '../../field/CheckboxMultipleField';



export default function PrvaDiagnozaGDPRSoglasjeForm({
    model,
}) {

    return (

        <div>
            <fieldset>
                <legend>Privolitve</legend>
                <div className="row">
                    <div
                        className="col s12"
                    >
                        <p>
                        Prvi osebni zavarovalnici, d.d., dajem privolitev, da obdeluje moje osebne podatke, ki jih je pridobila ob sklepanju in izvajanju zavarovalne pogodbe in iz druge dokumentacije (npr. osebno ime, naslov stalnega oz. zacasnega prebivališca, elektronski naslov, telefon, davcna številka, datum rojstva, spol itd.), razen posebnih osebnih podatkov (npr. zdravstvenih podatkov):
                        </p>
                    </div>
                    <div
                        className="col s12"
                    >
                        <DaNeField1
                            isRequired={model.qPrivolitve1.required}
                            onChange={value => model.qPrivolitve1.value = value}
                            name={model.qPrivolitve1.name}
                            questionText={model.qPrivolitve1.text.sl}
                            error={model.qPrivolitve1.error}
                            value={model.qPrivolitve1.value}
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <DaNeField1
                            isRequired={model.qPrivolitve2.required}
                            onChange={value => model.qPrivolitve2.value = value}
                            name={model.qPrivolitve2.name}
                            questionText={model.qPrivolitve2.text.sl}
                            error={model.qPrivolitve2.error}
                            value={model.qPrivolitve2.value}
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <DaNeField1
                            isRequired={model.qPrivolitve3.required}
                            onChange={value => model.qPrivolitve3.value = value}
                            name={model.qPrivolitve3.name}
                            questionText={model.qPrivolitve3.text.sl}
                            error={model.qPrivolitve3.error}
                            value={model.qPrivolitve3.value}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Preklic privolitve</legend>
                <div className="row">
                    <div
                        className="col s12"
                    >
                        <p>
                        Seznanjen sem, da lahko privolitev za obdelavo osebnih podatkov kadarkoli trajno ali začasno, v celoti ali delno preklicem oziroma zahtevam dostop, dopolnitev, popravek, omejitev, prenos, ugovor ali izbris svojih osebnih podatkov. To lahko zahtevam s pisno izjavo, naslovljeno na naslov upravljavca osebnih podatkov: Prva osebna zavarovalnica, d.d., Fajfarjeva 33, 1000 Ljubljana, ali preko elektronske pošte na e-naslov: info@prva.si. Upravljavec bo na zahtevek odgovoril v razumnem roku, skladno z zakonodajo. Preklic privolitve ne vpliva na zakonitost obdelave, ki se je na podlagi privolitve izvajala do njenega preklica.
                        </p>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Izbira načina obveščanja</legend>
                <div className="row">
                    <div
                        className="col s12"
                    >
                        <p>
                        Prosimo označite, kateri način vzpostavitve stika z vami v zvezi z izvajanjem sklenjene pogodbe, vam najbolj ustrezajo:
                        </p>
                    </div>
                    <div
                        className="col s12"
                    >
                        <CheckboxMultipleField
                            isRequired={model.nacinObvescanja.required}
                            isReadOnly={model.nacinObvescanja.readonly}
                            onChange={value => model.nacinObvescanja.value = value}
                            name={model.nacinObvescanja.name}
                            error={model.nacinObvescanja.error}
                            value={model.nacinObvescanja.value}
                            options={model.nacinObvescanja.options}
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <p>
                        Z izbiro načina obvešcanja na elektronski naslov in podpisom obrazca podajam izrecno soglasje Prvi osebni zavarovalnici, d. d., da me o vseh zadevah v zvezi z vsemi sklenjenimi in bodočimi zavarovalnimi pogodbami lahko obvešča izključno po elektronski poti. To vključuje vse obveščanje, kot ga določa Zakon o zavarovalništvu, vključno z informacijami za vlagatelje (t. i. KID-dokumentacija), informacijami o zavarovalnem produktu (t. i. IPID-dokumentacija) ter pošiljanjem UPN in drugih obvestil po elektronski poti. Seznanjen sem, da moram morebitno spremembo elektronskega naslova nemudoma sporočiti zavarovalnici ter da lahko kadar koli prekličem soglasje za obvešcanje, in sicer po elektronski pošti s pisno zahtevo, poslano na sedež zavarovalnice ali na e-naslov info@prva.si. Vsi dokumenti se lahko na zahtevo brezplacno zagotovijo tudi v tiskani obliki. Če ste e-naslov že podali v sklepalni dokumentaciji, ga ni treba znova navesti, temveč samo označite način obveščanja.
                        </p>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Podpisi</legend>
                <div className="row">
                    <div
                        className="col s12"
                    >
                        <p>
                        S podpisom izjavljam, da sem v celoti prebral in razumel vsebino zgornjega obvestila ter da sem seznanjen s Politiko zasebnosti Prve osebne zavarovalnice, d.d., objavljene na www.prva.si, in s svojimi pravicami ter s tem, kako jih lahko uveljavljam. S podpisom potrjujem še, da sem prejel ključne informacije iz navedene politike zasebnosti, ki so povzete v Pravnem obvestilu glede varstva osebnih podatkov strank in so na naslednji strani. V primeru, da sem ob sklepanju zavarovalne pogodbe podal osebne podatke mladoletne osebe (ime, priimek, naslov, sorodstveno razmerje), mlajše od 15 let, s podpisom izjavljam, da privolitev podajam kot starš, rejnik ali skrbnik.
                        </p>
                    </div>
                    <div
                        className="col s12"
                    >
                        <PodpisField1
                            isRequired={model.podpis.required}
                            onChange={value => model.podpis.value = value}
                            name={model.podpis.name}
                            questionText={model.podpis.text.sl}
                            error={model.podpis.error}
                            value={model.podpis.value}
                        />
                    </div>
                </div>
            </fieldset>
            <p>
            Prva osebna zavarovalnica, d.d., Fajfarjeva ulica 33, Ljubljana, matična št.: 2295431, ID za DDV: SI86404024, registrirana pri Okrožnem sodišcu v Ljubljani 5.7.2007, št. vl. 1/46967/00, osnovni kapital 13.100.000,00 EUR
            </p>
        </div>
    );

}


