
import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function DavcnaStevilkaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [errorClass, setErrorClass] = useState('');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const validateVatId = (value) => {
        let maxLen = 8; 

        if (value.length < 8) {
            return false;
        }

        let intValue = parseInt(value), maxDavcna = Math.pow(10, maxLen) - 1;
        if (isNaN(intValue)) {
            return false;
        }

        if (intValue > maxDavcna) {
            return false;
        }

        // Value format.
        let tabelaDavcna = [];
        for (let i = (maxLen - 1); i >= 0; i--) {
            tabelaDavcna[i] = intValue % 10;
            intValue -= tabelaDavcna[i];
            intValue /= 10;
        }

        let sestevekZmnozkov = 0;
        for (let pos = 0; pos < maxLen - 1; pos++) {
            sestevekZmnozkov = (maxLen - pos) * tabelaDavcna[pos] + sestevekZmnozkov;
        }

        let ostanekPriDeljenju = sestevekZmnozkov % 11;
        let kontrolnaStevilka = 11 - ostanekPriDeljenju;
        if (kontrolnaStevilka == 10) {
            kontrolnaStevilka = 0;
        }
        else if (kontrolnaStevilka == 11) {
            // invalid kontrolna stevilka
            return false;
        }

        if (kontrolnaStevilka != tabelaDavcna[maxLen - 1]) {
            // invalid kontrolna stevilka
            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        let vatIdValid = validateVatId(event.target.value);
        if (!vatIdValid) {
            setErrorClass('invalid');
            event.target.setCustomValidity('Invalid value');
        }
        else {
            setErrorClass('valid');
            event.target.setCustomValidity('');
        }

        props.onChange(event);
    };

    useEffect(() => {
        if (typeof(props.isReadOnly) === 'undefined') {
            setIsReadOnly(false);
        }
        else {
            setIsReadOnly(props.isReadOnly);
        }
        
    }, [props.isReadOnly]);

    return (
        <div className="input-field col s12">
            <input 
                id={fidPrefix + "davcnaStevilka"} 
                type="text" 
                name={fidPrefix + "davcnaStevilka"} 
                className="validate" 
                onChange={handleChange} 
                onBlur={handleChange} 
                onInput={handleChange} 
                required 
                readOnly={isReadOnly}
            />
            <label 
                htmlFor={fidPrefix + "davcnaStevilka"}
            >
                <Trans>field.label.vatId</Trans>
            </label>
            <span 
                className="helper-text" 
                data-error={i18n.t('validation:field.validate.vatIdRequired')} 
                data-success=""
            >
            </span>
        </div>
    );
}

