import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DateInputField({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    isReadOnly,
    validateFn,
    value,
    conf
}) {

    const configuration = {
        deltaMin: conf?.deltaMin || null,
        deltaMax: conf?.deltaMax || null,
        deltaZavarovalniskaStarostMin: conf?.deltaZavarovalniskaStarostMin || null,
        deltaZavarovalniskaStarostMax: conf?.deltaZavarovalniskaStarostMax || null,
    }

    const [ validationError, setValidationError ] = useState({ hasError: false });
    const [ inputValue, setInputValue ] = useState('');

    useEffect(() => {
        setValidationError(error);
    }, [error]);

    useEffect(() => {
        if (value !== '')
            setInputValue(dateToStringValue(value));
    }, [ value ]);

    const { t } = useTranslation();

    const handleChange = (event) => {
        const userInput = event.target.value;

        let stringValue = userInput;
        const prevValue = inputValue;

        try {
            if (
                userInput.length === 2 
                && !isNaN(Number(userInput)) 
                && !prevValue.endsWith('.')
            ) stringValue = userInput + '.';
            else if (
                userInput.length === 5 
                && !isNaN(Number(userInput.split('.')[1])) 
                && !prevValue.endsWith('.')
            ) stringValue = userInput + '.';            
        } catch (e) {
            console.log(e);
        }

        setInputValue(stringValue);

        const dateValue = stringValue.length === 10 ?
            parseDateFromStringValue(stringValue) : new Date('invalid');

        let isoString = '';
        try {
            isoString = dateValue.toISOString();
        } catch (error) {
            
        }
        if (onChange)
            onChange(isoString);

        if (validateFn) {
            validateFn(isoString)
                .then(errors => {
                    setValidationError(errors);
                });
        }
    };

    return (
        <div
            className={'input-field' + (validationError?.hasError ? ' invalid' : '')}
        >
            <input 
                id={name} 
                type="text" 
                name={name} 
                required={isRequired} 
                readOnly={isReadOnly} 
                onChange={handleChange}
                onBlur={handleChange}
                className={ validationError?.hasError ? 'invalid' : '' }
                value={inputValue}
            />
            <label 
                htmlFor={name}
                className={inputValue ? 'active' : ''}
            >
                {questionText}
            </label>
            <span 
                className="helper-text"
                hidden={!validationError?.hasError}    
            >   
                {
                    validationError?.required ?
                    'Prosimo izpolnite to polje'
                    : ''
                }
                {
                    validationError?.invalidDate ?
                    'Prosimo vnesite veljavni datum'
                    : ''
                }
                {
                    validationError?.invalidDelta ?
                    t('validation:field.validate.dateBirthRequired', { min: getMinLetString(configuration), max: getMaxLetString(configuration) })
                    : ''
                }
                {
                    validationError?.invalidZavarovalniskaStarostDelta ?
                    t('validation:field.validate.dateBirthZavarovalniskaStarost', { min: getMinZavarovalniskaStarostString(configuration), max: getMaxZavarovalniskaStarostString(configuration) })
                    : ''
                }
                {
                    validationError?.error || ''
                }
            </span>
        </div>
    );
    
}

const getMinLetString = (configuration) => {
    if (configuration.deltaMin === null) {
        return '[brez omejitve]';
    }

    return configuration.deltaMin;
};

const getMaxLetString = (configuration) => {
    if (configuration.deltaMax === null) {
        return '[brez omejitve]';
    }

    return configuration.deltaMax;
};

const getMinZavarovalniskaStarostString = (configuration) => {
    if (configuration.deltaZavarovalniskaStarostMin === null) {
        return '[brez omejitve]';
    }

    return configuration.deltaZavarovalniskaStarostMin;
};

const getMaxZavarovalniskaStarostString = (configuration) => {
    if (configuration.deltaZavarovalniskaStarostMax === null) {
        return '[brez omejitve]';
    }

    return configuration.deltaZavarovalniskaStarostMax;
};

function parseDateFromStringValue (stringValue) {
    const parts = stringValue.split('.');
    // Date Parsing
    let objParts = {
        year: '',
        month: '',
        day: ''
    }, intVal;
    if (parts.length === 3) {
        intVal = parseInt(parts[0]);
        if (parts[0].length === 2 && !isNaN(intVal)) {
            objParts.day = parts[0];
        }
        
        intVal = parseInt(parts[1]);
        if (parts[1].length === 2 && !isNaN(intVal)) {
            objParts.month = parts[1];
        }
        
        intVal = parseInt(parts[2]);
        if (parts[2].length === 4 && !isNaN(intVal)) {
            objParts.year = parts[2];
        }
    }

    // Date validation.
    let dateObj = new Date((objParts.year + '-' + objParts.month + '-' + objParts.day));

    return dateObj;
}


/**
 * 
 * @param {Date} date 
 * @returns 
 */
function dateToStringValue (
    dateIsoString
) {
    if (!dateIsoString) return '';
    const date = new Date(dateIsoString);
    return padWithZero(date.getDate()) + '.' + padWithZero(date.getMonth() + 1) + '.' + date.getFullYear();
}

/**
 * 
 * @param {string|number} input 
 */
function padWithZero (input) {
    const inp = String(input);

    if (inp.length === 1) return '0' + inp;
    else return inp;
}