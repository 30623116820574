import { React, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { getActionType, parseFieldName, inputValueToUpperCase} from '../redux/common';
import DatumRojstvaField from './DatumRojstvaField';
import ImeField from './ImeField';
import { 
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED,
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED,
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED
    } from '../redux/types/zavarovanaOsebaType';



export default function DediscinaDrugeOsebeField(props) {

    // !!! Field name: ostali !!!
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [hideClass, setHideClass] = useState('');
    const [upravicenci, setUpravicenci] = useState([]);
    const [upCounter, setUpCounter] = useState(1);
    const dispatch = useDispatch();

    const compFieldName = fidPrefix + "seznamUpravicencev";
    const upravicenecNew = {
        ime: '',
        priimek: '',
        datumRojstva: '',
        id: null
    };

    const dediscinaType = useSelector((state) => {

        if (typeof (state.produkt) === 'undefined') {
            return null;
        }
        else if (typeof (state.produkt.zavarovanaOseba) === 'undefined') {
            return null;
        }
        else if (typeof (state.produkt.zavarovanaOseba.upravicenecSmrt) === 'undefined') {
            return null;
        }
        else if (typeof (state.produkt.zavarovanaOseba.upravicenecSmrt) === 'undefined') {
            return null;
        }
        else if (typeof (state.produkt.zavarovanaOseba.upravicenecSmrt.dediscinaType) === 'undefined') {
            return null;
        }

        return state.produkt.zavarovanaOseba.upravicenecSmrt.dediscinaType;
    });

    useEffect(() => {
        if (dediscinaType === 'OSTALI') {
            // Lets show the first row to user so they do not have to click "Dodaj upravicenca".
            let obj = { ...upravicenecNew };
            obj.id = getAndIncrement();
            setUpravicenci([{...obj}]);
            
            dispatch({
                type: ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED,
                payload: {
                    field: compFieldName,
                    value: obj
                }
            });

            setHideClass('');
        }
        else {
            setUpravicenci([]);

            dispatch({
                type: ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED,
                payload: {
                    field: compFieldName,
                    value: '__all__'
                }
            });

            setHideClass('hide');
        }
    }, [dediscinaType]);

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const getAndIncrement = () => {
        let nextId = upCounter;
        setUpCounter( (nextId + 1) );
        return nextId;
    };

    const handleChange = (event) => {
        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name), objectId = parseInt(fieldInfo.prefix.slice(1));

        //type = getActionType(fieldInfo.prefix, 'UPDATED');
        let normalizedValue = event.target.value, upravicenec, index;
        normalizedValue = inputValueToUpperCase(normalizedValue);
        
        // Update object value.
        index = upravicenci.map((element) => element.id).indexOf(objectId);
        upravicenec = upravicenci[index];

        upravicenec[fieldInfo.name] = normalizedValue;
        let upravicenciCopy = upravicenci;
        upravicenciCopy[index] = upravicenec;
        setUpravicenci([...upravicenciCopy]);
        console.debug("updating internal state... " + upravicenci[index]);

        dispatch({
            type: ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED,
            payload: {
                field: compFieldName,
                value: upravicenci[index]
            }
        });

        
            //ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED,
        
    };

    const handleAddClick = (event) => {

        let i = 0, u = null;
        while (i < upravicenci.length) {
            u = upravicenci[i];
            if (typeof(u.ime) !== 'string' || typeof(u.priimek) !== 'string' || typeof(u.datumRojstva) !== 'string') {
                event.preventDefault();
                return;
            }

            if (u.ime.length === 0 || u.priimek.length === 0 || u.datumRojstva.length === 0) {
                event.preventDefault();
                return;
            }

            i++;
        }

        // We actually need to add another row.
        let obj = { ...upravicenecNew };
        obj.id = getAndIncrement();
        let upravicenciCopy = upravicenci;
        upravicenciCopy.push( {...obj} );
        setUpravicenci( [...upravicenciCopy] );

        dispatch({
            type: ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED,
            payload: {
                field: compFieldName,
                value: obj
            }
        });
    };

    const handleRemoveClick = (event) => {
        if (upravicenci.length === 1) {
            event.preventDefault();
            return;
        }

        // Internal object id.
        let objectId = parseInt(event.target.getAttribute('data-record-index')), index;
        let upravicenciCopy = upravicenci;

        index = upravicenci.findIndex(up => up.id === objectId);
        dispatch({
            type: ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED,
            payload: {
                field: compFieldName,
                value: upravicenciCopy[index]
            }
        });

        upravicenciCopy.splice(index, 1);
        setUpravicenci([...upravicenciCopy]);
    };

    return (
        <>
            <table className={"table " + hideClass}>
            <thead>
                <tr>
                    <th className="center">Ime</th>
                    <th className="center">Datum Rojstva</th>
                    <th className="center">Dejanja</th>
                </tr>
            </thead>
            <tbody>
                {upravicenci.map((element, index, array) => {
                        let key = element.id.toString(), localPrefix = "u" + key + "_";
                        return (<tr key={key}>
                            <td>
                                <ImeField prefix={localPrefix} onImeChange={handleChange} onPriimekChange={handleChange} />
                            </td>
                            <td>
                                <DatumRojstvaField prefix={localPrefix} onChange={handleChange} />
                            </td>
                            <td className="center-align">
                                <button data-record-index={key} type="button" className="btn btn-small darken-2 red" onClick={handleRemoveClick}>
                                    <i className="material-icons">delete</i>
                                </button>
                            </td>
                        </tr>);
                })}
                
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3" className="center-align">
                        <button type="button" className="btn-floating btn-large waves-effect waves-light green" onClick={handleAddClick}>
                            <i className="material-icons">add</i>
                        </button>
                    </td>
                </tr>
            </tfoot>
            </table>
        </>
    );
}

