import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import M from '@materializecss/materialize';
import FormSubmitField from '../field/FormSubmitField';
import FormStepButtonField from '../field/FormStepButtonField';
import PredogledArea from '../other/PredogledArea';
import ItemClen545Service from '../service/ItemClen545Service';
import TextInputField from '../field/TextInputField1';
import EmailInputField from '../field/EmailInputField';
import { validateCheckboxRequiredChecked, validateEmailInput, validatePotrditvenaKoda, validateTextInput } from 'eureko-lib';
import CheckboxFieldRequiredChecked from '../field/CheckboxFieldRequiredChecked';
import PodpisField1 from '../field/PodpisField1';
import { buildRequestData } from './VstopSSvetovanjemStrankaPrisotna/buildRequestData';
import IdentityService from '../service/IdentityService';
import PotrditvenaKoda3Field from '../field/PotrditvenaKoda3Field';
import { META_UPDATED } from '../redux/types/metaType';
import { ZAVAROVALEC_UPDATED } from '../redux/types/zavarovalecType';

export default function VstopSSvetovanjemForm({
    modelInitial,
}) {

    const [ model, setModel ] = useState(modelInitial);

    function reloadModel () {
        setModel(JSON.parse(JSON.stringify(model)));
    }
    function reloadSteps () {
        setAllSteps(JSON.parse(JSON.stringify(allSteps)));
    }
    
    const [status, setStatus] = useState('');
    const [pdfContent, setPdfContent] = useState(null);
    const [signedPdfContent, setSignedPdfContent] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const steps = [
        {
            step: 1,
            tab: 'zavarovalec',
            text: 'ZAVAROVALEC',
            error: false,
            isActive: false,
        },
        {
            step: 2,
            tab: 'clen545',
            text: '545. ČLEN',
            error: false,
            isActive: false,
        },
        {
            step: 3,
            tab: 'oddaja',
            text: 'ODDAJA',
            error: false,
            isActive: false,
        },
    ];
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const [allSteps, setAllSteps] = useState(steps);

    const { t } = useTranslation(['translation', 'validation', 'flashes', 'backend']);

    const handlePreviewClick = (requestId) => {
        const withoutSignatures = requestId === 'preview-without-signatures';

        let itemClen545Service = new ItemClen545Service();
        const requestBody = buildRequestData(model, 'preview', requestId);
        itemClen545Service.getItemClen545V1Preview(requestBody, withoutSignatures)
            .then((result) => {
                if (result.success) {
                    switch(requestId) {
                        case 'preview-without-signatures':
                            setPdfContent(result.data.pdf.content);
                            break;
                        case 'preview-with-signatures':
                            setSignedPdfContent(result.data.pdf.content);
                            break;
                        default:
                            break;           
                    }
                }
                else {
                    if (result?.errors?.[0]?.code === 'otp.error.verificationFailed') {
                        M.toast({ html: 'Neveljavna potrditvena koda.' });
                    }
                    else {
                        M.toast({ html: t("flashes:form.error.preview") });
                    }
                }
            })
            .catch(error => {
                M.toast({ html: t("flashes:form.error.preview") });
            });
    }

    const displayValidationErrors = (errors) => {
        allSteps.forEach(step => step.error = false);

        if (errors) {
            Object.entries(errors)
                .forEach(([formKey, formFields]) => {
                    Object.entries(formFields)
                        .forEach(([ fieldKey, field ]) => {
                            model[formKey][fieldKey].error = field.error;
                            if (field.error?.hasError) {
                                allSteps.find(step => step.tab === formKey)
                                    .error = true;
                            }
                        });
                });
        } else {
            Object.values(model)
                .forEach(tabModel => {
                    Object.values(tabModel)
                        .forEach(question => question.error = { hasError: false });
                });
        }

        reloadModel();
        reloadSteps();
    }

     const handleSubmit = async (event) => {
        event.preventDefault();
        setStatus('SUBMITTING');

        let itemClen545Service = new ItemClen545Service();
        const requestBody = buildRequestData(model, 'submit', 'submit');
        itemClen545Service.sendItemClen545V1(requestBody)
            .then((result) => {
                if (result.success) {

                    dispatch({
                        type: META_UPDATED,
                        payload: {
                            field: 'meta_agentParams',
                            value: result.data.agentParams
                        }
                    });

                    dispatch({
                        type: META_UPDATED,
                        payload: {
                            field: 'meta_jobSessionId',
                            value: result.data.jobSessionId
                        }
                    });

                    dispatch({
                        type: ZAVAROVALEC_UPDATED,
                        payload: {
                            field: 'zavarovalec_ime',
                            value: model.zavarovalec.ime.value,
                        },
                    });
                    dispatch({
                        type: ZAVAROVALEC_UPDATED,
                        payload: {
                            field: 'zavarovalec_priimek',
                            value: model.zavarovalec.priimek.value,
                        },
                    });
                    dispatch({
                        type: ZAVAROVALEC_UPDATED,
                        payload: {
                            field: 'zavarovalec_email',
                            value: model.zavarovalec.email.value,
                        },
                    });
                    dispatch({
                        type: ZAVAROVALEC_UPDATED,
                        payload: {
                            field: 'zavarovalec_mobitel',
                            value: model.zavarovalec.mobitel.value,
                        },
                    });

                    M.toast({ html: t("flashes:submission.successful") });
                    setStatus('SUBMITTED');
                    navigate('/medicare-izbira-produkta');
                }
                else {
                    setStatus('');
                    if (result.errors?.[0]?.code === 'INVALID_FORM_DATA') {
                        displayValidationErrors(result.errors?.[0]?.errors);
                        M.toast({ html: t(result.message) });
                    }
                    else if (result?.errors?.[0]?.code === 'otp.error.verificationFailed') {
                        M.toast({ html: 'Neveljavna potrditvena koda.' });
                    }
                    else {
                        M.toast({html: t(result.message) });
                    }
                }
            })
            .catch(() => {
                setStatus('');
                M.toast({ html: t("flashes:submission.error") });
            })
    }

    const handleSendOtpClick = async (event) => {
        event.preventDefault();

        const email = model.zavarovalec.email.value;        
        const mobitel = model.zavarovalec.mobitel.value;        

        if (!email || !mobitel) {
            M.toast({ html: "Najprej vnesite veljavno elektronsko pošto in telefonsko številko." });
            return;
        }

        let message = t('translation:field.verificationCode.errorMessage'); 
        let idService = new IdentityService();
        idService.sendVerificationCode({
            email: email,
            mobitel: mobitel

        }).then((responseResult) => {
            if (responseResult.success) {
                message = t('translation:field.verificationCode.successMessage');
                const otpId = responseResult.data.otp.id;
                model.zavarovalec.otpId.value = otpId;
                reValidatePotrditvenaKoda();
            }
        }).catch((error) => {
            
        }).finally(() => {
            M.toast({ html: message });
        });
    };

    const reValidatePotrditvenaKoda = async () => {
        const potrditvenaKoda = model.zavarovalec.potrditvenaKoda.value;
        const email = model.zavarovalec.email.value;
        const mobitel = model.zavarovalec.mobitel.value;
        const otpId = model.zavarovalec.otpId.value;

        try {
            const errors = await validatePotrditvenaKoda(
                async (usedPotrditvenaKoda) => {
                    const response = await new IdentityService().checkVerificationCode({
                        email,
                        mobitel,
                        id: otpId,
                        potrditvenaKoda: usedPotrditvenaKoda,
                    });
        
                    if (!response.success) {
                        return false;
                    } else return true;
                },
                {
                    required: true,
                },
                potrditvenaKoda,
            );

            model.zavarovalec.potrditvenaKoda.error = errors;
            reloadModel();
        } catch (error) {
            M.toast({ html: "Napaka pri validaciji potrditvene kode. Prosim obrnite se na administratorje platforme." });
        }

    }

    const activateStep = (stepToActivate) => {
        allSteps.forEach(step => {step.isActive = step.tab === stepToActivate.tab;});
        setAllSteps(allSteps);
        setCurrentStep(stepToActivate);
        setTimeout(() => {
            window.scroll(0, 0);
        }, 10);
    }

    return (
        <div>

            <form onSubmit={handleSubmit} noValidate={true}>
                <div className="row">
                <div className="col s12">
                        <ul 
                            className="custom-tabs" 
                        >
                            {
                                allSteps.map(step => {
                                    let classAdd = ' ';
                                    if (step.error) classAdd += 'red';
                                    if (step.isActive) classAdd += ' active';
                                    return (
                                        <li 
                                            key={step.tab}
                                            className={"cutom-tab darken-1 white-text" + classAdd}
                                            onClick={event => { event.preventDefault(); activateStep(step); }}
                                            role="navigation"
                                            >
                                                <span>
                                                    {step.text}
                                                </span>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="row">
                    {
                        currentStep.tab === 'zavarovalec'
                        ?
                        <div id="zavarovalec" className="col s12">
                            <fieldset>
                                <legend>Zavarovalec</legend>
                                <div
                                    className='row'
                                >
                                    <div
                                        className="col s6"
                                    >
                                        <TextInputField
                                            isReadOnly={model.zavarovalec.ime.readonly}
                                            error={model.zavarovalec.ime.error}
                                            isRequired={model.zavarovalec.ime.required}
                                            name={model.zavarovalec.ime.name}
                                            onChange={value => model.zavarovalec.ime.value = value}
                                            questionText="Ime"
                                            validateFn={validateTextInput.bind(null, { required: model.zavarovalec.ime.required })}
                                            value={model.zavarovalec.ime.value}
                                        />
                                    </div>
                                    <div
                                        className="col s6"
                                    >
                                        <TextInputField 
                                            isReadOnly={model.zavarovalec.priimek.readonly}
                                            error={model.zavarovalec.priimek.error}
                                            isRequired={model.zavarovalec.priimek.required}
                                            name={model.zavarovalec.priimek.name}
                                            onChange={value => model.zavarovalec.priimek.value = value}
                                            questionText="Priimek"
                                            validateFn={validateTextInput.bind(null, { required: model.zavarovalec.priimek.required })}
                                            value={model.zavarovalec.priimek.value}
                                        />
                                    </div>
                                    <div
                                        className="col s6"
                                    >
                                        <EmailInputField
                                            isReadOnly={model.zavarovalec.email.readonly}
                                            error={model.zavarovalec.email.error}
                                            isRequired={model.zavarovalec.email.required}
                                            name={model.zavarovalec.email.name}
                                            onChange={value => { model.zavarovalec.email.value = value; reValidatePotrditvenaKoda(); }}
                                            questionText="Elektronska pošta"
                                            validateFn={validateEmailInput.bind(null, { required: model.zavarovalec.email.required })}
                                            value={model.zavarovalec.email.value}
                                        />
                                    </div>
                                    <div
                                        className="col s6"
                                    >
                                        <TextInputField 
                                            isReadOnly={model.zavarovalec.mobitel.readonly}
                                            error={model.zavarovalec.mobitel.error}
                                            isRequired={model.zavarovalec.mobitel.required}
                                            name={model.zavarovalec.mobitel.name}
                                            onChange={value => { model.zavarovalec.mobitel.value = value; reValidatePotrditvenaKoda(); }}
                                            questionText="Mobitel"
                                            validateFn={validateTextInput.bind(null, { required: model.zavarovalec.mobitel.required })}
                                            value={model.zavarovalec.mobitel.value}
                                        />
                                    </div>
                                    <div
                                        className="col s12"
                                    >
                                        <PotrditvenaKoda3Field 
                                            name={model.zavarovalec.potrditvenaKoda.name} 
                                            onChange={value => { model.zavarovalec.potrditvenaKoda.value = value; reValidatePotrditvenaKoda(); }}
                                            onSendOtpClick={handleSendOtpClick}
                                            error={model.zavarovalec.potrditvenaKoda.error}
                                            isRequired={true}
                                            value={model.zavarovalec.potrditvenaKoda.value}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            
                            <fieldset>
                                <legend>Sklepanje</legend>
                                <div className="row">
                                    <div
                                        className='col s12'
                                    >
                                        <CheckboxFieldRequiredChecked
                                            isRequired={model.zavarovalec.soglasjeUporabaEmailMobitel.required}
                                            isReadOnly={model.zavarovalec.soglasjeUporabaEmailMobitel.readonly}
                                            onChange={value => model.zavarovalec.soglasjeUporabaEmailMobitel.value = value}
                                            name={model.zavarovalec.soglasjeUporabaEmailMobitel.name}
                                            questionText="Za preveritev podatkov bo stranka na telefonsko številko in/ali e-naslov prejela verifikacijsko kodo, ki jo je potrebno vnesti v sklepalno aplikacijo za nadaljevanje postopka sklepanja."
                                            error={model.zavarovalec.soglasjeUporabaEmailMobitel.error}
                                            validateFn={validateCheckboxRequiredChecked.bind(null, { required: model.zavarovalec.soglasjeUporabaEmailMobitel.required })}
                                            value={model.zavarovalec.soglasjeUporabaEmailMobitel.value}
                                        />
                                    </div>
                                    <div
                                        className='col s12'
                                    >
                                        <CheckboxFieldRequiredChecked
                                            isRequired={model.zavarovalec.soglasjePrejemDokumentacije.required}
                                            isReadOnly={model.zavarovalec.soglasjePrejemDokumentacije.readonly}
                                            onChange={value => model.zavarovalec.soglasjePrejemDokumentacije.value = value}
                                            name={model.zavarovalec.soglasjePrejemDokumentacije.name}
                                            questionText="Stranka se strinja, da bo sklepalno dokumentacijo prejela po e-pošti in da se z njo lahko komunicira elektronsko in sicer po e-pošti, telefonu ali s pomočjo mobilnih in spletnih  aplikacij. Stranka je seznanjena, da lahko to soglasje kadarkoli umakne s pisno izjavo po navadni ali e-pošti."
                                            error={model.zavarovalec.soglasjePrejemDokumentacije.error}
                                            validateFn={validateCheckboxRequiredChecked.bind(null, { required: model.zavarovalec.soglasjePrejemDokumentacije.required })}
                                            value={model.zavarovalec.soglasjePrejemDokumentacije.value}
                                        />
                                    </div>
                                </div>
                            </fieldset>

                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'clen545'
                        ?
                        <div id="clen545" className="col s12">
                            <p>
                                V skladu s 545. členom zakona o zavarovalništvu se morate pred začetkom sklepanja seznaniti s spodnjim dokumentom.
                            </p>
                            <PredogledArea onPreviewClick={handlePreviewClick} requestId="preview-without-signatures" pdfContent={pdfContent} />

                            <fieldset>
                                <legend>Podpis dokumenta</legend>
                                <PodpisField1
                                    isRequired={model.clen545.podpisSvetovalec.required}
                                    onChange={value => model.clen545.podpisSvetovalec.value = value}
                                    name={model.clen545.podpisSvetovalec.name}
                                    questionText="Podpis svetovalca"
                                    error={model.clen545.podpisSvetovalec.error}
                                    value={model.clen545.podpisSvetovalec.value}
                                />
                                <PodpisField1
                                    isRequired={model.clen545.podpisZavarovalec.required}
                                    onChange={value => model.clen545.podpisZavarovalec.value = value}
                                    name={model.clen545.podpisZavarovalec.name}
                                    questionText="Podpis zavarovalca"
                                    error={model.clen545.podpisZavarovalec.error}
                                    value={model.clen545.podpisZavarovalec.value}
                                />
                            </fieldset>
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'oddaja'
                        ?
                        <div id="oddaja" className="col s12">
                            <PredogledArea onPreviewClick={handlePreviewClick} requestId="preview-with-signatures" pdfContent={signedPdfContent} />
                            <FormSubmitField formStatus={status} />
                        </div>
                        : ''
                    }
                </div>

                <div className="row">
                    <div className="col m4">
                        <FormStepButtonField 
                            role="previous" 
                            allSteps={allSteps} 
                            onClick={() => activateStep(findPreviousStep(allSteps))}
                            disabled={currentStep.step === 1}
                        />
                    </div>
                    <div className="col m4 offset-m3">
                        <FormStepButtonField 
                            role="next" 
                            allSteps={allSteps} 
                            onClick={() => activateStep(findNextStep(allSteps))} 
                            disabled={currentStep.step === 3}
                        />
                    </div>
                </div>
            </form>
            
        </div>
    );

}

function findNextStep (allSteps) {
    let stepIndex = 0;
    allSteps.find((step, index) => {
        if (step.isActive) stepIndex = index;
        return step.isActive;
    });

    return allSteps[stepIndex + 1];
}
function findPreviousStep (allSteps) {
    let stepIndex = 0;
    allSteps.find((step, index) => {
        if (step.isActive) stepIndex = index;
        return step.isActive;
    });

    return allSteps[stepIndex - 1];
}



