import { React, useState, useEffect } from 'react';

export default function CheckboxField({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    isReadOnly,
    validateFn,
    value,
}) {
    const [ validationError, setValidationError ] = useState(error);
    const [ inputValue, setInputValue ] = useState(value || false);

    useEffect(() => {
        setValidationError(error);
    }, [error])

    useEffect(() => {
        setInputValue(value || false);
    }, [ value ]);

    const handleChange = () => {
        const val = !inputValue; 
        setInputValue(val);

        if (onChange)
            onChange(val);

        if (validateFn) {
            validateFn(val)
                .then(errors => {
                    setValidationError(errors);
                });
        }
    };

    return (
        <div
            className={'input-field checkbox-input' + (validationError?.hasError ? ' invalid' : '')}
        >
            <label 
                htmlFor={name}
            >
                <input 
                    id={name} 
                    type="checkbox" 
                    name={name} 
                    required={isRequired} 
                    readOnly={isReadOnly} 
                    onChange={handleChange}
                    className={ validationError?.hasError ? 'invalid' : '' }
                    checked={inputValue}
                />
                <span>
                    {questionText}
                </span>
            </label>
            <div>
                <span 
                    className="helper-text"
                    hidden={!validationError?.hasError}    
                >   
                    {
                        validationError?.required ?
                        'Prosimo izpolnite to polje'
                        : ''
                    }
                    {
                        validationError?.error || ''
                    }
                </span>
            </div>
        </div>
    );
}


