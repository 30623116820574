import { React, useState, useEffect } from 'react';
import CheckboxField from './CheckboxField';

export default function CheckboxMultipleField({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    isReadOnly,
    validateFn,
    value,
    options,
}) {
    const [ validationError, setValidationError ] = useState(error);
    const [ inputValue, setInputValue ] = useState(value || false);

    useEffect(() => {
        setValidationError(error);
    }, [error])

    useEffect(() => {
        setInputValue(value || false);
    }, [ value ]);

    const handleCheckboxChange = (value) => {
        const newValue = inputValue;

        newValue[value] = !newValue[value];
        
        setInputValue(newValue);
        handleChange(newValue);
    };

    const handleChange = (val) => {
        if (onChange)
            onChange(val);

        if (validateFn) {
            validateFn(val)
                .then(errors => {
                    setValidationError(errors);
                });
        }
    };

    return (
        <div
            className={'input-field checkbox-input' + (validationError?.hasError ? ' invalid' : '')}
        >
            {
                options.map(option => {
                    return (
                    <CheckboxField
                        key={option.name}
                        name={option.name}
                        value={inputValue[option.value]}
                        onChange={() => handleCheckboxChange(option.value)}
                        error={error}
                        isReadOnly={isReadOnly}
                        questionText={option.text.sl}
                    />
                    );
                })
            }
            {
                validationError?.hasError ?                
                <div 
                    className="helper-text"
                >   
                    {
                        validationError?.required ?
                        'Prosimo izpolnite to polje'
                        : ''
                    }
                    {
                        validationError?.atLeastOneRequired ?
                        'Izberite vsaj eno od možnosti'
                        : ''
                    }
                    {
                        validationError?.error || ''
                    }
                </div>
                : ''
            }
        </div>
    );
}


