import { React } from 'react';
import DateInputField from '../../field/DateInputField';
import DavcnaStevilkaField1 from '../../field/DavcnaStevilkaField1';
import EmailInputField from '../../field/EmailInputField';
import NaslovField1 from '../../field/NaslovField1';
import SpolField1 from '../../field/SpolField1';
import CheckboxField from '../../field/CheckboxField';
import TextInputField from '../../field/TextInputField1';
import { validateAddressInputGroup, validateDateInput, validateEmailInput, validateTextInput, validateVatId1 } from 'eureko-lib';



export default function PrvaDiagnozaZavarovalecForm({
    model,
    onZavarovalecDatumRojstvaChange,
    onNaslovZaObvsecanjeIsSameAsNaslovChange,
    naslovZaObvsecanjeIsSameAsNaslov,
}) {

    return (

        <div>
            <fieldset>
                <legend>Osnovni podatki</legend>
                    <div
                        className='row'
                    >
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={model.ime.readonly}
                                error={model.ime.error}
                                isRequired={model.ime.required}
                                name={model.ime.name}
                                onChange={value => model.ime.value = value}
                                questionText={model.ime.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.ime.required })}
                                value={model.ime.value}
                            />
                        </div>
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={model.priimek.readonly}
                                error={model.priimek.error}
                                isRequired={model.priimek.required}
                                name={model.priimek.name}
                                onChange={value => model.priimek.value = value}
                                questionText={model.priimek.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.priimek.required })}
                                value={model.priimek.value}
                            />
                        </div>
                        <div
                            className="col s6"
                        >
                            <EmailInputField 
                                isReadOnly={model.email.readonly}
                                error={model.email.error}
                                isRequired={model.email.required}
                                name={model.email.name}
                                onChange={value => model.email.value = value}
                                questionText={model.email.text.sl}
                                validateFn={validateEmailInput.bind(null, { required: model.email.required })}
                                value={model.email.value}
                            />
                        </div>
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={model.telefon.readonly}
                                error={model.telefon.error}
                                isRequired={model.telefon.required}
                                name={model.telefon.name}
                                onChange={value => model.telefon.value = value}
                                questionText={model.telefon.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.telefon.required })}
                                value={model.telefon.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <SpolField1
                                isRequired={model.spol.required}
                                onChange={value => model.spol.value = value}
                                name={model.spol.name}
                                error={model.spol.error}
                                value={model.spol.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DateInputField 
                                isReadOnly={model.datumRojstva.readonly}
                                error={model.datumRojstva.error}
                                isRequired={model.datumRojstva.required}
                                name={model.datumRojstva.name}
                                onChange={value => { model.datumRojstva.value = value; onZavarovalecDatumRojstvaChange(); }}
                                questionText={model.datumRojstva.text.sl}
                                validateFn={validateDateInput.bind(null, { required: model.datumRojstva.required, conf: model.datumRojstva.conf })}
                                value={model.datumRojstva.value}
                                conf={model.datumRojstva.conf}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <TextInputField 
                                isReadOnly={model.krajRojstva.readonly}
                                error={model.krajRojstva.error}
                                isRequired={model.krajRojstva.required}
                                name={model.krajRojstva.name}
                                onChange={value => model.krajRojstva.value = value}
                                questionText={model.krajRojstva.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.krajRojstva.required })}
                                value={model.krajRojstva.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <TextInputField 
                                isReadOnly={model.drzavaRojstva.readonly}
                                error={model.drzavaRojstva.error}
                                isRequired={model.drzavaRojstva.required}
                                name={model.drzavaRojstva.name}
                                onChange={value => model.drzavaRojstva.value = value}
                                questionText={model.drzavaRojstva.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.drzavaRojstva.required })}
                                value={model.drzavaRojstva.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DavcnaStevilkaField1 
                                isReadOnly={model.davcnaStevilka.readonly}
                                error={model.davcnaStevilka.error}
                                isRequired={model.davcnaStevilka.required}
                                name={model.davcnaStevilka.name}
                                onChange={value => model.davcnaStevilka.value = value}
                                questionText={model.davcnaStevilka.text.sl}
                                validateFn={validateVatId1.bind(null, { required: model.davcnaStevilka.required })}
                                value={model.davcnaStevilka.value}
                            />
                        </div>
                    </div>
            </fieldset>
            <fieldset>
                <legend>Naslov</legend>
                <div
                    className="row"
                >
                    <NaslovField1
                        isReadOnly={model.naslov.readonly}
                        error={model.naslov.error}
                        isRequired={model.naslov.required}
                        name={model.naslov.name}
                        onChange={value => model.naslov.value = value}
                        value={model.naslov.value}
                        validateFn={validateAddressInputGroup.bind(null, { required: model.naslov.required })}
                    />
                </div>
            </fieldset>
            <fieldset>
                <legend>Naslov za obveščanje</legend>
                <div>
                    <CheckboxField
                        questionText="Isti kot naslov"
                        value={naslovZaObvsecanjeIsSameAsNaslov}
                        onChange={value => onNaslovZaObvsecanjeIsSameAsNaslovChange(value)}
                    />
                </div>
                {
                    !naslovZaObvsecanjeIsSameAsNaslov ?
                    <NaslovField1
                        isReadOnly={model.naslovZaObvescanje.readonly}
                        error={model.naslovZaObvescanje.error}
                        isRequired={model.naslovZaObvescanje.required}
                        name={model.naslovZaObvescanje.name}
                        onChange={value => model.naslovZaObvescanje.value = value}
                        value={model.naslovZaObvescanje.value}
                        validateFn={validateAddressInputGroup.bind(null, { required: model.naslovZaObvescanje.required })}
                    />
                    : ''
                }
            </fieldset>
        </div>
    );

}


