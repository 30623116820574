class ItemPonudbaService {

    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'itemsPonudbaCreate':
                url = url + '/items/ponudba';
                break;
            case 'itemsPonudbaBscCreate':
                url = url + '/items/ponudba/bdca';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    async sendItemPonudba(requestData) {
        let url = this.getUrl('itemsPonudbaCreate');
        let response = await fetch(url, {
            'method': 'POST',
            'body': JSON.stringify(requestData),
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();

    }

    async sendItemPonudbaPrvaDiagnoza202204(data) {

        const requestData = {
            form: {
                type: 'PrvaDiagnoza202204',
                action: 'submit',
            },
            ponudba: {
                type: 'EurekoPonudba',
                data: data,
            }
        };

        const url = this.getUrl('itemsPonudbaCreate');
        const response = await fetch(url + '/prvadiagnoza202204', {
            'method': 'POST',
            'body': JSON.stringify(requestData),
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();

    }

    async getItemPonudbaPrvaDiagnoza202204Preview(data) {

        let requestData = {
            form: {
                type: 'PrvaDiagnoza202204',
                action: 'preview',
            },
            ponudba: {
                type: 'EurekoPonudba',
                data: data,
            }
        };

        const url = this.getUrl('itemsPonudbaCreate');
        const response = await fetch(url + '/prvadiagnoza202204', {
            'method': 'POST',
            'body': JSON.stringify(requestData),
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();
    }

    async sendItemPonudbaForm(requestData, endpoint) {
        let url = this.getUrl(endpoint);
        let response = await fetch(url, {
            'method': 'POST',
            'body': JSON.stringify(requestData),
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();

    }


}

export default ItemPonudbaService;