import { validateTextInput, validateVatId1 } from 'eureko-lib';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DavcnaStevilkaField1 from '../field/DavcnaStevilkaField1';
import RadioMultipleField from '../field/RadioMultipleField';
import StevilkaZdravsteveneKarticeInputField from '../field/StevilkaZdravsteveneKarticeInputField';
import GuardProdukt from '../other/GuardProdukt';
import { izbiraProduktaService } from '../service/IzbiraProduktaService';
import store from '../redux/store';

export default function MedicareIzbiraProduktaRoute() {

    const [ davcnaStevilka, setDavcnaStevilka ] = useState(undefined);
    const [ davcnaStevilkaError, setDavcnaStevilkaError ] = useState({ hasError: false });
    const [ stevilkaZdravstvenegaZavarovanja, setStevilkaZdravstvenegaZavarovanja ] = useState(undefined);
    const [ stevilkaZdravstvenegaZavarovanjaError, setStevilkaZdravstvenegaZavarovanjaError ] = useState({ hasError: false });
    const [ isFirstFormStepValid, setIsFirstFormStepValid ] = useState(false);

    const [ formStep, setFormStep ] = useState(1);
    const [ products, setProducts ] = useState([]);
    const [ selectedProduct, setSelectedProduct ] = useState(null);

    const navigate = useNavigate();

    async function handleDavcnaStevilkaChange (value) {
        setDavcnaStevilka(value);
        const error = await validateVatId1({ required: true }, value);
        setDavcnaStevilkaError(error);
    }

    async function handleStevilkaZdravstvenegaZavarovanjaChange (value) {
        setStevilkaZdravstvenegaZavarovanja(value);
        const error = await validateTextInput({ required: false }, value);
        setStevilkaZdravstvenegaZavarovanjaError(error);
    }

    useEffect(() => {
        setIsFirstFormStepValid(
            !!davcnaStevilka
            && !davcnaStevilkaError.hasError
            && !stevilkaZdravstvenegaZavarovanjaError.hasError
        )
    }, [
        davcnaStevilka,
        davcnaStevilkaError,
        stevilkaZdravstvenegaZavarovanjaError,
    ]);

    function handleFirstStepSubmit () {
        setFormStep(2);
        izbiraProduktaService
            .getRecommendedProductsForZavarovanaOseba(
                davcnaStevilka,
                stevilkaZdravstvenegaZavarovanja,
                store.getState().meta.agentParams,
                store.getState().meta.jobSessionId,
            ).then(products => {
                const productsOptions = products
                    .map(p => ({ 
                        name: p.label,
                        text: { sl: p.label },
                        value: p.id,
                        priporocljivo: p.priporocljivo,
                    }));
                setProducts(productsOptions);
            });
    }

    function handleSecondStepSubmit () {        
        if (selectedProduct === 'PD')
            navigate('/produkti/prva-diagnoza');
        if (selectedProduct === 'ALLIANZ')
            navigate('/produkti/allianz-nezgoda');
    }

    return (
        <GuardProdukt>
            {
                formStep === 2 ?
                <div
                    className='container'
                    style={{ marginTop: '1.52rem' }}
                >
                    <div className="row">
                        <div className="col s12 center">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                        Davčna številka
                                        </th>
                                        <th>
                                        Številka zdravstvene kartice
                                        </th>
                                        {
                                            products.map(product => {
                                                return (
                                                    <th
                                                        key={product.text.sl + '-label'}
                                                    >
                                                        {product.text.sl}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        
                                    >
                                        <td>
                                        {davcnaStevilka}
                                        </td>
                                        <td>
                                        {stevilkaZdravstvenegaZavarovanja}
                                        </td>
                                        {
                                            products.map(product => {
                                                return (
                                                    <td
                                                        className={product.priporocljivo ? 'green' : 'red'}
                                                        key={product.text.sl + '-recommended'}
                                                    >
                                                        {
                                                        product.priporocljivo ?
                                                        'Priporočljivo'
                                                        : 'Ni priporočljivo'
                                                        }
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 center">
                            <h4>
                                Produkti, ki so na voljo
                            </h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12 m4 offset-m2">
                            <RadioMultipleField
                                value={selectedProduct}
                                isRequired={true}
                                name='izbira-produkta-radio'
                                onChange={value => setSelectedProduct(value)}
                                options={products}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div 
                            className="col s12"
                            style={{ display: 'flex', justifyContent: 'space-evenly' }}
                        >
                            <button className={"btn eureko-form-action-btn"}  
                                type="button" 
                                onClick={() => setFormStep(1)} 
                            >
                                Nazaj
                            </button>
                            <button className={"btn eureko-form-action-btn" + (selectedProduct ? '' : ' disabled')}  
                                type="button" 
                                onClick={handleSecondStepSubmit} 
                            >
                                Potrdi izbiro
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
            {
                formStep === 1 ?
                <div
                    className='container'
                    style={{ marginTop: '1.52rem' }}
                >
                    <div className="row">
                        <div className="col s12 center">
                            <h4>
                                Oseba, ki jo želite zavarovati
                            </h4>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s6">
                            <DavcnaStevilkaField1
                                value={davcnaStevilka}
                                isRequired={true}
                                questionText="Davčna številka*"
                                onChange={handleDavcnaStevilkaChange}
                                error={davcnaStevilkaError}
                            />
                        </div>
                        <div className="col s6">
                            <StevilkaZdravsteveneKarticeInputField
                                value={stevilkaZdravstvenegaZavarovanja}
                                isRequired={false}
                                questionText="Številka zdravstvene kartice"
                                onChange={handleStevilkaZdravstvenegaZavarovanjaChange}
                                error={stevilkaZdravstvenegaZavarovanjaError}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div 
                            className="col s12"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <button className={"btn eureko-form-action-btn" + (isFirstFormStepValid ? '' : ' disabled')}  
                                type="button" 
                                onClick={handleFirstStepSubmit} 
                            >
                                Naprej
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
        </GuardProdukt>
    );

}