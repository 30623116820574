import { React, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function CenaField(props) {
    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    // Use fromatDecimal from redux/common
    /**
     * @deprecated
     * @param {*} decimal 
     * @returns 
     */
    const formatDecimal = (decimal) => {
        if (decimal === null || typeof(decimal) === 'undefined') {
            return '/';
        }

        let string = new String(decimal.toFixed(2));
        return string.replace('.', ',');
    };

    const premijaNeto = useSelector((state) => {
        if (typeof(state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.zavarovanaOseba) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.zavarovanaOseba.premija) === 'undefined') {
            return '';
        }

        return formatDecimal(state.produkt.zavarovanaOseba.premija.neto);
    });

    const premijaDavek = useSelector((state) => {
        if (typeof (state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba.premija) === 'undefined') {
            return '';
        }

        return formatDecimal(state.produkt.zavarovanaOseba.premija.davek);
    });

    const premijaBruto = useSelector((state) => {
        if (typeof (state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba.premija) === 'undefined') {
            return '';
        }

        return formatDecimal(state.produkt.zavarovanaOseba.premija.bruto);
    });

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <>            
            <table className="table">
                <thead></thead>
                <tbody>
                    <tr>
                        <td className="">
                            Osnovna premija [EUR]
                        </td>
                        <td className="">
                            {premijaNeto}
                        </td>
                    </tr>
                    <tr>
                        <td className="">
                            Davek od prometa zavarovalnih poslov [EUR]
                        </td>
                        <td className="">
                            {premijaDavek}
                        </td>
                    </tr>
                    <tr>
                        <td className="" style={{color: '#0F2B62'}}>
                            <b>Skupaj - premija [EUR]</b>
                        </td>
                        <td className="" style={{color: '#0F2B62'}}>
                            <b className="">{premijaBruto}</b>
                        </td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
        </>
    );
}

