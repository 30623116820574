export default function AllianzNezgodaTable(props) {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th>Kritje NR3</th>
                        <th>Kritja A</th>
                        <th>Kritja B</th>
                        <th>Kritja C</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Nezgodna smrt</td>
                        <td>60.000,00</td>
                        <td>35.000,00</td>
                        <td>40.000,00</td>
                    </tr>
                    <tr>
                        <td>Trajna invalidnost</td>
                        <td>120.000,00</td>
                        <td>70.000,00</td>
                        <td>80.000,00</td>
                    </tr>
                    <tr>
                        <td>Izplačilo v primeru 100% invalidnosti</td>
                        <td>180.000,00</td>
                        <td>105.000,00</td>
                        <td>120.000,00</td>
                    </tr>
                    <tr>
                        <td>Bolnišnična odškodnina</td>
                        <td>/</td>
                        <td>10,00</td>
                        <td>20,00</td>
                    </tr>
                    <tr>
                        <td>Dnevna odškodnina</td>
                        <td>/</td>
                        <td>10,00</td>
                        <td>20,00</td>
                    </tr>
                    <tr>
                        <td>Letna premija</td>
                        <td>137,36</td>
                        <td>135,63</td>
                        <td>202,46</td>
                    </tr>
                    <tr>
                        <td>Mesečna premija</td>
                        <td>11,45</td>
                        <td>11,30</td>
                        <td>16,87</td>
                    </tr>
                </tbody>
                <tfoot></tfoot>
            </table>
        </>
    );
}