import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function NacinPlacilaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <>
            <div className="input-field col s12">
                {props.fieldLabel}
                <p style={{marginBottom: '0px'}}>
                    <label>
                        <input name={fidPrefix + props.fieldName} 
                            type="radio" 
                            onChange={handleChange} 
                            value="SEPA" 
                            required={true} 
                        />
                        <span>SEPA</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + props.fieldName} 
                            type="radio" 
                            onChange={handleChange} 
                            value="UPN" 
                            required={true} 
                        />
                        <span>UPN</span>
                    </label>
                    
                </p>
                
            </div>
        </>
    );
}

