import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function getClassName (error, isFirst) {
    let className = ' ';
    className += "eureko-radio-label "
    if (!!error?.hasError) className +=  'eureko-input-boolean-required';
    return className.trim();
}

export default function RadioMultipleField({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    options,
    value,
}) {

    const { t } = useTranslation(['validation', 'flashes']);

    const [ inputValue, setInputValue ] = useState(value || undefined);

    useEffect(() => {
        setInputValue(value || undefined);
    }, [ value ]);

    const handleChange = (event) => {
        const val = event.target.value;
        setInputValue(val);

        if (onChange)
            onChange(val);
    };

    return (
        <div
            className={'input-field' + (error?.hasError ? ' invalid' : '')}
            id={'radio-multiple-' + name}
        >
            {questionText}
            <div>
                {
                    options.map((option, index) => {
                        return (
                            <div
                                key={name + '-' + option.name}
                            >
                                <label
                                    className={getClassName(error, index === 0)}>
                                    <input 
                                        name={name} 
                                        type="radio" 
                                        value={option.value} 
                                        onChange={handleChange}
                                        checked={option.value===inputValue}
                                    />
                                    <span>{option.text.sl}</span>
                                </label>
                            </div>
                        );
                    })
                }
            </div>
            <span 
                className="helper-text"
                hidden={!error?.hasError}    
            >   
                {
                    error?.required ?
                    'Prosim izberite eno izmed možnosti'
                    : ''
                }
                {
                    error?.invalidIdd2q1potrjujem ?
                    'Neveljavna izbira gleda na prejšnje odgovore'
                    : ''
                }
                {
                    error?.invalidIdd2q2nezelim ?
                    'Neveljavna izbira gleda na prejšnje odgovore'
                    : ''
                }
            </span>
        </div>
    );
}

