import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';

export default function RazmerjeField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const selectInput = useRef(null);

    const handleChange = (event) => {
        props.onChange(event);
    };

    const fieldName = fidPrefix + 'razmerje';

    useEffect(() => {
        selectInput.current.value = '';
        let dummyEvent = {
            target: {
                name: (fieldName),
                value: selectInput.current.value
            }
        };
        props.onChange(dummyEvent);

    }, []);

    const { t, i18n } = useTranslation(['validation']);

    return (
        <div className="input-field s12">
            <select className="validate" ref={selectInput} id={fieldName} name={fieldName} onChange={handleChange} required>
                <option value="">-- Izberi --</option>
                <option value="A">Zavarovalec</option>
                <option value="B">Zakonec</option>
                <option value="C">Vzdrževan družinski član</option>
                <option value="D">Drugo</option>
            </select>
            <label htmlFor={fieldName}>Razmerje</label>
            <span className="helper-text" data-error={t('validation:field.validate.razmerje')} data-success=""></span>
        </div>


    )
}   