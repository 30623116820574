import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';


export default function Predogled2Area({
    pdfStatus,
    document
    
}) {
    const [timeoutReached, setTimeoutReached] = useState(true);

    const { t, i18n } = useTranslation(['translation']);


    /*const handlePreviewClick = (event) => {
        let formValidated = props.onPreviewClick(props.requestId);
        if (!formValidated) {
            setPdfStatus('');
        }

        setPdfStatus('LOADING');
        setTimeoutReached(false);
        setTimeout(() => setTimeoutReached(true), 15000);
        
    }*/

    /*useEffect(() => {
        let mounted = true;
        if (mounted && props.pdfContent !== null) {
            setPdfStatus('LOADED');
        }

        return () => {
            mounted = false;
        }
    }, [props.pdfContent]);*/

    return (
        <div className="row">
            <div className="col s12 center">
                <div className="card horizontal">
                    <div className="card-stacked ">
                        
                        <div 
                            className="card-content eureko-blue lighten-1 center-align"
                        >
                            {pdfStatus === '' && 
                                    <p 
                                        className="white-text"
                                    >
                                        Za predogled dokumenta morate najprej izpolniti obrazec.
                                        <br /><br />
                                    </p>
                            }

                            {pdfStatus === 'LOADING' && 
                                <div>
                                    <span className="white-text">
                                        Predogled dokumentov v pripravi.
                                    </span>
                                    <br /><br />
                                </div>
                            }
                    
                            {pdfStatus === 'FAILED' &&
                                <p>
                                    <b className="red lighten-1">Pridobivanje dokumenta je spodletelo!</b>
                                    <br /><br />
                                </p>
                            }

                            {pdfStatus === 'LOADED' && document !== undefined && 
                                <p className="white-text">Sledite <a 
                                        href={'data:application/pdf;base64,' + document.content} 
                                        download="predogled-dokumenta.pdf"
                                        className="white">povezavi</a> za prikaz dokumenta v novem zavihku.
                                    <br /><br />
                                </p> 
                            }
                            
                            {pdfStatus === 'LOADED' && document !== undefined && 
                                <iframe 
                                    src={'data:application/pdf;base64,' + document.content} 
                                    type="application/pdf" 
                                    width="100%" 
                                    height="800"
                                >
                                </iframe>
                            }


                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
} 