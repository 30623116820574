import { parseFieldName } from '../../common';
import  appCoreOtpReducer from './appCoreOtpReducer';
import { 
    APP_CORE_OTP_UPDATED,
    APP_CORE_UPDATED
} from '../types/appCoreType';


const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = state;
    switch (action.type) {
        case APP_CORE_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        
        case APP_CORE_OTP_UPDATED:
            let otp = {};
            if (typeof(state.otp) !== 'undefined') {
                otp = state.otp;
            }

            return {...state, otp: appCoreOtpReducer(otp, action)}

        default:
            return state;
    }
};