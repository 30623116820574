import React from "react";
import VstopSSvetovanjemForm from "../form/VstopSSvetovanjemForm";
import AgentService from "../service/AgentService";


class SklepanjeVstopRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agentParamsStatus: null
        };
    }

    componentDidMount() {
        
        // Are agent params valid?
        const urlParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (params, p) => params.get(p)
        });

        if (typeof(urlParams.h) === 'string' && typeof(urlParams.t) === 'string') {
            this.setState({ agentParamsStatus: 'SUBMITTING' });
            let agentService = new AgentService();
            agentService.checkAgentLink({
                h: urlParams.h,
                t: urlParams.t
            }).then(result => {
                console.log("This is result:");
                console.log(result);
                if (result.success) {
                    this.setState({ agentParamsStatus: 'VALID' });
                }
                else if (result.errors.length) {
                    let e = result.errors[0];
                    this.setState({ agentParamsStatus: e.code === 'AGENT_NOT_FOUND' ? 'INVALID' : null });
                }
                else {
                    this.setState({ agentParamsStatus: null });
                }
            });
        }

    }

    render() {
        let title = <h4>Sklenite zavarovanje na platformi Medicalcare</h4>;
        let content = <p className="center-align">
            Za začetek sklepanja morate navezati stik z enim od naših agentov. Za več informaciji pokličite: 080 88 12
        </p>;
        let wrapperClass = "col s12 ";
        if (this.state.agentParamsStatus === 'VALID') {
            title = <h4>Soglasje za sklepanje na daljavo</h4>;
            content = <VstopSSvetovanjemForm agentParams={window.location.search}/>
        }
        else if (this.state.agentParamsStatus === 'INVALID') {
            content = <p className="center-align">
                <b className="red-text lighten-1">Povezava za začetek sklepanja je neveljavna!</b>
            </p>;
        }
        else if (this.state.agentParamsStatus === 'SUBMITTING') {
            
            content = <div>
                <p>Preverjanje veljavnosti povezave...</p>
                <div className="preloader-wrapper big active">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>;
            wrapperClass += "center-align";
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 center-align">
                        {title}
                    </div>
                </div>
                <div className="row">
                    <div className={wrapperClass}>
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

export default SklepanjeVstopRoute;