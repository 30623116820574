import { React, useEffect, useState } from 'react';
import DateInputField from '../../field/DateInputField';
import DavcnaStevilkaField1 from '../../field/DavcnaStevilkaField1';
import SpolField1 from '../../field/SpolField1';
import StevilkaZdravsteveneKarticeInputField from '../../field/StevilkaZdravsteveneKarticeInputField';
import TextInputField from '../../field/TextInputField1';
import { validateDateInput, validateTextInput, validateVatId1 } from 'eureko-lib';
import MessageBox from '../../other/MessageBox';
import { validateStevilkaZdravstveneKartice } from 'eureko-lib/src/validation/validation';



export default function PrvaDiagnozaZavarovanecForm({
    model,
    onZavarovanecIsSameAsZavarovalecChange,
    zavarovanecIsSameAsZavarovalec,
    onZavarovanecDatumRojstvaChange,
    zavarovalecData,
}) {

    const [
        zavarovalniskaStarostError,
        setZavarovalniskaStarostError,
    ] = useState({ hasError: false });

    useEffect(() => {
        if (zavarovanecIsSameAsZavarovalec) {
            validateDateInput(
                    { 
                        required: model.datumRojstva.required, 
                        conf: model.datumRojstva.conf,
                    },
                    zavarovalecData.datumRojstva.value,
                ).then(setZavarovalniskaStarostError);    
        }
    }, []);
    
    
    return (

        <div>
            <fieldset>
                <legend>Zavarovanje sklepam za</legend>
                <div
                    className='row'
                >   
                    <div
                        className='col s12'
                    >
                        <div 
                            className="switch input-field"
                        >
                            <label
                                style={{ position: 'relative' }}
                            >
                                svojega otroka
                            <input 
                            type="checkbox" 
                            checked={zavarovanecIsSameAsZavarovalec}                    
                            onChange={() => onZavarovanecIsSameAsZavarovalecChange(!zavarovanecIsSameAsZavarovalec)} 

                            />
                                <span className="lever"></span>
                                sebe
                            </label>
                        </div>
                    </div>
                </div>
            </fieldset>
            {
                !zavarovanecIsSameAsZavarovalec
                ? ''
                : <>
                    <fieldset>
                        <legend>Osnovni podatki</legend>
                        <div
                            className='row'
                        >
                            <div
                                className='col s12'
                            >
                                <StevilkaZdravsteveneKarticeInputField 
                                    isReadOnly={model.stevilkaZdravstveneKartice.readonly}
                                    error={model.stevilkaZdravstveneKartice.error}
                                    isRequired={model.stevilkaZdravstveneKartice.required}
                                    name={model.stevilkaZdravstveneKartice.name}
                                    onChange={value => model.stevilkaZdravstveneKartice.value = value}
                                    questionText={model.stevilkaZdravstveneKartice.text.sl}
                                    validateFn={validateStevilkaZdravstveneKartice.bind(null, { required: model.stevilkaZdravstveneKartice.required })}
                                    value={model.stevilkaZdravstveneKartice.value}
                                />
                            </div>
                            {
                                zavarovalniskaStarostError.hasError ?
                                <div
                                    className='col s12'
                                >
                                    <MessageBox
                                        category="error" 
                                        content="Starost zavarovanca je neustrezna. Zavarovanje lahko sklenejo osebe, stare od dopolnjenega 15. do 68. leta starosti."
                                    ></MessageBox>
                                </div>
                                : ''
                            }
                        </div>
                    </fieldset>
                </>
            }
            {
                zavarovanecIsSameAsZavarovalec
                ? ''
                : <>
                    <fieldset>
                        <legend>Osnovni podatki</legend>
                            <div className="row">
                                <div
                                    className="col s6"
                                >
                                    <TextInputField 
                                        isReadOnly={model.ime.readonly}
                                        error={model.ime.error}
                                        isRequired={model.ime.required}
                                        name={model.ime.name}
                                        onChange={value => model.ime.value = value}
                                        questionText={model.ime.text.sl}
                                        validateFn={validateTextInput.bind(null, { required: model.ime.required })}
                                        value={model.ime.value}
                                    />
                                </div>
                                <div
                                    className="col s6"
                                >
                                    <TextInputField 
                                        isReadOnly={model.priimek.readonly}
                                        error={model.priimek.error}
                                        isRequired={model.priimek.required}
                                        name={model.priimek.name}
                                        onChange={value => model.priimek.value = value}
                                        questionText={model.priimek.text.sl}
                                        validateFn={validateTextInput.bind(null, { required: model.priimek.required })}
                                        value={model.priimek.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <SpolField1
                                        isRequired={model.spol.required}
                                        onChange={value => model.spol.value = value}
                                        name={model.spol.name}
                                        error={model.spol.error}
                                        value={model.spol.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <DavcnaStevilkaField1 
                                        isReadOnly={model.davcnaStevilka.readonly}
                                        error={model.davcnaStevilka.error}
                                        isRequired={model.davcnaStevilka.required}
                                        name={model.davcnaStevilka.name}
                                        onChange={value => model.davcnaStevilka.value = value}
                                        questionText={model.davcnaStevilka.text.sl}
                                        validateFn={validateVatId1.bind(null, { required: model.davcnaStevilka.required })}
                                        value={model.davcnaStevilka.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <StevilkaZdravsteveneKarticeInputField 
                                        isReadOnly={model.stevilkaZdravstveneKartice.readonly}
                                        error={model.stevilkaZdravstveneKartice.error}
                                        isRequired={model.stevilkaZdravstveneKartice.required}
                                        name={model.stevilkaZdravstveneKartice.name}
                                        onChange={value => model.stevilkaZdravstveneKartice.value = value}
                                        questionText={model.stevilkaZdravstveneKartice.text.sl}
                                        validateFn={validateStevilkaZdravstveneKartice.bind(null, { required: model.stevilkaZdravstveneKartice.required })}
                                        value={model.stevilkaZdravstveneKartice.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <TextInputField 
                                        isReadOnly={model.krajRojstva.readonly}
                                        error={model.krajRojstva.error}
                                        isRequired={model.krajRojstva.required}
                                        name={model.krajRojstva.name}
                                        onChange={value => model.krajRojstva.value = value}
                                        questionText={model.krajRojstva.text.sl}
                                        validateFn={validateTextInput.bind(null, { required: model.krajRojstva.required })}
                                        value={model.krajRojstva.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <TextInputField 
                                        isReadOnly={model.drzavaRojstva.readonly}
                                        error={model.drzavaRojstva.error}
                                        isRequired={model.drzavaRojstva.required}
                                        name={model.drzavaRojstva.name}
                                        onChange={value => model.drzavaRojstva.value = value}
                                        questionText={model.drzavaRojstva.text.sl}
                                        validateFn={validateTextInput.bind(null, { required: model.drzavaRojstva.required })}
                                        value={model.drzavaRojstva.value}
                                    />
                                </div>
                                <div
                                    className='col s12'
                                >
                                    <DateInputField 
                                        isReadOnly={model.datumRojstva.readonly}
                                        error={model.datumRojstva.error}
                                        isRequired={model.datumRojstva.required}
                                        name={model.datumRojstva.name}
                                        onChange={value => { model.datumRojstva.value = value; onZavarovanecDatumRojstvaChange(); }}
                                        questionText={model.datumRojstva.text.sl}
                                        validateFn={validateDateInput.bind(null, { required: model.datumRojstva.required, conf: model.datumRojstva.conf })}
                                        value={model.datumRojstva.value}
                                        conf={model.datumRojstva.conf}
                                    />
                                </div>
                            </div>
                    </fieldset>
                </>
            }
        </div>
    );

}


