import { React, useEffect } from 'react';
import M from '@materializecss/materialize';
import '@materializecss/materialize/dist/css/materialize.min.css';
import VstopSSvetovanjemStrankaPrisotnaForm from '../form/VstopSSvetovanjemStrankaPrisotnaForm';
import AgentLinkValidator from '../other/AgentLinkValidator';
import { getPrefilled } from '../form/VstopSSvetovanjemStrankaPrisotna/prefillModel';
import { getVstopSSvetovanjemStrankaPrisotnaForm } from 'eureko-lib';


export default function SklepanjeVstopSvetovalecRoute(props) {

    useEffect(() => {

        M.AutoInit();

    }, []);


    return (
        <AgentLinkValidator>
            <div className="container">
                <div className="row">
                    <div className="col s12 center">
                        <h4>Soglasje za sklepanje v prisotnosti svetovalca</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <VstopSSvetovanjemStrankaPrisotnaForm 
                            modelInitial={getPrefilled(getVstopSSvetovanjemStrankaPrisotnaForm())}
                        />
                    </div>
                </div>
            </div>
        </AgentLinkValidator>
    );
}
