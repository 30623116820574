import {React, useState, useEffect, useRef} from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';


export default function PotrditvenaKodaField(props) {
    const isRequired = (typeof (props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;
    const [ fidPrefix, setFidPrefix ] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [validationError, setValidationError] = useState(props.error);
    
    /**
     * @todo Add the following state variable
     * const [inputValue, setInputValue] = useState('');
     */
    
    const {t, i18n} = useTranslation(['translation', 'validation', 'flashes']);

    // Set appropirate validation error.
    let validationErrMessage = t('validation:field.validate.verificationCode');
    if (validationError?.required) {
        validationErrMessage = t('validation:field.validate.required');
    }

    const buttonText = useRef(<Trans>form.button.verificationCode</Trans>);
    const buttonClasses = useRef("waves-effect waves-light btn-small eureko-green eureko-blue-text");

    const handleChange = async (event) => {
        props.onChange(event);
    };

    const handleClick = async (event) => {
        // event.preventDefault();
        props.onSendOtpClick(event);
    };

    useEffect(() => {
        setValidationError(props.error);
    }, [props.error]);

    return (
        <div 
            className="row"
        >
            <div 
                className="col s12"
            >
                <div 
                    className="row"
                >
                    <div 
                        className="input-field col s12 center-align"
                    >
                        <button 
                            onClick={handleClick} 
                            type="button" 
                            className={
                                buttonClasses.current + 
                                " eureko-form-action-btn"
                            }
                        >
                            {buttonText.current}
                        </button>
                    </div>
                </div>
                <div 
                    className="row"
                >
                    <div 
                        className="input-field col s12 center-align"
                    >
                        <input 
                            id={fidPrefix + "potrditvenaKoda"} 
                            type="text" 
                            name={fidPrefix + "potrditvenaKoda"} 
                            pattern="[0-9]{6}" 
                            required={isRequired} 
                            className={validationError?.hasError ? 'invalid' : ''} 
                            onChange={handleChange} 
                        />
                        <label 
                            htmlFor={fidPrefix + "potrditvenaKoda"} 
                            className="teal-text text-darken-1"
                        >
                            <Trans>field.label.verificationCode</Trans>
                        </label>
                        <span 
                            className="helper-text"
                            hidden={!validationError?.hasError} 
                            data-error={validationErrMessage}
                            data-success=""
                        >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
