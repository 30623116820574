import { React, useState, useEffect, useRef } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
//import i18n from 'i18next';


export default function MobitelField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const {t, i18n} = useTranslation(['translation', 'validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <div className="input-field col s12">
            <input id={fidPrefix + "poklic"} type="text" name={fidPrefix + "poklic"} className="validate" required onChange={handleChange} />
            <label htmlFor={fidPrefix + "poklic"}><Trans>field.label.profession</Trans></label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
        </div>
    );
}
