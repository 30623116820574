import { React, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { parseFieldName, getActionType } from '../redux/common';
import ValidatorService from '../service/ValidatorService';


export default function IbanField(props) {
    const dispatch = useDispatch();
    
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const isRequired = (typeof (props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;
    const ibanInput = useRef();

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const normalizeIbanValue = (value) => {
        if (typeof(value) === 'string') {
            value = value.toUpperCase().replaceAll(' ', '');
        }

        if (value.length) {
            const c = value.charAt(0);
            const intC = parseInt(c);
            
            if (!isNaN(intC)) {
                value = 'SI56' + value;
            }
        }

        return value;
    };

    const getDisplayValue = (value) => {
        
        if (!value.startsWith('SI56')) {
            return value;
        }

        let parts = value.match(/.{3,4}/g);
        return parts.join(' ');
    };

    const validateIban = (value) => {
        let imeBanke = '';
        let validatorService = new ValidatorService();
        validatorService.validateIban({ iban: value })
        .then((result) => {
            if (result.success) {
                ibanInput.current.classList.remove('invalid');
                ibanInput.current.classList.add('valid');

                let fieldInfo = parseFieldName(props.relatedInputs.imeBankeInput.current.name);
                imeBanke = result.data.bankInfo.nazivBanke;
                if (fieldInfo.prefix.length) {
                    let type = getActionType(fieldInfo.prefix, 'UPDATED');
                    dispatch({
                        type: type,
                        payload: {
                            field:fieldInfo.name,
                            value: imeBanke
                        }
                    });
                }
            }
            else {
                ibanInput.current.classList.remove('valid');
                ibanInput.current.classList.add('invalid');
            }
        })
        .catch((result) => { 
        })
        .then((result) => {
            if (imeBanke.length) {
                props.relatedInputs.imeBankeInput.current.value = imeBanke;
            }

            props.relatedInputs.imeBankeInput.current.focus();
            
        });
        
    };

    const handleBlur = async (event) => {
        let normalizedValue = normalizeIbanValue(event.target.value);
        event.target.value = getDisplayValue(normalizedValue);
        let dummyEvent = {
            target: {
                name: event.target.name,
                value: normalizedValue
            }
        };
        handleChange(dummyEvent);
        validateIban(normalizedValue);
    };

    const handleChange = (event) => {
        /*let normalizeValue = event.target.value.toUpperCase().trim(' ');
        console.log('norm ', normalizeValue)*/
        let normalizeValue = normalizeIbanValue(event.target.value);
        //let normalizeValue = event.target.value.trim(' ');
        //event.target.value = getDisplayValue(normalizeValue);
        let dummyEvent = {
            target: {
                name: event.target.name,
                value: normalizeValue
            }
        }

        props.onChange(dummyEvent);
    };

    return (    
        <div className="input-field col s12">
            <input ref={ibanInput} 
                id={fidPrefix + "IBAN"} 
                type="text" 
                name={fidPrefix + "IBAN"} 
                className="validate" 
                onChange={handleChange} 
                onBlur={handleBlur}
                required={isRequired}
            />
            <label htmlFor={fidPrefix + "IBAN"}><Trans>field.label.iban</Trans></label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.ibanRequired')} data-success=""></span>
        </div>
    );
}

