import { parseFieldName } from '../../common';
import { APP_CORE_OTP_UPDATED } from '../types/appCoreType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case APP_CORE_OTP_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        
        default:
            return state;
    }
};