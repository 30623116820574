import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getActionType, parseFieldName, inputValueToUpperCase, 
    formatDecimal, datumEuToTimestamp } from '../redux/common';
import PaketBoxField from '../field/PaketBoxField';


export default function PaketBdcaRow(props) {

    const dispatch = useDispatch();

    const [fidPrefix, setFidPrefix] = useState(typeof(props.prefix) === 'string' ? props.prefix : '');
    const [paketState, setPaketState] = useState({
        'BDCA_STANDARD': false,
        'BDCA_PREMIUM': false,
        'BDCA_BASIC': false
    });

    /**
     * Note: value from this method is actually key from initial paketState
     * @param {*} event 
     * @param {*} value 
     */
    const handleClick = (event) => {
        // We need previous state later in this function to determine if we need to clear the store.
        let prevSelectedPaket = null;  // key from the object paketState
        for (let k in paketState) {
            if (paketState[k] === true) {
                prevSelectedPaket = k;
            }
        }

        let value = event.target.value;
        let currentPaketState = {...paketState};
        for (let k in currentPaketState) {
            if (k === value && currentPaketState[k] === true) {
                // State is the same as before.
                return;
            }

            currentPaketState[k] = false;
        }

        currentPaketState[value] = true;
        setPaketState(currentPaketState);

        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name);
        let type = '';
        if (fieldInfo.prefix.length) {
            
            type = getActionType(fieldInfo.prefix, 'UPDATED');
            let normalizedValue = value;
            normalizedValue = inputValueToUpperCase(normalizedValue);

            if (prevSelectedPaket === 'BDCA_BASIC' || value === 'BDCA_BASIC') {
                // Clear the store.
                dispatch({type: 'ROOT_APP_RESET', payload: {
                    field: event.target.name,
                    value: normalizedValue
                }});
                console.debug("After cleaing the store...");
            }
            
            
            dispatch({
                type: type,
                payload: {
                    field: event.target.name,
                    value: normalizedValue
                }
            });

        }

    }

    return (<>
        <div className="row">
            <PaketBoxField prefix="meta_" 
                value="BDCA_STANDARD" 
                description="Standard Critical Advantage"
                onClick={handleClick}
                active={paketState.BDCA_STANDARD}
            >
            </PaketBoxField>
            <PaketBoxField prefix="meta_" 
                value="BDCA_PREMIUM" 
                description="Premium Critical Advantage"
                onClick={handleClick}
                active={paketState.BDCA_PREMIUM}
            >
            </PaketBoxField>
            <PaketBoxField prefix="meta_" 
                value="BDCA_BASIC" 
                description="Basic"
                onClick={handleClick}
                active={paketState.BDCA_BASIC}
            >
            </PaketBoxField>
        </div>
        
    </>);
}