import { parseFieldName } from '../common';
import privolitvenaEurekoPrivolitevQ33Reducer from './privolitvenaEurekoPrivolitevQ33Reducer';
import { ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED, ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED } from '../types/zavarovalecType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED:
        
            let privolitev = {};
            if (typeof (state.q62) !== 'undefined') {
                privolitev = state.q62;
            }

            return { ...state, q62: privolitvenaEurekoPrivolitevQ33Reducer(privolitev, action) };

        default:
            return state;
    }
};


