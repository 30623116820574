import { React, useState, useEffect, useRef, createRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import M from '@materializecss/materialize';
import '../asset/eurekodefault/sass/materialize.scss';
import { useTranslation } from 'react-i18next';
import DrBestBscFrom from '../form/DrBestBscForm';
import DrBestBasicForm from '../form/DrBestBasicForm';
import PaketBdcaRow from '../part/PaketBdcaRow';



export default function DrBestStarmanRoute() {
    const navigate = useNavigate();
    const [agentParamsStatus, setAgentParamsStatus] = useState('');

    const { t, i18n } = useTranslation();
    
    const selectPaket = useSelector((state) => {
        if (typeof (state.meta) === 'undefined') {
            return null;
        }
        else if (typeof(state.meta.paket) === 'undefined') {
            return null;
        }

        return state.meta.paket;
    });

    useEffect(() => {
        document.title = "Pridruži se Dr Best";
        M.AutoInit();

    }, []);

    useEffect(() => {
        if (selectPaket === null) {
            navigate('/izbira-paketa');
        }
    }, [
        selectPaket,
        navigate
    ]);

    return (
        
        <div className="container">
            <div className="row">
                <div
                    className="col s12"
                >
                    &nbsp;
                </div>
                <div className="col s12">
                    {
                        selectPaket === 'BDCA_BASIC' && <DrBestBasicForm channel="509adc98" /> /* Dr Best Eureko */
                    }
                    {
                        (
                            selectPaket === 'BDCA_PREMIUM' || 
                            selectPaket === 'BDCA_STANDARD'
                            
                        ) && 
                        <DrBestBscFrom channel="509adc98" /> /* Dr Best Eureko */
                    }
                </div>
            </div>
            
        </div>
    );
}
