import { React } from 'react';
import DateInputField from '../../field/DateInputField';
import DavcnaStevilkaField1 from '../../field/DavcnaStevilkaField1';
import NaslovField1 from '../../field/NaslovField1';
import PodpisField1 from '../../field/PodpisField1';
import SpolField1 from '../../field/SpolField1';
import TextInputField from '../../field/TextInputField1';
import { validateAddressInputGroup, validateDateInput, validateTextInput, validateVatId1 } from 'eureko-lib';
import TranskacijskiRacunInputField from '../../field/TranskacijskiRacunInputField';
import CheckboxField from '../../field/CheckboxField';



export default function PrvaDiagnozaSepaForm({
    model,
    zavarovalecModel,
    sepaPersonIsSameAsZavarovalec,
    onSepaPersonIsSameAsZavarovalecChange,
}) {

    return (

        <div>
            <div>
                <CheckboxField
                    questionText="Plačnik je isti kot zavarovalec"
                    value={sepaPersonIsSameAsZavarovalec}
                    onChange={value => onSepaPersonIsSameAsZavarovalecChange(value)}
                />
            </div>
            {
            sepaPersonIsSameAsZavarovalec ?
            <fieldset>
                <legend>Plačnik</legend>
                    <div className="row">
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={true}
                                error={zavarovalecModel.ime.error}
                                isRequired={zavarovalecModel.ime.required}
                                name={zavarovalecModel.ime.name}
                                onChange={value => zavarovalecModel.ime.value = value}
                                questionText={zavarovalecModel.ime.text.sl}
                                validateFn={validateTextInput.bind(null, { required: zavarovalecModel.ime.required })}
                                value={zavarovalecModel.ime.value}
                            />
                        </div>
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={true}
                                error={zavarovalecModel.priimek.error}
                                isRequired={zavarovalecModel.priimek.required}
                                name={zavarovalecModel.priimek.name}
                                onChange={value => zavarovalecModel.priimek.value = value}
                                questionText={zavarovalecModel.priimek.text.sl}
                                validateFn={validateTextInput.bind(null, { required: zavarovalecModel.priimek.required })}
                                value={zavarovalecModel.priimek.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <SpolField1
                                isReadOnly={true}
                                isRequired={zavarovalecModel.spol.required}
                                onChange={value => zavarovalecModel.spol.value = value}
                                name={zavarovalecModel.spol.name}
                                error={zavarovalecModel.spol.error}
                                value={zavarovalecModel.spol.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DateInputField 
                                isReadOnly={true}
                                error={zavarovalecModel.datumRojstva.error}
                                isRequired={zavarovalecModel.datumRojstva.required}
                                name={zavarovalecModel.datumRojstva.name}
                                onChange={value => { zavarovalecModel.datumRojstva.value = value; }}
                                questionText={zavarovalecModel.datumRojstva.text.sl}
                                validateFn={validateDateInput.bind(null, { required: zavarovalecModel.datumRojstva.required, conf: zavarovalecModel.datumRojstva.conf })}
                                value={zavarovalecModel.datumRojstva.value}
                                conf={zavarovalecModel.datumRojstva.conf}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DavcnaStevilkaField1 
                                isReadOnly={true}
                                error={zavarovalecModel.davcnaStevilka.error}
                                isRequired={zavarovalecModel.davcnaStevilka.required}
                                name={zavarovalecModel.davcnaStevilka.name}
                                onChange={value => zavarovalecModel.davcnaStevilka.value = value}
                                questionText={zavarovalecModel.davcnaStevilka.text.sl}
                                validateFn={validateVatId1.bind(null, { required: zavarovalecModel.davcnaStevilka.required })}
                                value={zavarovalecModel.davcnaStevilka.value}
                            />
                        </div>
                    </div>
            </fieldset>
            : ''
            }
            {
            !sepaPersonIsSameAsZavarovalec ?
            <fieldset>
                <legend>Plačnik</legend>
                    <div className="row">
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={model.ime.readonly}
                                error={model.ime.error}
                                isRequired={model.ime.required}
                                name={model.ime.name}
                                onChange={value => model.ime.value = value}
                                questionText={model.ime.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.ime.required })}
                                value={model.ime.value}
                            />
                        </div>
                        <div
                            className="col s6"
                        >
                            <TextInputField 
                                isReadOnly={model.priimek.readonly}
                                error={model.priimek.error}
                                isRequired={model.priimek.required}
                                name={model.priimek.name}
                                onChange={value => model.priimek.value = value}
                                questionText={model.priimek.text.sl}
                                validateFn={validateTextInput.bind(null, { required: model.priimek.required })}
                                value={model.priimek.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <SpolField1
                                isRequired={model.spol.required}
                                onChange={value => model.spol.value = value}
                                name={model.spol.name}
                                error={model.spol.error}
                                value={model.spol.value}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DateInputField 
                                isReadOnly={model.datumRojstva.readonly}
                                error={model.datumRojstva.error}
                                isRequired={model.datumRojstva.required}
                                name={model.datumRojstva.name}
                                onChange={value => { model.datumRojstva.value = value; }}
                                questionText={model.datumRojstva.text.sl}
                                validateFn={validateDateInput.bind(null, { required: model.datumRojstva.required, conf: model.datumRojstva.conf })}
                                value={model.datumRojstva.value}
                                conf={model.datumRojstva.conf}
                            />
                        </div>
                        <div
                            className='col s12'
                        >
                            <DavcnaStevilkaField1 
                                isReadOnly={model.davcnaStevilka.readonly}
                                error={model.davcnaStevilka.error}
                                isRequired={model.davcnaStevilka.required}
                                name={model.davcnaStevilka.name}
                                onChange={value => model.davcnaStevilka.value = value}
                                questionText={model.davcnaStevilka.text.sl}
                                validateFn={validateVatId1.bind(null, { required: model.davcnaStevilka.required })}
                                value={model.davcnaStevilka.value}
                            />
                        </div>
                    </div>
            </fieldset>
            : ''
            }
            {
            sepaPersonIsSameAsZavarovalec ?
            <fieldset>
                <legend>Naslov</legend>
                <div
                    className='row'
                >
                    <NaslovField1
                        isReadOnly={true}
                        error={zavarovalecModel.naslov.error}
                        isRequired={zavarovalecModel.naslov.required}
                        name={zavarovalecModel.naslov.name}
                        onChange={value => zavarovalecModel.naslov.value = value}
                        value={zavarovalecModel.naslov.value}
                        validateFn={validateAddressInputGroup.bind(null, { required: zavarovalecModel.naslov.required })}
                    />
                </div>
            </fieldset>
            : ''
            }
            {
            !sepaPersonIsSameAsZavarovalec ?
            <fieldset>
                <legend>Naslov</legend>
                <div
                    className='row'
                >
                    <NaslovField1
                        isReadOnly={model.naslov.readonly}
                        error={model.naslov.error}
                        isRequired={model.naslov.required}
                        name={model.naslov.name}
                        onChange={value => model.naslov.value = value}
                        value={model.naslov.value}
                        validateFn={validateAddressInputGroup.bind(null, { required: model.naslov.required })}
                    />
                </div>
            </fieldset>
            : ''
            }
            <fieldset>
                <legend>Transakcijski račun</legend>
                <div
                    className='row'
                >
                    <div
                        className="col s12"
                    >
                        <TranskacijskiRacunInputField
                            isReadOnly={model.transakcijskiRacun.readonly}
                            error={model.transakcijskiRacun.error}
                            isRequired={model.transakcijskiRacun.required}
                            name={model.transakcijskiRacun.name}
                            onChange={value => model.transakcijskiRacun.value = value}
                            value={model.transakcijskiRacun.value}
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <p>
                        Naziv prejemnika plačila:
                        </p>
                        <p>
                        PRVA OSEBNA ZAVAROVALNICA, d. d.,
                        Fajfarjeva ulica 33, 1000 Ljubljana, Slovenija
                        Ident. oznaka prejemnika plačila: SI23ZZZ86404024
                        </p>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Podpisi</legend>
                <div
                    className='row'
                >
                    <div
                        className="col s12"
                    >
                        <PodpisField1
                            isRequired={model.podpisPlacnika.required}
                            onChange={value => model.podpisPlacnika.value = value}
                            name={model.podpisPlacnika.name}
                            questionText={model.podpisPlacnika.text.sl}
                            error={model.podpisPlacnika.error}
                            value={model.podpisPlacnika.value}
                            
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <PodpisField1
                            isRequired={model.podpisZavarovalec.required}
                            onChange={value => model.podpisZavarovalec.value = value}
                            name={model.podpisZavarovalec.name}
                            questionText={model.podpisZavarovalec.text.sl}
                            error={model.podpisZavarovalec.error}
                            value={model.podpisZavarovalec.value}
                            
                        />
                    </div>
                    <div
                        className="col s12"
                    >
                        <p>
                        S podpisom tega obrazca pooblaščate (A) Prvo osebno zavarovalnico d. d., da posreduje navodila vašemu ponudniku plačilnih storitev za obremenitev vašega plačilnega računa in (B) vašega ponudnika plačilnih storitev, da obremeni vaš plačilni račun v skladu z navodili, ki jih posreduje Prva osebna zavarovalnica d. d.. Vaše pravice obsegajo tudi pravico do povračila denarnih sredstev s strani vašega ponudnika plačilnih storitev v skladu s splošnimi pogoji vašega ponudnika plačilnih storitev. Povračilo denarnih sredstev je potrebno terjati v roku 8 tednov, ki prične teči od dne, ko je bil obremenjen vaš plačilni račun. Vaše pravice v zvezi s tem soglasjem so navedene v splošnih pogojih poslovanja, ki jih lahko dobite pri vašem ponudniku plačilnih storitev.
                        </p>
                        <p>
                        S svojim podpisom soglašam, da sem seznanjen z nameravanimi izvršitvami SDD. Dovoljujem, da se vsakega 18. v mesecu nakazuje premija prejemniku plačil v višini kot izhaja iz sklenjene pogodbe. Hkrati dovoljujem, da upnik prilagaja premijo, kot je določeno v pogodbi, ki velja za to zavarovanje, splošnih pogojih in ceniku/pokojninskem načrtu in se odpovedujem pravici do nadaljnjega obveščanja o bodočih bremenitvah.                       
                        </p>
                        <p>
                        Hkrati soglašam, da se morebitne zapadle obveznosti prvega vplačila poravnajo pri naslednji obremenitivi oz. jih dolžnik poravna sam.
                        </p>
                        <p>
                        S podpisom tega soglasja jamčim za resničnost in pravilnost zgoraj navedenih podatkov in se zavezujem zagotoviti kritje na računu za znesek direktne obremenitve SEPA.                       
                        </p>
                        <p>
                        Vse spremembe tega soglasja bom posredoval prejemniku plačila najkasneje 14 dni pred nameravano spremembo.
                        </p>
                        <p>
                        Seznanjen sem z informacijami o obdelavi osebnih podatkov ter o pravicah, ki jih imam iz naslova varstva osebnih podatkov in so opredeljene v dokumentu Pravno obvestilo glede varstva osebnih podatkov strank oziroma v Politiki zasebnosti Prve osebne zavarovalnice, d.d., ki je objavljena na spletni strani www.prva.si in dostopna na sedežu zavarovalnice.
                        </p>
                        <p>
                        Seznanjen sem, da lahko Prva osebna zavarovalnica, d.d., pridobljene osebne podatke uporablja tudi za trženje svojih storitev in produktov ter da lahko kadarkoli zavrnem takšno uporabo s pisno zahtevo, poslano na naslov: Prva osebna zavarovalnica, d.d, Fajfarjeva ulica 33, 1000 Ljubljana ali na info@prva.si. Podani ugovor ne vpliva na zakonitost obdelave do trenutka njegove podaje niti na obdelavo na drugih pravnih podlagah.
                        </p>
                    </div>
                </div>
            </fieldset>
        </div>
    );

}


