import { React, useEffect, useState } from 'react';
import RadioMultipleField from '../../field/RadioMultipleField';
import TextInputField from '../../field/TextInputField1';

export default function PrvaDiagnozaPremijaForm({
    model,
    prices,
}) {


    const [ displayPrices, setDisplayPrices ] = useState({ neto: '/', bruto: '/', davek: '/' });
    useEffect(() => {
        const dp = {
            neto: prices.neto === null ? '/' : String(prices.neto.toFixed(2)).replace('.', ','),
            bruto: prices.bruto === null ? '/' : String(prices.bruto.toFixed(2)).replace('.', ','),
            davek: prices.davek === null ? '/' : String(prices.davek.toFixed(2)).replace('.', ','),
        }
        setDisplayPrices(dp);
    }, [prices]);

    return (
        <div>
            <fieldset>
                <legend>Premija</legend>
                <div
                    className='row'
                >
                    <div
                        className='col s12'
                    >
                        <RadioMultipleField
                            isRequired={model.placiloPrvePremija.required}
                            onChange={value => model.placiloPrvePremija.value = value}
                            name={model.placiloPrvePremija.name}
                            questionText={model.placiloPrvePremija.text.sl}
                            error={model.placiloPrvePremija.error}
                            options={model.placiloPrvePremija.options}
                            value={model.placiloPrvePremija.value}
                        />
                    </div>
                    <div
                        className='col s12'
                    >
                        <TextInputField
                            isReadOnly={true}
                            name='neto'
                            questionText='Osnovna premija'
                            value={displayPrices.neto}
                        />
                        <p>{'(EUR)'}</p>
                    </div>
                    <div
                        className='col s12'
                    >
                        <TextInputField
                            isReadOnly={true}
                            name='davek'
                            questionText='Davek od prometa zavarovalnih poslov'
                            value={displayPrices.davek}
                        />
                        <p>{'(EUR)'}</p>
                    </div>
                    <div
                        className='col s12'
                    >
                        <p>
                            Premija za plačilo znaša: &nbsp;
                            <span className='text-red'>
                                {displayPrices.bruto} €
                            </span>
                        </p>
                        <p>
                        Premija za plačilo že vsebuje 8,5 % DPZP. Zavarovanje je sklenjeno za dve leti in se po preteku vsakega zavarovalnega leta samodejno podaljša do zavarovančevega dopolnjenega 70. leta starosti. Ob prehodu zavarovanca v višji starostni razred se ob začetku novega zavarovalnega leta višina premije za naslednja zavarovalna leta uskladi z veljavnim cenikom.
                        </p>
                    </div>
                </div>
            </fieldset>
        </div>
    );

}


