class AgentService {
    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        console.log('Agent service url ' + url);
        switch (endpoint) {
            case 'agentsRead':
                url = url + '/agents';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    /**
     * 
     * @param {object} contactInfo {email: "", mobitel: ""}
     */
    async checkAgentLink(queryParams) {
        let url = this.getUrl('agentsRead');
        let params = {
            t: typeof queryParams['t'] == 'string' ? queryParams['t'] : '',
            h: typeof queryParams['h'] == 'string' ? queryParams['h'] : ''
        };
        
        let result = {
            status: false,
            message: "Params missing."
        };

        // There is no point in calling - params are missing.
        if (!params.t.length || !params.h.length) {
            return result;
        }

        url = url + '?t=' + params.t + '&h=' + params.h;

        try {

            let response = await fetch(url, {
                'method': 'GET',
                // 'body': JSON.stringify(requestObject)
            });

            result = await response.json();
            
        } catch (error) {
            console.log("error ");
            console.log(error);
            result = {
                status: false,
                errors: [{
                    code: 'CONNECTION_ERROR',
                    description: ''
                }],
                message: error.message
            };
        } finally {
            return result;
        }
        

    }
}

export default AgentService;