import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function PaketField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <>
            <div className="input-field col s12">
                Izberite paket
                <p>
                    <label>
                        <input name={fidPrefix + "paket"} type="radio" value="A" required={true} onChange={handleChange} />
                        <span>Kritje A</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + "paket"} type="radio" value="B" required={true} onChange={handleChange} />
                        <span>Kritje B</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + "paket"} type="radio" value="C" required={true} onChange={handleChange} />
                        <span>Kritje C</span>
                    </label>
                </p>
                <p>
                    Označite izbrani paket A, B ali C.
                </p>
                
                <span className="helper-text" data-error={i18n.t('validation:field.validate.paketRequired')} data-success=""></span>
            </div>
        </>
    );
}

