import { React, useEffect, useState } from 'react';

export default function FileInputField({
    name,
    onChange,
    value,
    hintText,
}) {

    const [ files, setFiles ] = useState([]);

    useEffect(() => {
        if (value) {
            setFiles(value);
        }
    }, [ value ]);

    const handleDrop = async (event) => {
        if (files.length === 3) {
            return
        };
        if (event.target?.files?.length) {
            const newFiles = [ ...files ];
            for (const file of event.target.files) {
                const fileName = file.name;
                if (!newFiles
                        .some(file => file.fileName === fileName)) {
                    const base64 
                        = await getBase64FromFile(file);
                    newFiles.push({
                        base64,
                        fileName,
                    });
                }
            }
            setFiles(newFiles);
            handleChange(newFiles);

            event.target.value = null;
        }
    }

    const removeFile = (file) => {
        const newFiles = [ ...files ].filter(f => f !== file);
        setFiles(newFiles);
        handleChange(newFiles);
    }

    const handleChange = async (files) => {
        if (onChange)
            onChange(files);
    };

    return (
        <>
            <div
                className='file-input-field'
            >
                <div
                    className='box'
                >
                    <div 
                        className="box__input"
                        onDrop={handleDrop}
                    >
                        <input 
                            className="box__file" 
                            type="file" 
                            name="files[]" 
                            id={name} 
                            data-multiple-caption="{count} files selected" 
                            multiple
                            onChange={handleDrop}
                        />
                        <label 
                            htmlFor={name}
                        >
                            {
                                files.length !== 3 ?
                                <div className='eureko-upload-button'>
                                    <i className="material-icons">upload</i>
                                    Izberi datoteko
                                </div>
                                : ''                
                            }
                            {
                                files.map(file => {
                                    return (
                                        <div
                                            key={file.fileName}
                                            className="eureko-uploaded-file"
                                        >
                                            <i 
                                                className="material-icons text-red"
                                                role="button"
                                                onClick={$event => {$event.preventDefault(); removeFile(file)}}
                                            >
                                                delete
                                            </i>
                                            <span>
                                                {file.fileName}
                                            </span>
                                        </div>
                                    );
                                })
                            }
                        </label>
                    </div>
                </div>
                {
                    hintText ?
                    <span 
                        className='helper-text'
                    >
                        {hintText}
                    </span>
                    : ''
                }
            </div>
        </>
    );
    
}

function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              resolve(reader.result);
            };
            reader.onerror = function (error) {
              reject(error);
            };            
        } catch (error) {
            reject(error);
        }
    });
}
