import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function SpolSelectField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const selectInput = useRef(null);

    const handleChange = (event) => {
        props.onChange(event);
    };

    const fieldName = fidPrefix + 'spol';

    useEffect(() => {
        selectInput.current.value = '';
        let dummyEvent = {
            target: {
                name: (fieldName),
                value: selectInput.current.value
            }
        };
        props.onChange(dummyEvent);

    }, []);

    const { t, i18n } = useTranslation(['translation', 'validation', 'flashes', 'backend']);

    return (
        <div className="input-field col s12">
            
            <select className="validate" ref={selectInput} id={fieldName} name={fieldName} onChange={handleChange} required>
                <option value="">-- Izberi --</option>
                <option value="M">Moški</option>
                <option value="Ž">Ženski</option>
            </select>
            <label htmlFor={fieldName}>Spol</label>
            
            <span className="helper-text" data-error={t('validation:field.validate.gender')} data-success=""></span>
        </div>
    );
}

