import { React, useState } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function FrekvencaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <>
            <div className="input-field col s12">
                Premijo želim plačevati
                <p>
                    <label>
                        <input name={fidPrefix + "frekvenca"} type="radio" onChange={handleChange} value="MESECNO" required />
                        <span>mesečno</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + "frekvenca"} type="radio" onChange={handleChange} value="LETNO" required />
                        <span>letno</span>
                    </label>
                    
                </p>
                
                <span className="helper-text" data-error={i18n.t('validation:field.validate.frekvencaPlacilaRequired')} data-success=""></span>
            </div>
        </>
    );
}

