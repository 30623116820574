import { parseFieldName } from '../common';
import { ZAVAROVANEC_PREMIJA_UPDATED } from '../types/zavarovanaOsebaType';

//neto = "10,55" davek = "0,9" bruto = "11,45"
const INITIAL_STATE = {
    neto: null,
    davek: null,
    bruto: null
};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    };
    switch (action.type) {
        case ZAVAROVANEC_PREMIJA_UPDATED:
            let fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        default:
            return state;
    }
};