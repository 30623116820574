import { React, useState, useEffect, useRef } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import M from '@materializecss/materialize';
import SignaturePad from 'react-signature-pad-wrapper';
import { appLogDebug } from 'eureko-lib';
import HiddenField from './HiddenField';


export default function PodpisField(props) {
    const sigCanvas = useRef({});

    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [fidName, setFieldName] = useState(props.updateFormDataField);
    const [canvasDim, setCanvasDim] = useState({
        height: 150,
        width: 300
    });
    const [signatureStatus, setSignatureStatus] = useState(props.signatureStatus ?? 'CLOSED');
    const [signatureData, setSignatureData] = useState(null);
    const [webSignatureData, setWebSignatureData] = useState(null);

    const [signatureSavedClass, setSignatureSavedClass] = useState('');

    /**
     * It needs to be string so we can pass it into hidden field component.
     */
    const [signed, setSigned] = useState("0");

    const { t, i18n } = useTranslation(['translation', 'flashes']);

    //**blob to dataURL**
    function blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) { callback(e.target.result); }
        a.readAsDataURL(blob);
    }


    const clear = () => {
        if (sigCanvas.current === null || typeof (sigCanvas.current.instance) === 'undefined') {
            return;  // Nothing to do here yet.
        }

        sigCanvas.current.instance.clear();
        props.onPodpisFieldChange(fidName, null);
        setSigned("0");
        setSignatureData(null);
    };

    const handleOpenCloseSignatureField = (e) => {
        clear();
        if (signatureStatus === 'OPENED') {
            setSignatureStatus('CLOSED');
        }
        else {
            setSignatureStatus('OPENED');
            setSignatureSavedClass('');
        }

        appLogDebug("Signature status ", signatureStatus);
    };

    const handlePodpisFieldChange = (e) => {
        if (sigCanvas === null || sigCanvas.current === null) {
            e.preventDefault();
            return;
        }

        if (sigCanvas.current.isEmpty()) {
            setSigned("0");
            e.preventDefault();
            return;
        }

        setWebSignatureData(sigCanvas.current.toDataURL('image/png'));


        sigCanvas.current.canvas.current.toBlob((orgData) => {
            new Compressor(orgData, {
                quality: 0.8,
                width: 300,
                height: 180,
                resize: "cover",

                success(result) {
                    setCanvasDim({
                        width: sigCanvas.current.canvas.current.offsetWidth,
                        height: sigCanvas.current.canvas.current.offsetHeight
                    });
                    const canvas = sigCanvas.current.canvas;
                    appLogDebug("Canvas offsetHeight: ", canvas.current.offsetHeight, " offsetWidth ", canvas.current.offsetWidth);
                    setSigned("1");

                    blobToDataURL(result, (data) => {
                        let fieldName = fidPrefix + fidName;
                        props.onPodpisFieldChange(
                            fieldName,
                            data
                        );
                        setSignatureData(data);
                        setSignatureStatus('CLOSED');
                        setSignatureSavedClass('eureko-signature-1-filled');
                        M.toast({ html: t("flashes:form.podpisField.saved") });
                    });


                },
                error(err) {
                    appLogDebug(err.message);
                },
            });
        });

    };

    return (
        <div className="row">
            <div className={"input-field col s12 eureko-signature eureko-signature-1 " + signatureSavedClass}>
                <HiddenField
                    prefix={fidPrefix}
                    name={fidName}
                    value={signed}
                    onChange={(e) => { }}
                    clName="eureko-signature"
                />
                <div className="card-title">
                    {props.signatureFor === 'agent' && <Trans>field.label.signatureAgent</Trans>}
                    {props.signatureFor === 'customer' && <Trans>field.label.signatureCustomer</Trans>}
                    {props.signatureFor === 'customerZavarovanec' && <Trans>field.label.signatureCustomerZavarovanec</Trans>}
                    {props.signatureFor === 'payer' && <Trans>field.label.signaturePayer</Trans>}
                </div>
                <div className="card-stacked">
                    <div className="card-content">
                        {signatureStatus === 'OPENED' && <SignaturePad
                            ref={sigCanvas}
                            redrawOnResize={true}
                            options={
                                { penColor: 'rgb(0, 0, 0)', backgroundColor: 'rgb(255, 255, 255)', minWidth: 2 }
                            }
                        />}
                        {signatureData !== null && signatureStatus === 'CLOSED' && <img style={canvasDim} src={webSignatureData} />}
                    </div>
                    <div className="card-action center">
                        <button type="button" className="btn-small red darken-2" onClick={handleOpenCloseSignatureField}>Dodaj podpis</button>
                        <button type="button" className="btn-small green darken-2" onClick={handlePodpisFieldChange}>Shrani podpis</button>
                        <br />
                        <small>Prosimo pred nadaljevanjem shranite podpis.</small>
                    </div>
                </div>
            </div>
        </div>

    );
}