import { React, useEffect, useState } from 'react';

export default function PostnaStevilkaInputField({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    isReadOnly,
    validateFn,
    value,
}) {

    const [ validationError, setValidationError ] = useState(error);
    const [ inputValue, setInputValue ] = useState(value || '');

    useEffect(() => {
        setValidationError(error);
    }, [error]);

    useEffect(() => {
        setInputValue(value || '');
    }, [ value ]);

    const handleChange = (event) => {

        const value = event.target.value; 
        setInputValue(value);

        if (onChange)
            onChange(value);

        if (validateFn) {
            validateFn(value)
                .then(errors => {
                    setValidationError(errors);
                });
        }
    };

    return (
        <div
            className={'input-field' + (validationError?.hasError ? ' invalid' : '')}
        >
            <input 
                id={name} 
                type="text" 
                name={name} 
                required={isRequired} 
                readOnly={isReadOnly} 
                onChange={handleChange}
                onBlur={handleChange}
                className={ validationError?.hasError ? 'invalid' : '' }
                value={inputValue}
            />
            <label 
                htmlFor={name}
                className={inputValue ? 'active' : ''}    
            >
                {questionText}
            </label>
            <span 
                className="helper-text"
                hidden={!validationError?.hasError}    
            >   
                {
                    validationError?.required ?
                    'Prosimo izpolnite to polje'
                    : ''
                }
                {
                    validationError?.invalidZipCode ?
                    'Vnesite veljavni poštno številko'
                    : ''
                }
                {
                    validationError?.error || ''
                }
            </span>
        </div>
    );
    
}
