import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DaNeField1({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    value,
}) {
    const { t } = useTranslation(['validation', 'flashes']);

    const [ inputValue, setInputValue ] = useState(value || undefined);

    useEffect(() => {
        setInputValue(value || undefined);
    }, [ value ]);

    const handleChange = (event) => {
        const val = event.target.value;
        setInputValue(val);

        if (onChange)
            onChange(val);
    };

    return (
        <div
        className={'input-field' + (error?.hasError ? ' invalid' : '')}
        >

                {questionText}
                <div
                    style={{ marginTop: '14px', marginBottom: '28px' }}
                >
                    <label
                        className={ error?.hasError ? 'eureko-input-boolean-required' : '' }
                    >
                        <input 
                            name={name} 
                            type="radio" 
                            value="D" 
                            required={isRequired} 
                            onChange={handleChange}
                            checked={inputValue==='D'}
                        />
                        <span>Da</span>
                    </label>
                    <label className={"eureko-radio-label" + ( error?.hasError ? ' eureko-input-boolean-required' : '')}>
                        <input 
                            name={name} 
                            type="radio" 
                            value="N" 
                            required={isRequired} 
                            onChange={handleChange} 
                            checked={inputValue==='N'}
                        />
                        <span>Ne</span>
                    </label>
                </div>
                {
                    error?.hasError
                    ? <span 
                        className="helper-text eureko-input-boolean-required" 
                    >
                        {t('validation:field.validate.daneFieldRequired')}
                    </span>
                    : '' 
                }
        </div>
    );
}

