import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import M from '@materializecss/materialize';
import SignaturePad from 'react-signature-pad-wrapper';

import HiddenField from './HiddenField';

export default function PodpisField1({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    value,
}) {

    const sigCanvas = useRef({});
    const sigImage = useRef({});
    const [webSignatureData, setWebSignatureData] = useState(null);
    const [signatureSavedClass, setSignatureSavedClass] = useState('');

    useEffect(() => {
        if (value)
            getSignatureForDisplay(value);
    }, [ value ]);

    const { t } = useTranslation(['translation', 'flashes']);

    //**blob to dataURL**
    function blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) { callback(e.target.result); }
        a.readAsDataURL(blob);
    }

    const getDimensionsForDisplay = () => {
        if (webSignatureData) {
            return {
                width: sigImage?.current?.clientWidth || 300,
                height: sigImage?.current?.clientHeight || 150, 
            };
        } else {
            return {
                width: sigCanvas?.current?.canvas?.current?.offsetWidth || 300,
                height: sigCanvas?.current?.canvas?.current?.offsetHeight || 150, 
            }
        }
    }

    function getSignatureForDisplay (dataUrl) {
        const dimensions = getDimensionsForDisplay();
        new Compressor(dataURItoBlob(dataUrl), {
            quality: 1,
            width: dimensions.width,
            height: dimensions.height,
            resize: "cover",

            success(result) {
                blobToDataURL(result, (data) => {
                    setWebSignatureData(data);
                });
            }
        });
    }

    const clear = () => {
        onChange(undefined);
        setWebSignatureData(null);
        if (sigCanvas.current === null || typeof (sigCanvas.current.instance) === 'undefined') {
            return;  // Nothing to do here yet.
        } else {
            sigCanvas.current.instance.clear();
        }
    };

    const handlePodpisFieldChange = (e) => {

        if (sigCanvas === null || sigCanvas.current === null) {
            e.preventDefault();
            return;
        }

        if (sigCanvas.current.isEmpty()) {
            e.preventDefault();
            return;
        }

        getSignatureForDisplay(sigCanvas.current.toDataURL('image/png'));

        sigCanvas.current.canvas.current.toBlob((orgData) => {
            new Compressor(orgData, {
                quality: 0.8,
                width: 300,
                height: 150,
                resize: "cover",

                success(result) {
                    blobToDataURL(result, (data) => {
                        onChange(data);
                        setSignatureSavedClass('eureko-signature-1-filled');
                        M.toast({ html: t("flashes:form.podpisField.saved") });
                    });
                }
            });
        });

    };

    return (
        <div 
            className="row"
        >
            <div 
                className={
                    "input-field col s12 eureko-signature eureko-signature-1 " + 
                    signatureSavedClass + 
                    (error?.hasError ? ' eureko-signature-1-invalid' : '')
                }
            >
                <HiddenField
                    /** We pass full name to PodpisField1 because of this we need to set prefix to empty string 
                     * when passing to HiddenField. */
                    prefix="" 
                    name={name}
                    value={ (value) ? "1" : "0" }
                    onChange={(e) => { }}
                    clName="eureko-signature"
                />
                <div 
                    className="card-title"
                >
                    {questionText}
                </div>
                <div 
                    className="card-stacked"
                >
                    <div 
                        className="card-content"
                    >
                        {
                            (!webSignatureData)  ?
                            <SignaturePad
                                ref={sigCanvas}
                                redrawOnResize={true}
                                options={
                                    { 
                                        penColor: 'rgb(0, 0, 0)', 
                                        backgroundColor: 'rgb(255, 255, 255)', 
                                        minWidth: 2 
                                    }
                                }
                            /> : ''
                        }
                        {webSignatureData && <img src={webSignatureData} ref={sigImage}/>}
                    </div>
                    <div 
                        className="card-action center"
                    >
                        {
                            (!webSignatureData) 
                            ? <button 
                                    type="button" 
                                    className="btn-small green darken-2" 
                                    onClick={handlePodpisFieldChange}
                                >
                                    Shrani podpis
                                </button> 
                                : <button 
                                    type="button" 
                                    className="btn-small red darken-2" 
                                    onClick={clear}
                                >
                                    Izbriši podpis
                                </button>
                        }
                        
                        
                        <br />
                        <small>Prosimo pred nadaljevanjem shranite podpis.</small>
                    </div>
                </div>
            </div>
        </div>

    );
}

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
}