const INITIAL_STATE = {
    optId: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    if (action.type === 'SET_OTP_ID') {
        console.log(action);
        return Object.assign({}, state, { optId: action.payload.data });
    }

    return {
        ...state
    };
};