import { parseFieldName } from '../common';
import defaultPonudbaReducer from './defaultPonudbaReducer';
import zavarovanaOsebaPremijaReducer from './zavarovanaOsebaPremijaReducer';
import zavarovanaOsebaUpravicenecSmrtReducer from './zavarovanaOsebaUpravicenecSmrtReducer';
import zavarovanaOsebaSoglasjeReducer from './zavarovanaOsebaSoglasjeReducer';
import zavarovanaOsebaIzjavaGdprReducer from './zavarovanaOsebaIzjavaGdprReducer';
import zavarovanaOsebaPristopAbiReducer from './zavarovanaOsebaPristopAbiReducer'
import naslovReducer from './naslovReducer';
import { 
    ZAVAROVANEC_UPDATED, 
    ZAVAROVANEC_PREMIJA_UPDATED, 
    ZAVAROVANEC_NASLOV_UPDATED, 
    ZAVAROVANEC_UPRAVICENEC_SMRT_UPDATED, 
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED,
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED,
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED,
    ZAVAROVANEC_SOGLASJE_UPDATED,
    ZAVAROVANEC_IZJAVA_GDPR_UPDATED,
    ZAVAROVANEC_IZJAVA_GDPR_Q62_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED,
    ZAVAROVANEC_IZJAVA_ZAVAROVANCA_UPDATED
} from '../types/zavarovanaOsebaType';

const INITIAL_STATE = {
    premija: {
        neto: null,
        davek: null,
        bruto: null
    }
};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case ZAVAROVANEC_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        
        case ZAVAROVANEC_PREMIJA_UPDATED:
            let premija = {};
            if (typeof(state.premija) !== 'undefined') {
                premija = state.premija;
            }

            return { ...state, premija: zavarovanaOsebaPremijaReducer(premija, action) }
        
        case ZAVAROVANEC_NASLOV_UPDATED:
            let naslov = {};
            if (typeof(state.naslov) !== 'undefined') {
                naslov = state.naslov;
            }

            return { ...state, naslov: naslovReducer(naslov, action) };
        
        case ZAVAROVANEC_UPRAVICENEC_SMRT_UPDATED:
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED:
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED:
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED:
            let upravicenecSmrt = {};
            if (typeof(state.upravicenecSmrt) !== 'undefined') {
                upravicenecSmrt = state.upravicenecSmrt;
            }

            return { ...state, upravicenecSmrt: zavarovanaOsebaUpravicenecSmrtReducer(upravicenecSmrt, action) };
        
        case ZAVAROVANEC_SOGLASJE_UPDATED:
            let soglasje = {};
            if (typeof(state.soglasje) !== 'undefined') {
                soglasje = state.soglasje;
            }

            return { ...state, soglasje: zavarovanaOsebaSoglasjeReducer(soglasje, action) };
        
        case ZAVAROVANEC_IZJAVA_GDPR_UPDATED:
        case ZAVAROVANEC_IZJAVA_GDPR_Q62_UPDATED:
            let gdpr = {};
            if (typeof (state.izjavaGDPR) !== 'undefined') {
                gdpr = state.izjavaGDPR;
            }

            return { ...state, izjavaGDPR: zavarovanaOsebaIzjavaGdprReducer(gdpr, action) };
        
        case ZAVAROVANEC_PRISTOP_ABI_UPDATED:
        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED:
        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED:
            let abi = {};
            if (typeof (state.pristopAbi) !== 'undefined') {
                abi = state.pristopAbi;
            }

            return { ...state, pristopAbi: zavarovanaOsebaPristopAbiReducer(abi, action) };
        
        case ZAVAROVANEC_IZJAVA_ZAVAROVANCA_UPDATED:
            let izjavaZavarovanca = {};
            if (typeof(state.izjavaZavarovanca) !== 'undefined') {
                izjavaZavarovanca = state.izjavaZavarovanca;
            }

            return {...state, izjavaZavarovanca: defaultPonudbaReducer(izjavaZavarovanca, action)}
            
        default:
            return state;
    }
};