
import { parseFieldName } from '../common';
import { 
    ZAVAROVANEC_ARRAY_UPDATED,
    ZAVAROVANEC_ARRAY_APPENDED,
    ZAVAROVANEC_ARRAY_DELETED
    } from '../types/zavarovanaOsebaType';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    let fieldInfo, newState;
    switch (action.type) {
        case ZAVAROVANEC_ARRAY_APPENDED:
            return [...state, action.payload.value];

        case ZAVAROVANEC_ARRAY_UPDATED:
            const index = state.findIndex(oseba => oseba.id == action.payload.value.id);
            newState = [...state];
            newState[index] = {
                //...newState[index],
                ...action.payload.value
            };
            return newState;
        
        case ZAVAROVANEC_ARRAY_DELETED:
            if (action.payload.value === '__all__') {
                newState = [];
            }
            else {
                newState = state.filter(oseba => oseba.id !== action.payload.value.id);
            }
            
            return newState;
        
        default:
            return state;
    }
};