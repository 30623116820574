import { getPricesForPrvaDiagnoza } from "eureko-lib";
import { getCopiedDataFromZavarovalecToZavarovanec } from "./prefilModel";
import store from "../../redux/store";

export function buildRequestData (
  naslovZaObvsecanjeIsSameAsNaslov,
  zavarovanecIsSameAsZavarovalec,
  sepaPersonIsSameAsZavarovalec,
  model,
) {
    const modelCopy = JSON.parse(JSON.stringify(model));

    const zavarovanaOseba 
      = zavarovanecIsSameAsZavarovalec 
      ? getCopiedDataFromZavarovalecToZavarovanec(
        modelCopy.zavarovalec,
        modelCopy.zavarovanaOseba,  
      )
      : modelCopy.zavarovanaOseba;

    modelCopy.zavarovanaOseba = zavarovanaOseba;

    const naslovZaObvescanje
        = naslovZaObvsecanjeIsSameAsNaslov
        ? modelCopy.zavarovalec.naslovZaObvescanje 
          = JSON.parse(JSON.stringify(modelCopy.zavarovalec.naslov))
        : modelCopy.zavarovalec.naslovZaObvescanje;

    modelCopy.zavarovalec.naslovZaObvescanje = naslovZaObvescanje;
    
    if (zavarovanecIsSameAsZavarovalec) {
      modelCopy.zavarovalec.stevilkaZdravstveneKartice.value
        = zavarovanaOseba.stevilkaZdravstveneKartice.value;
    }
    
    if (sepaPersonIsSameAsZavarovalec) {
      modelCopy.sepa.ime.value =  modelCopy.zavarovalec.ime.value;
      modelCopy.sepa.priimek.value =  modelCopy.zavarovalec.priimek.value;
      modelCopy.sepa.spol.value =  modelCopy.zavarovalec.spol.value;
      modelCopy.sepa.datumRojstva.value =  modelCopy.zavarovalec.datumRojstva.value;
      modelCopy.sepa.davcnaStevilka.value = modelCopy.zavarovalec.davcnaStevilka.value;
      modelCopy.sepa.naslov.value = modelCopy.zavarovalec.naslov.value;
    } 

    const storeState = store.getState();
    const data545 = {
      jobSessionId: storeState.meta.jobSessionId,
      agentParams: storeState.meta.agentParams,
      // jobSessionId: "f021258f-8b64-4110-87c4-fd5314df4136",
      // agentParams: "?t=bc89378a7bf08e7b32bcf58b2c8131077933d9bfb1b5bff50978dfd01dcf9ae1&h=a84cda4e946b2a574c7b8d5c2f9c1c85582c72776662f6dc97a357ff44209502",
    };

    const premija = getPricesForPrvaDiagnoza(
        new Date(modelCopy.zavarovanaOseba.datumRojstva.value),
        modelCopy.izbiraPaketa.paket.value,
    );

    const modelWithJustValues = {};
    Object.entries(modelCopy)
      .forEach(([formSectionKey, formSection]) => {
        modelWithJustValues[formSectionKey] = {};
         Object.entries(formSection)
          .forEach(([formFieldKey, formField]) => {
            modelWithJustValues[formSectionKey][formFieldKey] = formField.value;
          });
      });

    const m = {
        jobId: data545.jobSessionId,
        agentParams: data545.agentParams,
        model: modelWithJustValues,
        premija,
    };

    return m;
}
