import { parseFieldName } from '../common';
import privolitvenaEurekoPrivolitevReducer  from './privolitvenaEurekoPrivolitevReducer';
import { PRIVOLITVENA_EUREKO_UPDATED, PRIVOLITVENA_EUREKO_PRIVOLITEV_UPDATED, PRIVOLITVENA_EUREKO_PRIVOLITEV_Q33_UPDATED } from '../types/privolitvenaEurekoType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case PRIVOLITVENA_EUREKO_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case PRIVOLITVENA_EUREKO_PRIVOLITEV_Q33_UPDATED:
        case PRIVOLITVENA_EUREKO_PRIVOLITEV_UPDATED:
            let privolitev = {};
            if (typeof (state.privolitev) !== 'undefined') {
                privolitev = state.privolitev;
            }

            return { ...state, privolitev: privolitvenaEurekoPrivolitevReducer(privolitev, action) };

        default:
            return state;
    }
};


