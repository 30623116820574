import { parseFieldName } from '../common';
import { ZAVAROVALEC_NASLOV_UPDATED } from '../types/zavarovalecType';
import { ZAVAROVANEC_NASLOV_UPDATED } from '../types/zavarovanaOsebaType';
import { SEPA_PLACNIK_NASLOV_UPDATED } from '../types/sepaType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;

    if (typeof(action.payload) !== 'undefined') {
        fieldInfo = parseFieldName(action.payload.field);    
    }
    
    switch (action.type) {
        case ZAVAROVALEC_NASLOV_UPDATED:
        case ZAVAROVANEC_NASLOV_UPDATED:
        case SEPA_PLACNIK_NASLOV_UPDATED:
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        default:
            return state;
    }
};