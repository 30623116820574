import { parseFieldName } from '../common';
import { 
    ZAVAROVANEC_UPRAVICENEC_SMRT_UPDATED, 
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED, 
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED,
    ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED
    } from '../types/zavarovanaOsebaType';
import zavarovanaOsebaUpravicenecSmrtOstaliReducer from './zavarovanaOsebaUpravicenecSmrtOstaliReducer';

const INITIAL_STATE = { ostali: { seznamUpravicencev: [] }};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case ZAVAROVANEC_UPRAVICENEC_SMRT_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED:
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED:
        case ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED:
            fieldInfo = parseFieldName(action.payload.field);
            let ostali = [];
            if (typeof(newState.ostali) !== 'undefined' && typeof(newState.ostali.seznamUpravicencev) !== 'undefined') {
                ostali = newState.ostali.seznamUpravicencev;
            }

            return {
                ...state,
                ostali: {
                    seznamUpravicencev: zavarovanaOsebaUpravicenecSmrtOstaliReducer(ostali, action)
                }
            };
        default:
            return state;
    }
};