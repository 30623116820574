import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';

export default function SpolField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <div className="input-field col s12">
            Spol
            <p style={{marginBottom: '0px'}}>
                <label 
                    htmlFor={fidPrefix + "spolM"}
                >
                    <input 
                        id={fidPrefix + "spolM"} 
                        onChange={handleChange} 
                        name={fidPrefix + "spol"} 
                        type="radio" required value="M" 
                    />
                    <span>
                        Moški
                    </span>
                </label>
                <label 
                    htmlFor={fidPrefix + "spolZ"} 
                    className="eureko-radio-label"
                >
                    <input 
                        id={fidPrefix + "spolZ"} 
                        onChange={handleChange} 
                        name={fidPrefix + "spol"} 
                        type="radio" 
                        required 
                        value="Ž" 
                    />
                    <span>
                        Ženski
                    </span>
                </label>
            </p>
            
        </div>
    );
}

