import React from 'react';
import { Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import './asset/eurekodefault/sass/materialize.scss';
import './App.css';
import Header from './other/Header';
import HeaderDrBestSi from './other/HeaderDrBestSi';
import FooterDrBestSi from './other/FooterDrBestSi';
import Footer from './other/Footer';
import store from './redux/store';


/*  -------------------------------------- */

export default function App(props) {
    return (
        <Provider store={store}>
            <div className="app">
                {
                    window.location.href.indexOf('/izbira-paketa') === -1 &&  
                    window.location.href.indexOf('/skleni') === -1 ? 
                    <Header /> : <HeaderDrBestSi />
                }
                    <main>
                        <Outlet />
                    </main>
                {
                    window.location.href.indexOf('izbira-paketa') === -1 && 
                    window.location.href.indexOf('skleni') ? 
                    <Footer /> : <FooterDrBestSi />
                }
            </div>
        </Provider>

    );
    
}
