import { React, useEffect, useState } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
// import i18n from 'i18next';


export default function FormSubmitField({
    formStatus,
    enabledButtonText,
    disabledButtonText
}) {
    const {t, i18n} = useTranslation(['translation', 'flashes']);
    const [currentEnabledText, setCurrentEnabledText] = useState(null);
    const [currentDisabledText, setCurrentDisabledText] = useState(null);

    useEffect(() => {
        let text = enabledButtonText;
        if (typeof(text) === 'undefined') {
            text = <Trans>form.button</Trans>;
        }
        else {
            text = enabledButtonText;
        }
        setCurrentEnabledText(text);

        text = disabledButtonText;
        if (typeof(text) === 'undefined') {
            text = <Trans>form.button.submitting</Trans>;
        }
        else {
            text = disabledButtonText;
        }
        setCurrentDisabledText(text);

    }, [
        enabledButtonText, 
        disabledButtonText
    ]);

    return (
        <div 
            className="row"
        >
            <div 
                className="input-field col s12 center-align"
            >
                <button 
                    className={
                        'waves-effect btn-large eureko-form-action-btn eureko-green eureko-blue-text ' +
                            (formStatus === 'SUBMITTING' ? 'disabled' : '')
                        
                        
                    } 
                    type="submit" 
                >
                    {
                        formStatus === 'SUBMITTING' ? currentDisabledText : currentEnabledText
                    }
                </button>
            </div>
        </div>
    );
}

