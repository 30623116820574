import { getSuggestionBasedOnIBAN } from 'eureko-lib';
import { React, useEffect, useState } from 'react';
import IBANInputField from './IBANInputField';
import TextInputField from './TextInputField1';
import { validatorService } from '../service/ValidatorService';
import { validateTransakcijskiRacunInput } from 'eureko-lib/src/validation/validation';

export default function TranskacijskiRacunInputField({
    name,
    onChange,
    isRequired,
    error,
    isReadOnly,
    value,
}) {

    const [ validationError, setValidationError ] = useState(error || {
        hasError: false,
        IBAN: { hasError: false },
        nazivBanke: { hasError: false },
        BIC: { hasError: false },
    });
    const [ IBANInputValue, setIBANInputValue ] = useState(value?.IBAN || '');
    const [ BICInputValue, setBICInputValue ] = useState(value?.BIC || '');
    const [ nazivBankeInputValue, setNazivBankeInputValue ] = useState(value?.nazivBanke || '');
    const [ suggestionObj, setSuggestionObj ] = useState(null);

    useEffect(() => {
        setValidationError(error || {});
    }, [error]);

    useEffect(() => {
        setIBANInputValue(value?.IBAN || '');
        setNazivBankeInputValue(value?.nazivBanke || '');
    }, [ value ]);

    const getSuggestionObj = async (IBAN) => {
        return await getSuggestionBasedOnIBAN(
            validatorService.validateIban.bind(validatorService),
            // below is mock if server is not available
            // (ibanObj) => {
            //     if (ibanObj.iban === 'SI56300000107863454') {
            //         return {
            //             data: {
            //                 bankInfo: {
            //                     bic: 'bic',
            //                     iban: ibanObj.iban,
            //                     nazivBanke: '@Neki.jeba+',
            //                     type: '',
            //                 },
            //             },
            //             success: true,
            //         };
            //     } else {
            //         return {
            //             success: false
            //         };
            //     }    
            // },
            IBAN,
        );
    }

    const onNazivBankeChange = async (val) => {

        setNazivBankeInputValue(val);

        let newSuggestionObj = suggestionObj;
        if (newSuggestionObj === null) {
            newSuggestionObj
                =  await getSuggestionObj(IBANInputValue);
            setSuggestionObj(newSuggestionObj);
        }        

        handleChange({
            IBAN: IBANInputValue,
            nazivBanke: val,
            BIC: BICInputValue,
        }, newSuggestionObj);
    }

    const onBICChange = async (val) => {

        setBICInputValue(val);

        let newSuggestionObj = suggestionObj;
        if (newSuggestionObj === null) {
            newSuggestionObj
                =  await getSuggestionObj(IBANInputValue);
            setSuggestionObj(newSuggestionObj);
        }        

        handleChange({
            IBAN: IBANInputValue,
            nazivBanke: nazivBankeInputValue,
            BIC: val,
        }, newSuggestionObj);
    }

    const onIBANChange = async (IBAN) => {
        setIBANInputValue(IBAN);
        const newSuggestionObj
            =  await getSuggestionObj(IBAN);

        setSuggestionObj(newSuggestionObj);

        if (newSuggestionObj.bankInfo) {
            setNazivBankeInputValue(newSuggestionObj.bankInfo.nazivBanke);
            setBICInputValue(newSuggestionObj.bankInfo.bic);
            handleChange({
                IBAN: IBAN,
                nazivBanke: newSuggestionObj.bankInfo.nazivBanke,
                BIC: newSuggestionObj.bankInfo.bic,
            }, newSuggestionObj);
        } else {
            handleChange({
                IBAN: IBAN,
                nazivBanke: nazivBankeInputValue,
                BIC: BICInputValue,
            }, newSuggestionObj);
        }
    }

    const validate = (
        suggestionObj,
        IBAN,
        nazivBanke,
        BIC,
    ) => {
        validateTransakcijskiRacunInput(
            () => suggestionObj,
            { required: isRequired },
            { IBAN, nazivBanke, BIC },
        ).then(error => {
            setValidationError(error);
        });
    }

    const handleChange = (newValue, suggestionObj) => {
        validate(suggestionObj, newValue.IBAN, newValue.nazivBanke, newValue.BIC);
        if (onChange)
            onChange(newValue);
    };

    return (
        <>
            <div
            >
                <IBANInputField
                    isReadOnly={isReadOnly}
                    error={validationError.IBAN}
                    isRequired={isRequired}
                    name={'iban-' + name}
                    onChange={onIBANChange}
                    questionText='Številka računa (IBAN)'
                    value={IBANInputValue}
                />
            </div>
            <div
            >
                <TextInputField
                    isReadOnly={isReadOnly}
                    error={validationError.nazivBanke}
                    isRequired={isRequired}
                    name={'naziv-banke-' + name}
                    onChange={onNazivBankeChange}
                    questionText='Ime banke'
                    value={nazivBankeInputValue}
                />
            </div>
            <div
            >
                <TextInputField
                    isReadOnly={isReadOnly}
                    error={validationError.BIC}
                    isRequired={isRequired}
                    name={'BIC-' + name}
                    onChange={onBICChange}
                    questionText='Identifikacijska oznaka banke (BIC)'
                    value={BICInputValue}
                />
            </div>
        </>
    );
}
