import store from "../../redux/store";

export function buildRequestData (model, action, requestId) {

    const storeData = store.getState();
    const agentData = {
        agentParams: storeData.meta.agentParams,
    };

    const modelWithJustValues = {};
    Object.entries(model)
      .forEach(([formSectionKey, formSection]) => {
        modelWithJustValues[formSectionKey] = {};
         Object.entries(formSection)
          .forEach(([formFieldKey, formField]) => {
            modelWithJustValues[formSectionKey][formFieldKey] = formField.value;
          });
      });

    if (requestId === 'preview-without-signatures') {
        modelWithJustValues.clen545.podpisSvetovalec = undefined;
        modelWithJustValues.clen545.podpisZavarovalec = undefined;
    }

    const body = {
        agentParams: agentData.agentParams,
        model: modelWithJustValues,
    };

    return body;
};