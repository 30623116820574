import { parseFieldName } from '../common';
import { ZAVAROVALEC_OSEBNI_DOKUMENT_UPDATED } from '../types/zavarovalecType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;

    if (typeof(action.payload) !== 'undefined') {
        fieldInfo = parseFieldName(action.payload.field);    
    }
    
    switch (action.type) {
        case ZAVAROVALEC_OSEBNI_DOKUMENT_UPDATED:
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        default:
            return state;
    }
};