import {React, useState, useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';


export default function PotrditvenaKoda3Field({
    isRequired,
    error,
    onChange,
    onSendOtpClick,
    value,
    name,
}) {
    const [validationError, setValidationError] = useState(error);
    const [inputValue, setInputValue] = useState(value || '');
    const {t} = useTranslation(['translation', 'validation', 'flashes']);

    // Set appropirate validation error.
    let validationErrMessage = t('validation:field.validate.verificationCode');
    if (validationError?.required) {
        validationErrMessage = t('validation:field.validate.required');
    }

    const buttonText = useRef(<Trans>form.button.verificationCode</Trans>);

    const handleChange = async (event) => {
        const val = event.target.value; 
        setInputValue(val);
        if (onChange)
            onChange(val);
    };

    const handleClick = async (event) => {
        // event.preventDefault();
        onSendOtpClick(event);
    };

    useEffect(() => {
        setValidationError(error);
    }, [error]);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <div 
            className="row"
        >
            <div 
                className="col s12"
            >
                <div 
                    className="row"
                >
                    <div 
                        className="input-field col s12 center-align"
                    >
                        <input 
                            id={name} 
                            type="text" 
                            name={name} 
                            pattern="[0-9]{6}" 
                            required={isRequired} 
                            className={validationError?.hasError ? 'invalid' : ''} 
                            onChange={handleChange}
                            value={inputValue}
                        />
                        <label 
                            htmlFor={name}
                            className={inputValue ? 'active' : ''}
                        >
                            <Trans>field.label.verificationCode</Trans>
                        </label>
                        <span 
                            className="helper-text"
                            hidden={!validationError?.hasError} 
                            data-error={validationErrMessage}
                        >
                        </span>
                    </div>
                </div>
                <div 
                    className="row"
                >
                    <div 
                        className="input-field col s12 center-align"
                    >
                        <button 
                            onClick={handleClick} 
                            type="button" 
                            className={
                                "waves-effect waves-light btn-small eureko-green eureko-blue-text eureko-form-action-btn"
                            }
                        >
                            {buttonText.current}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
