export const datumEuToTimestamp = (dateString) => {
    let parts = dateString.split('.'), mIndex = parseInt(parts[1]) - 1;
    let date = new Date(parseInt(parts[2]), mIndex, parseInt(parts[0]));
    return date.getTime();
}

/**
 * @deprecated Use function from eureko lib.
 */
export const appLogDebug = (...args) => {
    if (process.env.NODE_ENV !== 'development') {
        return;
    }

    console.debug(...args);
}

export const validateFormSignatures = (allSteps) => {

    appLogDebug('Validate Eureko Signature');
    let elements = [], tab = null, el = null, 
        tabValid = false,
        failedTabs = [],
        removeIndex = -1;
    let i = 0, isValid = true, gIsValid = true, 
        clInvalidClass = 'eureko-signature-1-invalid', 
        contains = null,
        stepIndex = 0, 
        cssSelector = '', 
        cssTabSelector = '#form-steps li',
        tabs = document.querySelectorAll(cssTabSelector)
        el = null;
    let tabValidClasses = '', tabInvalidClasses = 'red';

    while (stepIndex < allSteps.length) {
        cssSelector = '#' + allSteps[stepIndex].tab + ' input.eureko-signature';
        // tabs = document.querySelectorAll(cssTabSelector);
        elements = document.querySelectorAll(cssSelector);
        tabValid = true;
        i = 0;

        while (i < elements.length) {
        
            el = elements[i].closest('div.eureko-signature');
            contains = el.classList.contains(clInvalidClass);
            
            isValid = elements[i].value === '1';
            tabValid = (tabValid === false) ? tabValid : isValid;
            gIsValid = (gIsValid === false) ? gIsValid : isValid;

            console.debug('signature table');
            console.table(isValid);

            if (isValid && contains) {
                el.classList.remove(clInvalidClass);
            }
            else if (!isValid && !contains) {
                el.classList.add(clInvalidClass);
            }
            
            i++;
        }

        // Mark tab if validation error found.
        if (!tabValid) {
            /*if (!tabs[stepIndex].classList.contains(tabInvalidClasses)) {
                tabs[stepIndex].classList.add(tabInvalidClasses);
            }*/
            failedTabs.push(allSteps[stepIndex].tab);
        }
        else {
            // valid tab
            // tabs[stepIndex].classList.remove(tabInvalidClasses);
            removeIndex = failedTabs.indexOf(allSteps[stepIndex].tab);
            if (removeIndex !== -1) {
                failedTabs.splice(removeIndex, 1);
            }
        }

        stepIndex++;
    }

    return failedTabs;
};

export const validateFormSignature = (podpisValue, isRequired=true) => {
    let obj = {
        hasError: false
    };


    // Not all signatures are required all the time.
    if (!isRequired) {
        return obj;
    }

    if (podpisValue === null || podpisValue === undefined) {
        obj.hasError = true;
        obj['required'] = true;
    }

    return obj;
};

export const validateFormInputs = (allSteps) => {

    appLogDebug('Validate Form Input');
    let customValidators = ['davcnaStevilka', 'IBAN', 'nazivBanke', 'potrditvenaKoda'];

    let elements = null, cssSelector, i = 0, j = 0;
    let clNameAdd, clNameRemove, formValid = true;

    let failedTabs = [], removeIndex = -1;
    let tabValidClasses = '', tabInvalidClasses = 'red';
    let tabs = document.querySelectorAll('#form-steps li'), tabValid;
    appLogDebug("tabs length ", tabs.length, " allSteps ", allSteps.length);

    while (j < allSteps.length) {
        cssSelector = '#' + allSteps[j].tab + ' input';
        elements = document.querySelectorAll(cssSelector);
        tabValid = true;
        i = 0;

        appLogDebug(cssSelector, elements.length);
        while (i < elements.length) {

            clNameAdd = 'valid';
            clNameRemove = 'invalid';

            // We do not know how to trigger validators on fields with custom validation logic.
            // so for now we use this.
            if (customValidators.some((v) => elements[i].name.indexOf(v) !== -1) && elements[i].classList.contains('invalid')) {

                // Field is already marked as invalid.
                tabValid = false;
                formValid = false;
                i++;
                continue;
            }
            else if (!elements[i].checkValidity()) {
                appLogDebug("Failed for element: ", elements[i].name);
                clNameAdd = 'invalid';
                clNameRemove = 'valid';
                tabValid = false;
                formValid = false;
            }
            else {
                appLogDebug("Everything  OK for ", elements[i].name);
            }

            if (!elements[i].classList.contains(clNameAdd)) {
                elements[i].classList.add(clNameAdd);
            }

            if (elements[i].classList.contains(clNameRemove)) {
                elements[i].classList.remove(clNameRemove);
            }

            switch (elements[i].type) {
                case 'radio':
                case 'checkbox':
                    let parent = elements[i].parentElement, cl = 'eureko-input-boolean-required';
                    if (!elements[i].checkValidity()) {
                        parent.classList.add(cl);
                    }
                    else {
                        parent.classList.remove(cl);
                    }
                    break;

                default:
                    break;
            }

            i++;
        }

        // Mark tab if validation error found.
        if (!tabValid) {

            failedTabs.push(allSteps[j].tab);

            /*if (!tabs[j].classList.contains(tabInvalidClasses)) {
                tabs[j].classList.add(tabInvalidClasses);
            }*/
        }
        else {
            // valid tab

            removeIndex = failedTabs.indexOf(allSteps[j].tab);
            if (removeIndex !== -1) {
                failedTabs.splice(removeIndex, 1);
            }
            
            // tabs[j].classList.remove(tabInvalidClasses);
        }

        j++;
    }

    return failedTabs;
};

export const markInvalidTabs = (allSteps, tabs1, tabs2) => {
    let allFailedTabs = tabs1.concat(tabs2);
    allFailedTabs = allFailedTabs.filter((val, index, array) => array.indexOf(val) === index);
    appLogDebug("all failed tabs ", allFailedTabs);
    let i = 0;
    while (i < allSteps.length) {
        allSteps[i].error = false;
        i++;
    }

    let stepIndex = -1, stepProps = null, stepPropsList;
    i = 0;
    while (i < allFailedTabs.length) {
        stepPropsList = allSteps.filter((val, index, array) => val.tab === allFailedTabs[i]);
        if (stepPropsList.length > 0) {
            stepProps = stepPropsList[0];

            appLogDebug("stepProps ", stepProps);
            stepIndex = stepProps.step - 1;       
            allSteps[stepIndex].error = true;
        }

        
        i++;
    }

    return allSteps;
};

export const markInput = (clientError, action) => {

    let name = clientError.fidPrefix + clientError.fidName;
    appLogDebug(name);
    let elements = document.getElementsByName(name);
    appLogDebug("EL ", elements);

    let j = 0;
    while (j < elements.length) {

        /*if (action === 'APPLY_ERRORS') {
            elements[j].setCustomValidity("Wrong value");
        }
        else {
            // Clear errors
            elements[j].setCustomValidity("");
        }*/

        switch (elements[j].type) {
            case 'radio':
            case 'checkbox':
                let parent = elements[j].parentElement, cl = 'eureko-input-boolean-required';
                if (action === 'APPLY_ERRORS' && !parent.classList.contains(cl)) {
                    parent.classList.add(cl);
                }
                else if (action === 'CLEAR_ERRORS' && parent.classList.contains(cl)) {
                    parent.classList.remove(cl)
                }
                break;

            default:
                break;
        }


        j++;
    }
};

export const mapErrorsToForm = (errors) => {

    let clientErrors = [], tabs = [];
    let i = 0, erObj;
    while (i < errors.length) {
        erObj = {
            tab: null,
            fidPrefix: null,
            fidName: null,
            path: errors[i].path,
            value: errors[i].value,
        };
        switch (errors[i].path) {
            case "ponudba.data.produkt.zavarovanaOseba.pristopAbi.izjavaGDPR.q31":
                erObj = {
                    ...erObj,
                    tab: "abi",
                    fidPrefix: "zavarovanec_pristopabi_izjavagdpr_",
                    fidName: "q31"
                };
                break;
            
            case "ponudba.data.produkt.zavarovanaOseba.pristopAbi.izjavaGDPR.q311":
                erObj = {
                    ...erObj, 
                    tab: "abi",
                    fidPrefix: "zavarovanec_pristopabi_izjavagdpr_",
                    fidName: "q311"
                };
                break;
        }

        tabs.push(erObj.tab);
        clientErrors.push(erObj);
        i++;
    }

    return {
        clientErrors: clientErrors,
        tabs: tabs
    };
};

/**
 * 
 * @param {Date} inD 
 * @param {object} conf 
 * @returns 
 */
export const validateDatumConstraints = (inD, conf) => {
    let c = new Date(), diff = null;
    
    diff = c.getFullYear() - inD.getFullYear();

    if (conf.constraintType === 'PRAVA_STAROST') {
        // Se ni star toliko.
        let temp = new Date(c.getFullYear(), inD.getMonth(), inD.getDate());
        if (temp < c) {
            diff--;
        }
    }
    
    if (conf.deltaMin !== null && diff < conf.deltaMin) {
        return false;
    }

    // Is older then 30 days? We need it for bdca.
    let diffDays = Math.floor( (c.getTime() - inD.getTime()) / (24 * 3600 * 1000) );
    if (conf.deltaMinDays !== null && diffDays < conf.deltaMinDays) {
        return false;
    }

    if (conf.deltaMax !== null && diff > conf.deltaMax) {
        return false;
    }
    
    return true;
};

