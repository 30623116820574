import { React, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import M from '@materializecss/materialize';
import ImeField from '../field/ImeField';
import EmailField from '../field/EmailField';
import MobitelField from '../field/MobitelField';
import PotrditvenaKodaField from '../field/PotrditvenaKodaField';
import FormSubmitField from '../field/FormSubmitField';
import AgreeField from '../field/AgreeField';
import HiddenField from '../field/HiddenField';
import ItemClen545Service from '../service/ItemClen545Service';


export default function VstopSSvetovanjemForm(props) {
    const [status, setStatus] = useState('');
    const [agentParams, setAgentParams] = useState(props.agentParams);
    const [otpId, setOtpId] = useState(null);
    const [formData, setFormData] = useState({
        type: 'VstopSSvetovanjemForm',

        // We need to initialize this because we are passing it as props.
        email: '',
        mobitel: ''
    });

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const { t, i18n } = useTranslation(['translation', 'validation', 'flashes']);

    function updateFormData(field, value) {
        setFormData(currentValues => {
            let values = {...currentValues};
            values[field] = value;
            return values;
        });
    }

    function handleFieldChange(event) {
        updateFormData(event.target.name, event.target.value);
        console.log(formData);
    }

    function handleOtpIdChange(value) {
        setOtpId(value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setStatus('SUBMITTING');

        console.debug("handleSubmit Current State:");
        console.debug(formData);

        let requestBody = {
            agent: {
                agentParams: agentParams
            },
            otp: {
                id: otpId,
                potrditvenaKoda: formData.potrditvenaKoda,
                siganture: ''
            },
            legal: {
                zavarovalec: {
                    ime: formData.ime,
                    priimek: formData.priimek,
                    email: formData.email,
                    telefon: formData.mobitel,
                    soglasjePrejemDokumentacije: formData.soglasjePrejemDokumentacije,
                },
            },
            form: {
                type: formData.type,
                soglasjeUporabaEmailMobitel: formData.soglasjeUporabaEmailMobitel
            }
        };
        let itemClen545Service = new ItemClen545Service();
        itemClen545Service.sendItemClen545(requestBody)
            .then((result) => {
                if (result.success) {
                    M.toast({ html: "flashes:submission.successful" });
                }
                else {
                    M.toast({ html: result.message });    
                    M.toast({ html: "flashes:submission.error" });    
                }
            })
            .then((result) => {
                setStatus('SUBMITTED');
            })
            .catch(error => {
                M.toast({ html: "flashes:submission.error" });
            })
    }

    useEffect(() => {
        if (status === 'SUBMITTED') {
            navigate('/zahtevek-za-sklepanje');
        }
    }, [status, navigate]);    
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <HiddenField name="agentParams" onChange={handleFieldChange} />
                <ImeField onImeChange={handleFieldChange} onPriimekChange={handleFieldChange} />
                <div className="row">
                    <EmailField onChange={handleFieldChange} />
                    <MobitelField onChange={handleFieldChange} />
                </div>
                <PotrditvenaKodaField onChange={handleFieldChange} onOtpIdChange={handleOtpIdChange} formStatus={status} email={formData.email} mobitel={formData.mobitel} otp={{id:otpId}} />
                <div className="row">
                    <div className="input-field col s12">
                        <AgreeField name="soglasjeUporabaEmailMobitel" onChange={handleFieldChange}
                            text="Dovoljujem uporabo svojega elektronskega naslova in telefonske številke za namene komunikacije v povezavi z zdravstvenimi, asistenčnimi in zavarovalniškimi storitvami"
                        />
                        <AgreeField name="soglasjePrejemDokumentacije" onChange={handleFieldChange}
                            isRequired={true}
                            text="Soglašam s prejemom sklepalne dokumentacije v elektronski obliki"
                        />
                    </div>
                </div>
                <FormSubmitField formStatus={status} />
            </form>
        </div>
    );

}


       