import { parseFieldName } from '../common';
import naslovReducer from './naslovReducer';
import { SEPA_PLACNIK_UPDATED, SEPA_PLACNIK_NASLOV_UPDATED } from '../types/sepaType';


const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;

    if (typeof(action.payload) !== 'undefined') {
        fieldInfo = parseFieldName(action.payload.field);    
    }
    
    switch (action.type) {
        case SEPA_PLACNIK_UPDATED:
            newState[fieldInfo.name] = action.payload.value;
            return newState;
        
        case SEPA_PLACNIK_NASLOV_UPDATED:
            let naslov = {};
            if (typeof (state.naslov) !== 'undefined') {
                naslov = state.naslov;
            }

            return { ...state, naslov: naslovReducer(naslov, action) };

        default:
            return state;
    }
};