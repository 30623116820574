export const ZAVAROVANEC_UPDATED = 'ZAVAROVANEC_UPDATED';
export const ZAVAROVANEC_PREMIJA_UPDATED = 'ZAVAROVANEC_PREMIJA_UPDATED';
export const ZAVAROVANEC_NASLOV_UPDATED = 'ZAVAROVANEC_NASLOV_UPDATED';
export const ZAVAROVANEC_UPRAVICENEC_SMRT_UPDATED = 'ZAVAROVANEC_UPRAVICENECSMRT_UPDATED';
export const ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_APPENDED = 'ZAVAROVANEC_UPRAVICENECSMRT_OSTALI_APPENDED';
export const ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_UPDATED = 'ZAVAROVANEC_UPRAVICENECSMRT_OSTALI_UPDATED';
export const ZAVAROVANEC_UPRAVICENEC_SMRT_OSTALI_DELETED = 'ZAVAROVANEC_UPRAVICENECSMRT_OSTALI_DELETED';
export const ZAVAROVANEC_SOGLASJE_UPDATED = 'ZAVAROVANEC_SOGLASJE_UPDATED';
export const ZAVAROVANEC_IZJAVA_GDPR_UPDATED = 'ZAVAROVANEC_IZJAVAGDPR_UPDATED';
export const ZAVAROVANEC_IZJAVA_GDPR_Q62_UPDATED = 'ZAVAROVANEC_IZJAVAGDPR_Q62_UPDATED';
export const ZAVAROVANEC_PRISTOP_ABI_UPDATED = 'ZAVAROVANEC_PRISTOPABI_UPDATED';
export const ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED = 'ZAVAROVANEC_PRISTOPABI_IZJAVAGDPR_UPDATED';
export const ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED = 'ZAVAROVANEC_PRISTOPABI_IZJAVAGDPR_Q32_UPDATED';
export const ZAVAROVANEC_IZJAVA_ZAVAROVANCA_UPDATED = 'ZAVAROVANEC_IZJAVAZAVAROVANCA_UPDATED';
export const ZAVAROVANEC_ARRAY_UPDATED = 'ZAVAROVANECARRAY_UPDATED';
export const ZAVAROVANEC_ARRAY_APPENDED = 'ZAVAROVANECARRAY_APPENDED';
export const ZAVAROVANEC_ARRAY_DELETED = 'ZAVAROVANECARRAY_DELETED';

