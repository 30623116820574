import { React, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function DediscinaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [zavarovalecClass, setZavarovalecClass] = useState('');

    const zavarovanaOsebaDavcnaStevilka = useSelector((state) => {
        if (typeof (state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba) === 'undefined') {
            return '';
        }
        else if (typeof (state.produkt.zavarovanaOseba.davcnaStevilka) === 'undefined') {
            return '';
        }

        return state.produkt.zavarovanaOseba.davcnaStevilka;
    });

    const zavarovalecDavcnaStevilka = useSelector((state) => {
        if (typeof (state.zavarovalec) === 'undefined') {
            return '';
        }
        else if (typeof (state.zavarovalec.davcnaStevilka) === 'undefined') {
            return '';
        }

        return state.zavarovalec.davcnaStevilka;
    });

    useEffect(() => {
        if (zavarovanaOsebaDavcnaStevilka === zavarovalecDavcnaStevilka) {
            setZavarovalecClass('disabled');

            let dummyEvent = {
                target: {
                    name: (fidPrefix + "dediscinaType"),
                    value: 'DEDNO_PRAVO',
                }
            };
            props.onChange(dummyEvent);
        }
        else {
            setZavarovalecClass('');
        }
    }, [zavarovanaOsebaDavcnaStevilka, zavarovalecDavcnaStevilka]);

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <>
            <div className="input-field col s12">
                Upravičenec/Upravičenci v primeru smrti
                <p>
                    <label>
                        <input name={fidPrefix + "dediscinaType"} type="radio" value="ZAVAROVALEC" disabled={zavarovalecClass === 'disabled'} required={zavarovalecClass !== 'disabled'} onChange={handleChange} />
                        <span>Zavarovalec</span>
                    </label> 
                        
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + "dediscinaType"} type="radio" value="DEDNO_PRAVO" required={true} onChange={handleChange} />
                        <span>Dedno pravo</span>
                    </label>
                    <label className="eureko-radio-label">
                        <input name={fidPrefix + "dediscinaType"} type="radio" value="OSTALI" required={true} onChange={handleChange} />
                        <span>Druge osebe</span>
                    </label>
                </p>
                
                
                <span className="helper-text" data-error={i18n.t('validation:field.validate.dediscinaRequired')} data-success=""></span>
            </div>
        </>
    );
}

