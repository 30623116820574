import { parseFieldName } from '../common';
import {
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED,
    ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED 
} from '../types/zavarovanaOsebaType';
import privolitvenaEurekoPrivolitevQ33Reducer from './privolitvenaEurekoPrivolitevQ33Reducer';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {
        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED:
            let q32 = {};
            if (typeof (state.q32) !== 'undefined') {
                q32 = state.q32;
            }

            // Note that we can use privolitvenaEurekoPrivolitevQ33Reducer just because the object is the same.
            return { ...state, q32: privolitvenaEurekoPrivolitevQ33Reducer(q32, action) };
            
        default:
            return state;
    }
};