import { React, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { getActionType, parseFieldName, inputValueToUpperCase} from '../redux/common';
import { appLogDebug } from '../helper';
import DatumRojstvaField from './DatumRojstvaField';
import ImeField from './ImeField';
import { 
    ZAVAROVANEC_ARRAY_UPDATED,
    ZAVAROVANEC_ARRAY_APPENDED,
    ZAVAROVANEC_ARRAY_DELETED
    } from '../redux/types/zavarovanaOsebaType';
import DavcnaStevilkaField from './DavcnaStevilkaField';
import RazmerjeField from './RazmerjeField';
import SpolSelectField from './SpolSelectField';
import M from '@materializecss/materialize';


export default function ZavarovanciArrayField(props) {

    // !!! Field name: '' !!!
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [hideClass, setHideClass] = useState('');
    const [upravicenci, setUpravicenci] = useState([]);
    const [upCounter, setUpCounter] = useState(1);
    const dispatch = useDispatch();
    const [zavarovalecIndex, setZavarovalecIndex] = useState(-1);
    const lastRecordImeInput = useRef(null);

    const compFieldName = fidPrefix.slice(0, -1);
    const upravicenecNew = {
        ime: '',
        priimek: '',
        davcnaStevilka: '',
        datumRojstva: '',
        spol: null,
        razmerje: '',
        id: null
    };

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const getAndIncrement = () => {
        let nextId = upCounter;
        setUpCounter( (nextId + 1) );
        return nextId;
    };

    const handleChange = (event) => {
        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name), objectId = parseInt(fieldInfo.prefix.slice(1));

        //type = getActionType(fieldInfo.prefix, 'UPDATED');
        let normalizedValue = event.target.value, upravicenec, index;
        normalizedValue = inputValueToUpperCase(normalizedValue);
        
        // Update object value.
        index = upravicenci.map((element) => element.id).indexOf(objectId);
        upravicenec = upravicenci[index];

        upravicenec[fieldInfo.name] = normalizedValue;
        let upravicenciCopy = upravicenci;
        upravicenciCopy[index] = upravicenec;
        setUpravicenci([...upravicenciCopy]);
        appLogDebug("updating internal state... ", upravicenci[index]);

        dispatch({
            type: ZAVAROVANEC_ARRAY_UPDATED,
            payload: {
                field: compFieldName,
                value: upravicenci[index]
            }
        });
        
    };

    const handleAddClick = (event) => {

        let i = 0, u = null;
        while (i < upravicenci.length) {
            u = upravicenci[i];
            if (typeof(u.ime) !== 'string' || typeof(u.priimek) !== 'string' || typeof(u.datumRojstva) !== 'string') {
                event.preventDefault();
                return;
            }

            if (u.ime.length === 0 || u.priimek.length === 0 || u.datumRojstva.length === 0) {
                event.preventDefault();
                return;
            }

            i++;
        }

        if (i >= props.maxZavarovanciCount) {
            // We cannot add more then 6 people.
            event.preventDefault();
            return;
        }

        // We actually need to add another row.
        let obj = { ...upravicenecNew };
        obj.id = getAndIncrement();
        let upravicenciCopy = upravicenci;
        upravicenciCopy.push( {...obj} );
        setUpravicenci( [...upravicenciCopy] );

        dispatch({
            type: ZAVAROVANEC_ARRAY_APPENDED,
            payload: {
                field: compFieldName,
                value: obj
            }
        });
    };

    const handleAddZavarovalecClick = (event) => {
        
        handleAddClick(event);
        setZavarovalecIndex(upravicenci.length - 1);
        
    };

    const handleRemoveClick = (event) => {
        if (upravicenci.length === 1) {
            event.preventDefault();
            return;
        }

        // Internal object id.
        //console.debug(event.target);
        let objectId = parseInt(event.target.getAttribute('data-record-index')), index;
        let upravicenciCopy = [...upravicenci];

        index = upravicenciCopy.findIndex(up => up.id === objectId);
        // If we removed zavarovalca reset the index to get the button back.
        if (index === zavarovalecIndex) {
            setZavarovalecIndex(-1);
        }
        
        /*console.debug('my index and upravicenci', index);
        console.debug('object id ', objectId);
        console.debug(upravicenciCopy);*/
        dispatch({
            type: ZAVAROVANEC_ARRAY_DELETED,
            payload: {
                field: compFieldName,
                value: upravicenciCopy[index]
            }
        });

        upravicenciCopy.splice(index, 1);
        setUpravicenci([...upravicenciCopy]);
        event.stopPropagation();
    };

    useEffect(() => {
        M.AutoInit();
    }, [upravicenci.length]);

    useEffect(() => {
        appLogDebug('zavarovalecIndex... ' + zavarovalecIndex);
         // Just added zavarovalca to zavarovanci.
         let lastIndex = upravicenci.length - 1;
         if (zavarovalecIndex === lastIndex && zavarovalecIndex !== -1) {
             let inputEvent = new Event('input', {bubbles: false}), blurEvent = new Event('blur', {bubbles: false}), 
                 focusEvent = new Event('focus', {bubbles: false}), changeEvent = new Event('change', {bubbles: true}), dummyEvent;
             inputEvent.simulated = true;
             blurEvent.simulated = true;
             focusEvent.simulated = true;
             let selector = '', el = null, zavarovalecDomPos = upravicenci[zavarovalecIndex].id;
 
             let ime = document.getElementById('zavarovalec_ime').value;
             selector = `u${zavarovalecDomPos}_ime`;
             el = document.getElementById(selector);
             el.value = ime;
             // el.dispatchEvent(changeEvent);
             el.dispatchEvent(inputEvent);
             el.dispatchEvent(blurEvent);
             el.dispatchEvent(focusEvent);
             dummyEvent = {
                 target: {
                     name: selector,
                     value: ime
                 }
             };
             handleChange(dummyEvent);
 
             let priimek = document.getElementById('zavarovalec_priimek').value;
             selector = `u${zavarovalecDomPos}_priimek`;
             el = document.getElementById(selector);
             el.value = priimek;
             // el.dispatchEvent(changeEvent);
             el.dispatchEvent(inputEvent);
             el.dispatchEvent(blurEvent);
             el.dispatchEvent(focusEvent);
             dummyEvent = {
                 target: {
                     name: selector,
                     value: priimek
                 }
             };
             handleChange(dummyEvent);
 
             let davcnaStevila = document.getElementById('zavarovalec_davcnaStevilka').value;
             selector = `u${zavarovalecDomPos}_davcnaStevilka`;
             el = document.getElementById(selector);
             el.value = davcnaStevila;
             el.dispatchEvent(inputEvent);
             el.dispatchEvent(blurEvent);
             el.dispatchEvent(focusEvent);
             dummyEvent = {
                 target: {
                     name: selector,
                     value: davcnaStevila
                 }
             };
             handleChange(dummyEvent);
             
             let datumRojstva = document.getElementById('zavarovalec_datumRojstva').value;
             selector = `u${zavarovalecDomPos}_datumRojstva`;
             el = document.getElementById(selector);
             el.value = datumRojstva;
             el.dispatchEvent(inputEvent);
             el.dispatchEvent(blurEvent);
             el.dispatchEvent(focusEvent);
             dummyEvent = {
                 target: {
                     name: selector,
                     value: datumRojstva
                 }
             };
             handleChange(dummyEvent);
 
             let razmerje = 'A';
             selector = `u${zavarovalecDomPos}_razmerje`;
             el = document.getElementById(selector);
             el.value = razmerje;
             // While populating select field we only need to trigger change to make fields work.
             el.dispatchEvent(changeEvent);
 
             let spol = null;
             el = document.querySelector('input[name="zavarovalec_spol"]:checked');
             if (el !== null) {
                 spol = el.value;
             }
 
             if (typeof(spol) === 'string' && spol.length) {
                 selector = `u${zavarovalecDomPos}_spol`;
                 el = document.getElementById(selector);
                 el.value = spol;
                 // While populating select field we only need to trigger change to make fields work.
                 el.dispatchEvent(changeEvent);
             }
             
         }
    }, [zavarovalecIndex])

    return (
        <>
            {upravicenci.map((element, index, array) => {
                    let key = element.id.toString(), localPrefix = "u" + key + "_";       
                        
                    return (<div className="row" key={key}>

                            <div className="col s12 m12 center-align">
                                <b>Zavarovanec {index + 1}</b><br />
                                <button data-record-index={key} type="button" className="btn btn-small darken-2 red" onClick={handleRemoveClick}>
                                    {/** We put remove handler to <i> tag because user can click on ths tag and we have to ensure that data property is accessible. */}
                                    <i data-record-index={key} className="material-icons" onClick={handleRemoveClick}>delete</i>
                                </button>
                            </div>                            
                            <div className="col s12 m4">
                                <ImeField prefix={localPrefix} onImeChange={handleChange} onPriimekChange={handleChange} />
                            </div>
                            <div className="col s12 m4">
                                <DavcnaStevilkaField prefix={localPrefix} onChange={handleChange} />
                            </div>
                            <div className="col s12 m4">
                                <DatumRojstvaField prefix={localPrefix} onChange={handleChange} />
                            </div>
                            <div className="col s12 m6">
                                <RazmerjeField prefix={localPrefix} onChange={handleChange} />
                            </div>                                              
                            <div className="col s12 m6">
                                <SpolSelectField prefix={localPrefix} onChange={handleChange} /> 
                            </div>
                            <div className="col s12">
                                <hr />
                            </div> 

                        </div>);
            })}

            <div className="row">
                <div className="col s12 center-align">
                    {
                        zavarovalecIndex === -1 && 
                        <button 
                            type="button" 
                            className="btn-large waves-effect waves-light eureko-blue lighten-1-text" 
                            onClick={handleAddZavarovalecClick}>
                            Zavarujem le sebe
                        </button>
                    }

                    <button 
                        type="button" 
                        className="btn-large waves-effect waves-light eureko-green eureko-blue-text" 
                        onClick={handleAddClick}
                    >
                        Dodaj zavarovanca
                    </button>
                </div>
            </div>
                
            
        </>
    );
}

