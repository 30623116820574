import {React, useState, useEffect, useRef} from 'react';
import M from '@materializecss/materialize';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import IdentityService from '../service/IdentityService';


export default function PotrditvenaKodaField(props) {
    const isRequired = (typeof (props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [ processing, setProcessing ] = useState(false);
    const [ showHelpMessage, setShowHelpMessage ] = useState(false);
    const [ otpStatusClass, setOtpStatusClass ] = useState('');

    // Default handlder
    const onOtpIdChangeDefault = (value) => {
        const dummyEvent = {
            target: {
                name: (fidPrefix + 'id'),
                value: value
            }
        };

        props.onChange(dummyEvent);
    };
    
    const {t, i18n} = useTranslation(['translation', 'validation', 'flashes']);

    const buttonText = useRef(<Trans>form.button.verificationCode</Trans>);
    const buttonClasses = useRef("waves-effect waves-light btn-small eureko-green");

    const handleChange = async (event) => {
        props.onChange(event);

        let potrditvenaKodaVal = event.target.value;
        if (props.otp === null || potrditvenaKodaVal.match(/[0,9]{6}/) === null) {
            console.debug("Code does not match");
            return;
        }

        setProcessing(true);
        let idService = new IdentityService();
        idService.checkVerificationCode({
            email: props.email,
            mobitel: props.mobitel,
            id: props.otp.id,
            potrditvenaKoda: potrditvenaKodaVal
        }).then((responseResult) => {
            let pc = (responseResult.success) ? 'valid' : 'invalid';
            setOtpStatusClass(pc);

        }).then(() => {
            setProcessing(false);
        })
            .catch((error) => {
                setOtpStatusClass('');
            });
    };

    const handleClick = async (event) => {
        event.preventDefault();
        let email = null, mobitel = null;
        if (props.email.length) {
            email = props.email;
        }

        if (props.mobitel.length) {
            mobitel = props.mobitel
        }

        if (email !== null && mobitel !== null) {
            setProcessing(true);
            let idService = new IdentityService();
            idService.sendVerificationCode({
                email: email,
                mobitel: mobitel

            }).then((responseResult) => {
                console.log(responseResult);
                if (responseResult.success) {
                    setShowHelpMessage(responseResult.success);
                    setProcessing(false);

                    if (typeof(props.onOtpIdChange) === 'undefined') {
                        onOtpIdChangeDefault(responseResult.data.otp.id);
                    }
                    else {
                        props.onOtpIdChange(responseResult.data.otp.id);
                    }

                    if (showHelpMessage) {
                        const message = t('translation:field.verificationCode.successMessage');
                        M.toast({ html: message });
                    }
                }
                else {
                    const message = t('translation:field.verificationCode.errorMessage');
                    M.toast({ html: message });
                }
                
                
                
            }).catch((error) => {
                console.debug("error ", error);

                setShowHelpMessage(false);
                setProcessing(false);
                const message = i18n.t('translation:field.verificationCode.errorMessage');
                M.toast({ html: message });
            });
        }
    };

    useEffect(() => {
        buttonText.current = <Trans>form.button.verificationCode</Trans>;
        buttonClasses.current = "waves-effect btn-small eureko-green eureko-blue-text";
        if (processing === true) {
            buttonText.current = <Trans>form.button.submitting</Trans>;
            buttonClasses.current += "disabled";
        }
    }, [processing]);


    return (
        <div className="row">
            <div className="col s12">
                <div className="row">
                    <div className="input-field col s12 center-align">
                        <input id={fidPrefix + "potrditvenaKoda"} 
                            type="text" name={fidPrefix + "potrditvenaKoda"} 
                            pattern="[0-9]{6}" 
                            required={isRequired} 
                            className="validate {otpStatusClass}" 
                            onChange={handleChange} 
                        />
                        <label htmlFor={fidPrefix + "potrditvenaKoda"} className="teal-text text-darken-1"><Trans>field.label.verificationCode</Trans></label>
                        <span className="helper-text" data-error={t('validation:field.validate.verificationCode')} data-success="">
                            <span className={showHelpMessage === true ? "" : "hide"}>
                                <b><Trans>field.verificationCode.help</Trans></b>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12 center-align">
                        <button onClick={handleClick} 
                            type="button" 
                            className={buttonClasses.current + " eureko-form-action-btn"}
                        >
                            {buttonText.current}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
