import React from 'react';


export default function FooterDrBestSi(props) {
    return (
        <footer className="page-footer indigo darken-4 eureko-footer" style={{background: '#0F2B62'}}>
            <div className="container">
                <div className="row">
                    <div className="col s12 center">
                        <p>
                            <small>
                            Further Underwriting International SLU (“Further”), je družba, registrirana v Španiji z davčno številko B83644484 in s sedežem na Paseo de Recoletos 12, Madrid, Španija. Družba Further je registrirana pri španskem regulatorju Dirección General de Seguros y Fondos de Pensiones, kot neekskluzivni zastopnik zavarovalnice Axeria z licenco številka AJ0241 in je pooblaščena za odločanje o zahtevkih v zvezi z zavarovalno pogodbo. Axeria iard (“Axeria”) je zavarovalnica, registrirana v Franciji s številko 352 893 200 in naslovom 26 rue du Général Mouton Duvernet, 69003 Lyon, Francija.
                            </small>
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col s12 center">
                            Eureko, d.o.o., Leskoškova cesta 9E, 1000 Ljubljana<br />
                            080 88 12 / <a href="mailto:info@eureko.si">info@eureko.si</a><br />
                            &copy; {new Date().getFullYear()} Dr Best
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );    
}

