import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';

export default function DrzavaRojstvaTextField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const fidName = 'drzavaRojstva';
    const textInput = useRef(null);

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        textInput.current.value = 'SLOVENIJA';
        let dummyEvent = {
            target: {
                name: (fidPrefix + fidName),
                value: textInput.current.value
            }
        };
        props.onChange(dummyEvent);

    }, []);

    return (
        <div className="col s12">
            <input ref={textInput} id={fidPrefix + fidName} type="text" name={fidPrefix + fidName} className="validate" onChange={handleChange} />
            <label htmlFor={fidPrefix + fidName}>Država rojstva</label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.required')} data-success=""></span>
        </div>
    );
}   