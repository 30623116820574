import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import {
    validateDatumConstraints
} from '../helper';
//import i18n from 'i18next';

export default function DatumRojstvaField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const datumRojstvaInput = useRef(null);
    /**
     * constraintType: [ZAVAROVALNISKA_STAROST, PRAVA_STAROST]
     */
    const [conf, setConf] = useState(
        typeof(props.conf) === 'undefined' ? 
        {deltaMin: null, deltaMinDays: null, deltaMax: null, constraintType: 'ZAVAROVALNISKA_STAROST'} : 
        props.conf
    );
    const [errorClass, setErrorClass] = useState('');
    const [datumValue, setDatumValue] = useState('');
    

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const getMinLetString = () => {
        if (conf.deltaMin === null) {
            return '[brez omejitve]';
        }

        return conf.deltaMin;
    };

    const getMaxLetString = () => {
        if (conf.deltaMax === null) {
            return '[brez omejitve]';
        }

        return conf.deltaMax;
    };

    const validateDateObject = (obj) => {
        return obj instanceof Date && !isNaN(obj);
    };

    /*const validateDatumConstraints = (inD) => {
        let c = new Date(), diff = null;
        
        diff = c.getFullYear() - inD.getFullYear();

        if (conf.constraintType === 'PRAVA_STAROST') {
            // Se ni star toliko.
            let temp = new Date(c.getFullYear(), inD.getMonth(), inD.getDate());
            if (temp < c) {
                diff--;
            }
        }
        
        if (conf.deltaMin !== null && diff < conf.deltaMin) {
            return false;
        }

        // Is older then 30 days? We need it for bdca.
        let diffDays = Math.floor( (c.getTime() - inD.getTime()) / (24 * 3600 * 1000) );
        if (conf.deltaMinDays !== null && diffDays < conf.deltaMinDays) {
            return false;
        }

        if (conf.deltaMax !== null && diff > conf.deltaMax) {
            return false;
        }
        
        return true;
    };*/

    const handleChange = (event) => {
        let currentValue = event.target.value, parts = currentValue.split('.'), 
            shouldBeNumber = currentValue.replace('.', '');

        // Dot auto insert.
        if (!isNaN(shouldBeNumber)) {
            setErrorClass('');

            if (currentValue.length > datumValue.length && (currentValue.length === 2 || currentValue.length === 5)) {
                // Add dot.
                currentValue = currentValue + '.';
                event.target.value = currentValue;
            }

            if (parts.length === 3 && parts[2].length > 4) {
                setErrorClass('invalid');
                datumRojstvaInput.current.setCustomValidity('Invalid date');
            }
        }

        // Date Parsing
        let objParts = {
            year: '',
            month: '',
            day: ''
        }, intVal;
        if (parts.length === 3) {

            intVal = parseInt(parts[0]);
            if (parts[0].length === 2 && !isNaN(intVal)) {
                objParts.day = parts[0];
            }
            
            intVal = parseInt(parts[1]);
            if (parts[1].length === 2 && !isNaN(intVal)) {
                objParts.month = parts[1];
            }
            
            intVal = parseInt(parts[2]);
            if (parts[2].length === 4 && !isNaN(intVal)) {
                objParts.year = parts[2];
            }
        }

        // Date validation.
        let dateObj = new Date((objParts.year + '-' + objParts.month + '-' + objParts.day));
        if (!validateDateObject(dateObj) || !validateDatumConstraints(dateObj, conf)) {
            setErrorClass('invalid');
            datumRojstvaInput.current.setCustomValidity('Invalid date');
        }
        else {
            setErrorClass('');
            datumRojstvaInput.current.setCustomValidity('');
        }
        
        setDatumValue(currentValue);
        props.onChange(event);
    };

    useEffect(() => {
        // console.debug("datumRojstva component conf ", conf);
        setConf(
            typeof(props.conf) === 'undefined' ? 
            {deltaMin: null, deltaMinDays: null, deltaMax: null, constraintType: 'ZAVAROVALNISKA_STAROST'} : 
            props.conf
        );
    }, [props.conf]);

    useEffect(() => {
        if (typeof(props.isReadOnly) === 'undefined') {
            setIsReadOnly(false);
        }
        else {
            setIsReadOnly(props.isReadOnly);
        }
        
    }, [props.isReadOnly]);

    useEffect(() => {

        let confCopy = conf;
        if (!confCopy.hasOwnProperty('constraintType')) {
            confCopy['constraintType'] = 'ZAVAROVALNISKA_STAROST';
            setConf(confCopy);
        }
    }, []);

    return (
        <div className="input-field col s12">
            <input 
                ref={datumRojstvaInput} 
                id={fidPrefix + "datumRojstva"} 
                type="text" 
                name={fidPrefix + "datumRojstva"} 
                placeholder="DD.MM.LLLL" 
                className="validate" 
                onChange={handleChange} 
                onBlur={handleChange} 
                onInput={handleChange} 
                required 
                readOnly={isReadOnly}
            />
            <label 
                htmlFor={fidPrefix + "datumRojstva"}
            >
                <Trans>field.label.dateBirth</Trans>
            </label>
            <span 
                className="helper-text" 
                data-error={i18n.t('validation:field.validate.dateBirthRequired', {min: getMinLetString(), max: getMaxLetString() })} 
                data-success=""
            ></span>
        </div>
    );
}

