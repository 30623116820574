import { React, useEffect } from 'react';
import M from '@materializecss/materialize';
import '@materializecss/materialize/dist/css/materialize.min.css';
import PrvaDiagnozaForm from '../form/PrvaDiagnozaForm';
import GuardProdukt from '../other/GuardProdukt';
import { getPrefilled } from '../form/PrvaDiagnoza/prefilModel';
import {  prvadiagnoza202204Model } from 'eureko-lib';


export default function PrvaDiagnozaRoute() {

    useEffect(() => {
        M.AutoInit();
    }, []);

    return (
        <GuardProdukt>
            <div className="container">
                <div className="row">
                    <div className="col s12 center">
                        <h4>PRVA diagnoza</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <PrvaDiagnozaForm 
                            modelInitial={getPrefilled(prvadiagnoza202204Model)}
                            zavarovanecIsSameAsZavarovalecInitial={true}
                        />
                    </div>
                </div>
            </div>
        </GuardProdukt>
    );
}
