 import { useEffect, useState } from 'react';

export default function PaketBoxField(props) {
    const activeChoice = ' green white-text';

    const [fidPrefix, setFidPrefix] = useState(typeof(props.prefix) === 'string' ? props.prefix : '');
    const [activeClasses, setActiveClasses] = useState((props.active === true) ? activeChoice : '');

    const handleClick = (e) => {
        let dummyEvent = {
            target: {
                name: (fidPrefix + "paket"),
                value: props.value
            }
        };
        props.onClick(dummyEvent);

    }

    const handleMouseEnter = (e) => {
        setActiveClasses(activeChoice);
    };
    
    const handleMouseLeave = (e) => {
        // Choice is currently selected.
        if (props.active !== true) {
            setActiveClasses('');
        }
        
    };

    useEffect(() => {
        if (props.active === true) {
            setActiveClasses(activeChoice);
        }
        else {
            setActiveClasses('');
        }
    }, [props.active])

    return (<>
        <div className="col s4">
            <div className={"card" + activeClasses} 
                onClick={handleClick} 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
            >
                <div className="card-title">
                    {props.description}
                </div>
            </div>
        </div>
        
            
        </>);
}