import { useState } from 'react';
import { useEffect } from 'react';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import FileInputField from './FileInputField';
import TextAreaField from './TextAreaField';

export default function DANEuppFieldWithExtraDataIfDa({
    name,
    onChange,
    questionText,
    isRequired,
    error,
    value,
    extraDataText,
    extraDataFileInputText,
}) {

    const { t } = useTranslation();

    const [ inputValue, setInputValue ] = useState(undefined);
    const [ extraText, setExtraText ] = useState('');
    const [ extraFiles, setExtraFiles ] = useState([]);

    const [ showExtraData, setShowExtraData ] 
        = useState(false);
    useEffect(() => {
        if (value && value.DA) {
            setInputValue('DA');
            setExtraText(value.DA.Podrobnosti || '');
            setExtraFiles(
                value.DA.Izvidi
                    .map(izvid => ({
                        fileName: izvid.imeDokumenta,
                        base64: izvid.vsebinaDokumenta,
                    }))
                || []);
        }
        else if (value && value.NE) {
            setInputValue('NE');
        }
    }, [ value ]);

    useEffect(() => {
        setShowExtraData(inputValue === 'DA');
    }, [ inputValue ]);

    const handleChange = (
        daNe,
        text,
        files,
    ) => {
        if (!onChange) return;
        if (daNe === 'DA') {
            onChange({
                DA: {
                    Podrobnosti: text,
                    Izvidi: files.map(file => ({
                        imeDokumenta: file.fileName,
                        vsebinaDokumenta: file.base64,
                    })),
                },
                NE: null,
            });
        } else if (daNe === 'NE') {
            onChange({
                DA: null,
                NE: {},
            });
        }
    };

    const handleFileDataChange = (files) => {
        setExtraFiles(files);
        handleChange(inputValue, extraText, files);
    };

    const handleExtraDataTextChange = (text) => {
        setExtraText(text);
        handleChange(inputValue, text, extraFiles);
    }

    const handleDANEChange = (event) => {
        const val = event.target.value;
        setInputValue(val);
        handleChange(val, extraText, extraFiles);
    };

    return (
        <div className="row">
            <div 
                className={'input-field col s12' + (error?.hasError ? ' invalid' : '')}
            >
                {questionText}
                <p>
                    <label
                        className={ error?.hasError ? 'eureko-input-boolean-required' : '' }
                    >
                        <input 
                            name={name} 
                            type="radio" 
                            value="DA" 
                            onChange={handleDANEChange}
                            checked={inputValue === 'DA'}
                        />
                        <span>Da</span>
                    </label>
                    <label className={"eureko-radio-label" + (error?.hasError ? ' eureko-input-boolean-required' : '')}>
                        <input 
                            name={name} 
                            type="radio" 
                            value="NE" 
                            onChange={handleDANEChange}
                            checked={inputValue === 'NE'}
                        />
                        <span>Ne</span>
                    </label>
                </p>
                {
                    showExtraData ?
                    <div>
                        <p>
                            {extraDataText}
                        </p>
                        <TextAreaField
                            error={error}
                            onChange={v => handleExtraDataTextChange(v)}
                            value={extraText}
                            name={'extra-data' + name}
                            max={150}
                        ></TextAreaField>
                        <p>
                            {extraDataFileInputText}
                        </p>
                        <FileInputField
                            name={'file-input-' + name}
                            onChange={handleFileDataChange}
                            value={extraFiles}
                            hintText="* Dovoljeni formati: png, jpg, pdf"
                        ></FileInputField>
                    </div>
                    : ''
                }
                {
                    error?.hasError
                    ? <span 
                        className="helper-text eureko-input-boolean-required" 
                    >
                        {
                            error?.required ?
                            t('validation:field.validate.daneFieldRequired')
                            : ''
                        }
                        {
                            error?.missingExtraData ?
                            'Prosimo dodajte dodatna pojasnila'
                            : ''
                        }
                    </span>
                    : '' 
                }
            </div>
        </div>
    );
}

