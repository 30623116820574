import { React, useState, useEffect, useRef, createRef } from 'react';
import M from '@materializecss/materialize';
import '@materializecss/materialize/dist/css/materialize.min.css';
import { useTranslation } from 'react-i18next';
import AllianzNezgodaForm from '../form/AllianzNezgodaForm';
import GuardProdukt from '../other/GuardProdukt';


export default function AllianzNezgodaRoute() {
    const [agentParamsStatus, setAgentParamsStatus] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {

        M.AutoInit();

    }, []);

    return (
        <GuardProdukt>
            <div className="container">
                <div className="row">
                    <div className="col s12 center">
                        <h4>Allianz Nezgoda</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <AllianzNezgodaForm />
                    </div>
                </div>
            </div>
        </GuardProdukt>
    );
}
