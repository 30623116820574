class ValidatorService {

    getUrl(endpoint) {
        let url = process.env.REACT_APP_API_URI;
        switch (endpoint) {
            case 'validatorIbanRead':
                url = url + '/validators/';
                break;
            default:
                url = null;
                break;
        }

        return url;

    }

    async validateIban(data) {
        let url = this.getUrl('validatorIbanRead');
        url += '?value=' + data.iban;
        let response = await fetch(url, {
            'method': 'GET',
            'headers': new Headers({ 'content-type': 'application/json' })
        });

        return await response.json();

    }
}

export default ValidatorService;

export const validatorService = new ValidatorService();