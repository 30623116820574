import { Link } from 'react-router-dom';

export default function TySklenjenoZavarovanjeRoute() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h4>Vaše zavarovanje je v obdelavi</h4>

                <p>
                    Veseli smo, da ste se nam pridružili v skrbi za svoje zdravje in zdravje vaših najbližjih.
                    <br /><br />
                    Vso dokumentacijo boste prejeli po elektronski pošti.
                </p>

                <Link to={"/produkti/allianz-nezgoda"}>
                    <span className="btn-large green">
                        Dodaj zavarovano osebo
                    </span>
                </Link>
            </div>
        </div>

    );
}
