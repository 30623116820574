import {React, useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';


export default function FormStepButtonField(props) {
    const [disableClass, setDisableClass] = useState('');
    const button = useRef();
    const { t } = useTranslation(['translation']);

    const handleClick = (event) => {
        props.onClick(event);
    };

    useEffect(() => {
        if (props.disabled === true) {
            setDisableClass('disabled');
        } 
        else if (props.disabled === false) {
            setDisableClass('');
        }
        else {
            if ((props.role === 'previous' && props.currentStep === 1) || (props.role === 'next' && props.currentStep === props.allSteps.length)) {
                setDisableClass('disabled');
            }
    
            if ((props.role == 'previous' && props.currentStep > 1) || (props.role === 'next' && props.currentStep < props.allSteps.length) ) {
                setDisableClass('');
            }
        }

    }, [props.allSteps, props.currentStep, props.role, props.disabled]);
    
    let roleSpecificClass = 'eureko-blue lighten-1-text';
    if (props.role === 'next') {
        roleSpecificClass = 'eureko-green eureko-blue-text';
    }
    return (
        
        <div className="input-field center-align">
            <button ref={button} className={"btn eureko-form-action-btn " + roleSpecificClass + " " + disableClass}  
                type="button" 
                onClick={handleClick} 
                formTarget="_self"
            >
                { props.role === 'next' && t('translation:form.button.step.next') }
                { props.role === 'previous' && t('translation:form.button.step.previous') }
            </button>
        </div>
        
    );
}

