import {React, useState, useEffect} from 'react';

export default function MessageBox(props) {
    const [categoryConfig, setCategoryConfig] = useState('');
    const [contentConfig, setContentConfig] = useState('');

    useEffect(() => {
        switch (props.category) {
            case 'success':
                break;

            case 'warning':
                setCategoryConfig('yellow daken-4');
                setContentConfig('black-text lighten-1');
                break;

            case 'error':
                setCategoryConfig('red lighten-2');
                setContentConfig('white-text lighten-1');
                break;
            
            case 'info':
            default:
                break;
        }
    }, [props.category]);

    return (
        <div className={"card " + categoryConfig}>
            <div className={"card-content " + contentConfig}>
                {
                    props.children ?? ''
                }
                {
                    props.content ?? ''
                }
            </div>
        </div>

        
    );
}