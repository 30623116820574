import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { reduxSelectValueFor } from '../redux/selectors';
import { maybePopulateField } from '../redux/common';

export default function DaNeField(props) {
    const store = useStore();
    const daInput = useRef(null);
    const neInput = useRef(null);
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        let storeData = store.getState();
        maybePopulateField(
            daInput,
            reduxSelectValueFor(storeData, daInput)
        );

        maybePopulateField(
            neInput,
            reduxSelectValueFor(storeData, neInput)
        );

    }, []);

    return (
        <>
            <div className="input-field col s12">
                {
                    props.children ? props.children : ''
                }
                {
                    props.question ? props.question : ''
                }
                <p style={{marginBottom: '0px'}}>
                    <label>
                        <input ref={daInput} 
                            name={fidPrefix + props.stringId} 
                            type="radio" 
                            value="D" 
                            required={true} 
                            onChange={handleChange} 
                            className="eureko-radio-large"
                        />
                        <span>Da</span>
                    </label>
                    <label className="eureko-radio-label ">
                        <input 
                            ref={neInput} 
                            name={fidPrefix + props.stringId} 
                            type="radio" 
                            value="N" 
                            required={true} 
                            onChange={handleChange} 
                            className="eureko-radio-large"
                        />
                        <span>Ne</span>
                    </label>
                </p>
                
            </div>
        </>
    );
}

