import React from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
    return (
        <nav>
            <div className="nav-wrapper indigo darken-4 ">
                <ul id="nav-mobile" className="left hide-on-med-and-down">
                    <li>
                        <b>
                            <a href="https://dr-best.si">MEDICAL CARE</a>
                        </b>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

