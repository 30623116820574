import { React, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, } from "react-router-dom";
import M from '@materializecss/materialize';
import FormSubmitField from '../field/FormSubmitField';
import FormStepButtonField from '../field/FormStepButtonField';
import PredogledArea from '../other/PredogledArea';
import ItemPonudbaService from '../service/ItemPonudbaService';
import PrvaDiagnozaFormIdd2 from './PrvaDiagnoza/PrvaDiagnozaFormIdd2';
import PrvaDiagnozaZavarovalecForm from './PrvaDiagnoza/PrvaDiagnozaZavarovalecForm';
import PrvaDiagnozaZavarovanecForm from './PrvaDiagnoza/PrvaDiagnozaZavarovanecForm';
import { getCopiedDataFromZavarovalecToZavarovanec, getInitialZavarovanecData } from './PrvaDiagnoza/prefilModel';
import PrvaDiagnozaIzbiraPaketaForm from './PrvaDiagnoza/PrvaDiagnozaIzbiraPaketaForm';
import PrvaDiagnozaPremijaForm from './PrvaDiagnoza/PrvaDiagnozaPremijaForm';
import { getPricesForPrvaDiagnoza } from 'eureko-lib';
import PrvaDiagnozaSepaForm from './PrvaDiagnoza/PrvaDiagnozaSepaForm';
import PrvaDiagnozaIzjavaForm from './PrvaDiagnoza/PrvaDiagnozaIzjavaForm';
import PrvaDiagnozaGDPRSoglasjeForm from './PrvaDiagnoza/PrvaDiagnozaGDPRSoglasjeForm';
import { buildRequestData } from './PrvaDiagnoza/buildRequestData';
import { reloadResources } from 'i18next';

export default function PrvaDiagnozaForm({
    modelInitial,
    zavarovanecIsSameAsZavarovalecInitial,
}) {
    const navigate = useNavigate();

    const [ model, setModel ] = useState(modelInitial);

    function reloadModel () {
        setModel(JSON.parse(JSON.stringify(model)));
    }
    function reloadSteps () {
        setAllSteps(JSON.parse(JSON.stringify(allSteps)));
    }

    useEffect(() => {
        handleZavarovanecIsSameAsZavarovalecChange(zavarovanecIsSameAsZavarovalecInitial);
    }, []);

    const [status, setStatus] = useState('');
    const [signedPdfContent, setSignedPdfContent] = useState(null);
    const steps = [
        {
            step: 1,
            tab: 'idd2',
            text: 'Opredelitev potreb in zahtev',
            error: false,
            isActive: true,
        },
        {
            step: 2,
            tab: 'zavarovalec',
            text: 'Zavarovalec',
            error: false,
            isActive: false,
        },
        {
            step: 3,
            tab: 'zavarovanaOseba',
            text: 'Zavarovanec',
            error: false,
            isActive: false,
        },
        {
            step: 4,
            tab: 'izbiraPaketa',
            text: 'Izbira paketa',
            error: false,
            isActive: false,
        },
        {
            step: 5,
            tab: 'premija',
            text: 'Premija',
            error: false,
            isActive: false,
        },
        {
            step: 6,
            tab: 'sepa',
            text: 'SEPA',
            error: false,
            isActive: false,
        },
        {
            step: 7,
            tab: 'izjava',
            text: 'Izjava',
            error: false,
            isActive: false,
        },
        {
            step: 8,
            tab: 'GDPRSoglasje',
            text: 'GDPR soglasje',
            error: false,
            isActive: false,
        },
        {
            step: 9,
            tab: 'form-final',
            text: 'Oddaja',
            error: false,
            isActive: false,
        },
    ];
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const [allSteps, setAllSteps] = useState(steps);

    const { t } = useTranslation(['translation', 'validation', 'flashes', 'backend']);

    const [ zavarovanecIsSameAsZavarovalec, setZavarovanecIsSameAsZavarovalec ]
        = useState(zavarovanecIsSameAsZavarovalecInitial);
    const [ sepaPersonIsSameAsZavarovalec, setSepaPersonIsSameAsZavarovalec ]
        = useState(true);


    function handleZavarovanecIsSameAsZavarovalecChange (isSame) {
        setZavarovanecIsSameAsZavarovalec(isSame);
        if (isSame) {
            model.zavarovanaOseba
                = getCopiedDataFromZavarovalecToZavarovanec(
                    model.zavarovalec, model.zavarovanaOseba)
        } else {
            model.zavarovanaOseba
                = getInitialZavarovanecData();
        }

        reloadModel();
        recalculatePrices(isSame);
    }

    const [ prices, setPrices ] = useState({
        neto: null,
        bruto: null,
        davek: null,
    });

    async function recalculatePrices (isSame) {
        const zavarovanaOseba 
            = isSame 
            ? model.zavarovalec
            : model.zavarovanaOseba;

        const prices = await getPricesForPrvaDiagnoza(
            new Date(zavarovanaOseba.datumRojstva.value),
            model.izbiraPaketa.paket.value,
        );
        setPrices(prices);
    }

    const [ naslovZaObvsecanjeIsSameAsNaslov, setNaslovZaObvsecanjeIsSameAsNaslov ]
        = useState(true);

    const handlePreviewClick = async () => {
        const data = buildRequestData(
            naslovZaObvsecanjeIsSameAsNaslov, zavarovanecIsSameAsZavarovalec, sepaPersonIsSameAsZavarovalec, model);
        try {
            const result = await new ItemPonudbaService()
                .getItemPonudbaPrvaDiagnoza202204Preview(data);

            if (result.success) {
                M.toast({ html: t("flashes:submission.preview.successful") });
                setSignedPdfContent(result.data.pdf.content);
                displayValidationErrors();
            } else {
                if (result.errors?.[0]?.code === 'INVALID_FORM_DATA') {
                    displayValidationErrors(result.errors?.[0]?.errors);
                    M.toast({ html: t(result.message) });
                }
                else if (result.errors?.[0]?.code === 'INVALID_FILE') {
                    displayValidationErrors(result.errors?.[0]?.errors);
                    M.toast({ html: 'Nevljavna priložena datoteka: ' + result.errors?.[0].fileName });
                } else {
                    M.toast({ html: t(result.message) });
                }
            }
        } catch (error) {
            M.toast({ html: t("flashes:form.error.preview") });
        }
    };

    const displayValidationErrors = (errors) => {
        allSteps.forEach(step => step.error = false);

        if (errors) {
            Object.entries(errors)
                .forEach(([formKey, formFields]) => {
                    Object.entries(formFields)
                        .forEach(([ fieldKey, field ]) => {
                            model[formKey][fieldKey].error = field.error;
                            if (field.error?.hasError) {
                                allSteps.find(step => step.tab === formKey)
                                    .error = true;
                            }
                        });
                });
        } else {
            Object.values(model)
                .forEach(tabModel => {
                    Object.values(tabModel)
                        .forEach(question => question.error = { hasError: false });
                });
        }

        reloadModel();
        reloadResources();
        reloadSteps();
    }

    const handleSubmit = async () => {
        setStatus('SUBMITTING');
        const data = buildRequestData(
            naslovZaObvsecanjeIsSameAsNaslov, zavarovanecIsSameAsZavarovalec, sepaPersonIsSameAsZavarovalec, model);
        try {
            const result = await new ItemPonudbaService()
                .sendItemPonudbaPrvaDiagnoza202204(data);

            if (result.success) {
                M.toast({ html: t("flashes:submission.successful") });
                setStatus('SUBMITTED');
                return navigate('/sklenjeno-zavarovanje-prvadiagnoza');
            } else {
                setStatus('');

                if (result.errors?.[0]?.code === 'INVALID_FORM_DATA') {
                    displayValidationErrors(result.errors?.[0]?.errors);
                    M.toast({ html: t(result.message) });
                }
                else if (result.errors?.[0]?.code === 'INVALID_FILE') {
                    displayValidationErrors(result.errors?.[0]?.errors);
                    M.toast({ html: 'Nevljavna priložena datoteka: ' + result.errors?.[0].fileName });
                } else {
                    M.toast({ html: t(result.message) });
                }
            }
        } catch (error) {
            setStatus('');
            M.toast({ html: "Another error" });
        }
    }

    function activateStep (stepToActivate) {
        allSteps.forEach(step => {step.isActive = step.tab === stepToActivate.tab;});
        setAllSteps(allSteps);
        setCurrentStep(stepToActivate);
        setTimeout(() => {
            window.scroll(0, 0);
        }, 10);
    }

    return (

            <form 
                className='eureko-prvadiagnoza'
                onSubmit={event => { event.preventDefault(); handleSubmit(); }} noValidate={true}>
                <div className="row">
                    <div className="col s12">
                        <ul 
                            className="custom-tabs" 
                        >
                            {
                                allSteps.map(step => {
                                    let classAdd = ' ';
                                    if (step.error) classAdd += 'red';
                                    if (step.isActive) classAdd += ' active';
                                    return (
                                        <li 
                                            key={step.tab}
                                            className={"cutom-tab darken-1 white-text" + classAdd}
                                            onClick={event => { event.preventDefault(); activateStep(step); }}
                                            role="navigation"
                                            >
                                                <span>
                                                    {step.text}
                                                </span>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="row">
                    {
                        currentStep.tab === 'idd2' ?
                        <div id="idd2" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaFormIdd2
                                model={model.idd2}
                            >
                            </PrvaDiagnozaFormIdd2>
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'zavarovalec' ?
                        <div id="zavarovalec" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaZavarovalecForm
                                model={model.zavarovalec}
                                onZavarovalecDatumRojstvaChange={() => recalculatePrices(zavarovanecIsSameAsZavarovalec)}
                                naslovZaObvsecanjeIsSameAsNaslov={naslovZaObvsecanjeIsSameAsNaslov}
                                onNaslovZaObvsecanjeIsSameAsNaslovChange={value => setNaslovZaObvsecanjeIsSameAsNaslov(value)}
                            >
                            </PrvaDiagnozaZavarovalecForm>
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'zavarovanaOseba' ?
                        <div id="zavarovalec-data" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaZavarovanecForm
                                model={model.zavarovanaOseba}
                                zavarovanecIsSameAsZavarovalec={zavarovanecIsSameAsZavarovalec}
                                onZavarovanecIsSameAsZavarovalecChange={bool => handleZavarovanecIsSameAsZavarovalecChange(bool)}
                                onZavarovanecDatumRojstvaChange={() => recalculatePrices(zavarovanecIsSameAsZavarovalec)}
                                zavarovalecData={model.zavarovalec}
                            >
                            </PrvaDiagnozaZavarovanecForm>
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'izbiraPaketa' ?
                        <div id="izbiraPaketa" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaIzbiraPaketaForm
                                model={model.izbiraPaketa}
                                onPaketChange={() => recalculatePrices(zavarovanecIsSameAsZavarovalec)}
                            />
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'premija' ?
                        <div id="premija" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaPremijaForm
                                model={model.premija}
                                prices={prices}
                            />
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'sepa' ?
                        <div id="sepa" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaSepaForm
                                model={model.sepa}
                                onSepaPersonIsSameAsZavarovalecChange={setSepaPersonIsSameAsZavarovalec}
                                sepaPersonIsSameAsZavarovalec={sepaPersonIsSameAsZavarovalec}
                                zavarovalecModel={model.zavarovalec}
                            />
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'izjava' ?
                        <div id="izjava" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaIzjavaForm
                                model={model.izjava}
                                izbiraPaketaModel={model.izbiraPaketa}
                            />
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'GDPRSoglasje' ?
                        <div id="GDPRSoglasje" className="col s12 eureko-tab-container">
                            <PrvaDiagnozaGDPRSoglasjeForm
                                model={model.GDPRSoglasje}
                            />
                        </div>
                        : ''
                    }
                    {
                        currentStep.tab === 'form-final' ?
                        <div id="form-final" className="col s12 eureko-tab-container">
                            <PredogledArea onPreviewClick={handlePreviewClick} requestId="preview-with-signatures" pdfContent={signedPdfContent} />
                            <FormSubmitField formStatus={status} />
                        </div>
                        : ''
                    }

                </div>

                <div className="row">
                    <div className="col m4">
                        <FormStepButtonField 
                            role="previous" 
                            allSteps={allSteps} 
                            onClick={() => activateStep(findPreviousStep(allSteps))}
                            disabled={currentStep.step === 1}
                        />
                    </div>
                    <div className="col m4 offset-m3">
                        <FormStepButtonField 
                            role="next" 
                            allSteps={allSteps} 
                            onClick={() => activateStep(findNextStep(allSteps))} 
                            disabled={currentStep.step === 9}
                        />
                    </div>
                </div>
            </form>
            

    );

}

function findNextStep (allSteps) {
    let stepIndex = 0;
    allSteps.find((step, index) => {
        if (step.isActive) stepIndex = index;
        return step.isActive;
    });

    return allSteps[stepIndex + 1];
}
function findPreviousStep (allSteps) {
    let stepIndex = 0;
    allSteps.find((step, index) => {
        if (step.isActive) stepIndex = index;
        return step.isActive;
    });

    return allSteps[stepIndex - 1];
}


