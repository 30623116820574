import { React, useEffect, useState } from 'react';
import { validateTextInput, validateZipCodeInput } from 'eureko-lib';
import PostnaStevilkaInputField from './PostnaStevilkaInputField';
import TextInputField from './TextInputField1';

export default function NaslovField1({
    name,
    onChange,
    isRequired,
    error,
    isReadOnly,
    value,
}) {

    const [ validationError, setValidationError ] = useState(error || {});
    const [ ulicaInputValue, setUlicaInputValue ] = useState(value?.ulica || '');
    const [ postnaStevilkaInputValue, setPostnaStevilkaInputValue ] = useState(value?.postnaStevilka || '');
    const [ krajInputValue, setKrajInputValue ] = useState(value?.kraj || '');

    useEffect(() => {
        setValidationError(error || {});
    }, [error]);

    useEffect(() => {
        setUlicaInputValue(value?.ulica || '');
        setPostnaStevilkaInputValue(value?.postnaStevilka || '');
        setKrajInputValue(value?.kraj || '');
    }, [ value ]);

    const handleUlicaChange = (val) => {

        setUlicaInputValue(val);
        handleChange({
            ulica: val,
            postnaStevilka: postnaStevilkaInputValue,
            kraj: krajInputValue,
        });

        validateTextInput.bind(null, { required: isRequired })(val)
            .then(newErrors => {
                setValidationError({
                    ulica: newErrors,
                    kraj: validationError.kraj,
                    postnaStevilka: validationError.postnaStevilka,
                });
            });
    }

    const handlePostnaStevilkaChange = (val) => {

        setPostnaStevilkaInputValue(val);
        handleChange({
            ulica: ulicaInputValue,
            postnaStevilka: val,
            kraj: krajInputValue,
        });

        validateZipCodeInput.bind(null, { required: isRequired })(val)
            .then(newErrors => {
                setValidationError({
                    ulica: validationError.ulica,
                    kraj: validationError.kraj,
                    postnaStevilka: newErrors,
                });
            });
    }

    const handleKrajChange = (val) => {

        setKrajInputValue(val);
        handleChange({
            ulica: ulicaInputValue,
            postnaStevilka: postnaStevilkaInputValue,
            kraj: val,
        });

        validateTextInput.bind(null, { required: isRequired })(val)
            .then(newErrors => {
                setValidationError({
                    ulica: validationError.ulica,
                    kraj: newErrors,
                    postnaStevilka: validationError.postnaStevilka,
                });
            });

    }

    const handleChange = (newValue) => {
        if (onChange)
            onChange(newValue);
    };

    return (
        <>
            <div
                className="col s12"
            >
                <TextInputField
                    isReadOnly={isReadOnly}
                    error={validationError.ulica}
                    isRequired={isRequired}
                    name={name + '-ulica'}
                    onChange={value => handleUlicaChange(value)}
                    questionText={'Ulica'}
                    validateFn={validateTextInput.bind(null, { required: isRequired })}
                    value={ulicaInputValue}
                />
            </div>
            <div
                className="col s6"
            >
                <PostnaStevilkaInputField 
                    isReadOnly={isReadOnly}
                    error={validationError.postnaStevilka}
                    isRequired={isRequired}
                    name={name + '-postnaStevilka'}
                    onChange={value => handlePostnaStevilkaChange(value)}
                    questionText={'Postna številka'}
                    validateFn={validateZipCodeInput.bind(null, { required: isRequired })}
                    value={postnaStevilkaInputValue}
                />
            </div>
            <div
                className="col s6"
            >
                <TextInputField 
                    isReadOnly={isReadOnly}
                    error={validationError.kraj}
                    isRequired={isRequired}
                    name={name + '-kraj'}
                    onChange={value => handleKrajChange(value)}
                    questionText={'Kraj'}
                    validateFn={validateTextInput.bind(null, { required: isRequired })}
                    value={krajInputValue}
                />
            </div>
        </>
    );
    
}
