import { useEffect } from 'react';
import DaNeField from '../field/DaNeField';
import AgreeField from '../field/AgreeField';


export default function DaNeKanaliGroup(props) {
    const fidPrefixKanalBase = props.prefix + props.kanalBasePrefix;

    const handleFieldChange = (event) => {
        props.onChange(event);
    };

    /**
     * @todo maybe useful in the future iterations
     */
    const handleIsKanalRequired = (kanal) => {
        return props.isKanalRequired(kanal);
    };

    return (<>
        <DaNeField prefix={props.prefix} stringId={props.questionStringId} onChange={handleFieldChange} question={props.question} />
        {props.showKanali === true && <div className='col s12'>
            <AgreeField key={"elektronskaPosta"} prefix={fidPrefixKanalBase} name="elektronskaPosta" onChange={handleFieldChange}
                isRequired={props.isKanalRequired}
                text="po elektronski pošti"
        /></div>}
        {props.showKanali === true && <div className='col s12'> <AgreeField key={"SMS"} prefix={fidPrefixKanalBase} name="SMS" onChange={handleFieldChange}
            isRequired={props.isKanalRequired}
            text="sporočil po telefonu (SMS)"
        /></div>}
        {props.showKanali === true && <div className='col s12'> <AgreeField key={"klicPoTelefonu"} prefix={fidPrefixKanalBase} name="klicPoTelefonu" onChange={handleFieldChange}
            isRequired={props.isKanalRequired}
            text="klica po telefonu"
        /></div>}
        {props.showKanali === true && <div className='col s12'> <AgreeField key={"posta"} prefix={fidPrefixKanalBase} name="posta" onChange={handleFieldChange}
            isRequired={props.isKanalRequired}
            text="pošte"
        /></div>}
        {props.showKanali === true && <div className='col s12'> <AgreeField key={"drugePoti"} prefix={fidPrefixKanalBase} name="drugePoti" onChange={handleFieldChange}
            isRequired={props.isKanalRequired}
            text="drugih poti"
        /></div>}
        

    </>);
}