import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
//import i18n from 'i18next';

export default function OsebniDokumentDatumIzdajeField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const fieldInput = useRef(null);
    const [conf, setConf] = useState(typeof(props.conf) === 'undefined' ? {deltaMin: null, deltaMax: null} : props.conf);
    const [errorClass, setErrorClass] = useState('');
    const [datumValue, setDatumValue] = useState('');
    

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const getMinLetString = () => {
        if (conf.deltaMin === null) {
            return '[brez omejitve]';
        }

        return conf.deltaMin;
    };

    const getMaxLetString = () => {
        if (conf.deltaMax === null) {
            return '[brez omejitve]';
        }

        return conf.deltaMax;
    };

    const validateDateObject = (obj) => {
        return obj instanceof Date && !isNaN(obj);
    };

    const validateDatumConstraints = (inD) => {
        let c = new Date();
        let diff = c.getFullYear() - inD.getFullYear();  // Zavarovalniska starost.

        if (conf.deltaMin !== null && diff < conf.deltaMin) {
            return false;
        }

        if (conf.deltaMax !== null && diff > conf.deltaMax) {
            return false;
        }
        
        return true;
    };

    const handleChange = (event) => {
        console.debug("onChange " + fidPrefix + "datumIzdaje")
        let currentValue = event.target.value, parts = currentValue.split('.'), 
            shouldBeNumber = currentValue.replace('.', '');

        // Dot auto insert.
        if (!isNaN(shouldBeNumber)) {
            setErrorClass('');

            if (currentValue.length > datumValue.length && (currentValue.length === 2 || currentValue.length === 5)) {
                // Add dot.
                currentValue = currentValue + '.';
                event.target.value = currentValue;
            }

            if (parts.length === 3 && parts[2].length > 4) {
                setErrorClass('invalid');
                fieldInput.current.setCustomValidity('Invalid date');
            }
        }

        // Date Parsing
        let objParts = {
            year: '',
            month: '',
            day: ''
        }, intVal;
        if (parts.length === 3) {

            intVal = parseInt(parts[0]);
            if (parts[0].length === 2 && !isNaN(intVal)) {
                objParts.day = parts[0];
            }
            
            intVal = parseInt(parts[1]);
            if (parts[1].length === 2 && !isNaN(intVal)) {
                objParts.month = parts[1];
            }
            
            intVal = parseInt(parts[2]);
            if (parts[2].length === 4 && !isNaN(intVal)) {
                objParts.year = parts[2];
            }
        }

        // Date validation.
        let dateObj = new Date((objParts.year + '-' + objParts.month + '-' + objParts.day));
        if (!validateDateObject(dateObj) || !validateDatumConstraints(dateObj)) {
            setErrorClass('invalid');
            fieldInput.current.setCustomValidity('Invalid date');
        }
        else {
            setErrorClass('');
            fieldInput.current.setCustomValidity('');
        }
        
        setDatumValue(currentValue);
        props.onChange(event);
    };

    return (
        <div className="input-field col s12">
            <input ref={fieldInput} id={fidPrefix + "datumIzdaje"} type="text" name={fidPrefix + "datumIzdaje"} placeholder="DD.MM.LLLL" className="validate" onChange={handleChange} onBlur={handleChange} onInput={handleChange} required />
            <label htmlFor={fidPrefix + "datumIzdaje"}><Trans>field.label.osebniDokumentDatumIzdaje</Trans></label>
            <span className="helper-text" data-error={t('validation:field.validate.osebniDokumentDatumIzdajeRequired', {min: getMinLetString(), max: getMaxLetString() })} data-success=""></span>
        </div>
    );
}

