import { React } from 'react';
import DANEuppFieldWithExtraDataIfDa from '../../field/DANEuppFieldWithExtraDataIfDa';
import RadioMultipleField from '../../field/RadioMultipleField';

export default function PrvaDiagnozaIzbiraPaketaForm({
    model,
    onPaketChange,
}) {

    return (

        <div>
            <fieldset>
                <legend>Izbira paketa</legend>
                <div className='row'>
                    <div
                        className='col s12'
                    >
                    <RadioMultipleField
                        isRequired={model.paket.required}
                        onChange={value => { model.paket.value = value; onPaketChange() }}
                        name={model.paket.name}
                        questionText={model.paket.text.sl}
                        error={model.paket.error}
                        options={model.paket.options}
                        value={model.paket.value}
                    />
                    </div>
                    <div
                        className='col s12'
                    >
                        <b>
                        Na vsa vprašanja, na katera odgovorite z DA, podajte podrobne opise:
                        </b>
                        <p>
                        Izključeno je kritje stroškov v primeru kroničnih stanj in stanj, ki so nastala pred pričetkom zavarovalne pogodbe.
                        </p>
                    </div>
                    <div
                        className='col s12'
                    >
                        <DANEuppFieldWithExtraDataIfDa
                            isRequired={model.q1.required}
                            onChange={value => model.q1.value = value}
                            name={model.q1.name}
                            questionText={model.q1.text.sl}
                            error={model.q1.error}
                            value={model.q1.value}
                            extraDataText={model.q1.extraDataText}
                            extraDataFileInputText={model.q1.extraDataFileInputText}
                        />
                    </div>
                    <div
                        className='col s12'
                    >
                        <DANEuppFieldWithExtraDataIfDa
                            isRequired={model.q2.required}
                            onChange={value => model.q2.value = value}
                            name={model.q2.name}
                            questionText={model.q2.text.sl}
                            error={model.q2.error}
                            value={model.q2.value}
                            extraDataText={model.q2.extraDataText}
                            extraDataFileInputText={model.q2.extraDataFileInputText}
                        />
                    </div>
                    <div
                        className='col s12'
                    >
                        <DANEuppFieldWithExtraDataIfDa
                            isRequired={model.q3.required}
                            onChange={value => model.q3.value = value}
                            name={model.q3.name}
                            questionText={model.q3.text.sl}
                            error={model.q3.error}
                            value={model.q3.value}
                            extraDataText={model.q3.extraDataText}
                            extraDataFileInputText={model.q3.extraDataFileInputText}
                        />
                    </div>
                </div>
            </fieldset>
        </div>
    );

}


