import { React, useState, useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { reduxSelectFieldValue, reduxSelectValueFor } from '../redux/selectors';
import { maybePopulateField } from '../redux/common';
//import i18n from 'i18next';

export default function EmailField(props) {
    const store = useStore();
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const fidName = (typeof(props.name) === 'undefined') ? 'email' : props.name;
    const fidStyles = (typeof (props.styles) === 'undefined') ? { gridClasses: 'col s6'} : props.styles;
    const isRequired = (typeof(props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;
    const initIsReadOnly = (typeof (props.isReadOnly) === 'undefined' || props.isReadOnly === false) ? false : true;
    const [isReadOnly, setIsReadOnly] = useState(initIsReadOnly);
    const [inputClasses, setInputClasses] = useState(initIsReadOnly ? 'valid' : 'validate');
    
    const emailInput = useRef(null);

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        let storeData = store.getState();
        maybePopulateField(
            emailInput,
            reduxSelectValueFor(storeData, emailInput)
        );

    }, []);

    return (
        <div className={"input-field " + fidStyles.gridClasses}>
            <input ref={emailInput} id={fidPrefix + fidName} type="email" name={fidPrefix + fidName} className={inputClasses} required={isRequired} readOnly={isReadOnly} onChange={handleChange} />
            <label htmlFor={fidPrefix + fidName}><Trans>field.label.email</Trans></label>
            <span className="helper-text" data-error={i18n.t('validation:field.validate.emailRequired')} data-success=""></span>
        </div>
    );
}

