export const izbiraProduktaService = {
    async getRecommendedProductsForZavarovanaOseba(
        davcnaStevilka,
        stevilkaZdravstvenegaZavarovanja,
        agentParams,
        jobSessionId,
    ) {

        if (!davcnaStevilka) 
            throw Error('getRecommendedProductsForZavarovanaOseba - missing davcnaStevilka');

        try {            
            let paramsUrlPart 
                = '?davcnaStevilka=' + davcnaStevilka
                 + '&agentParams=' + encodeURIComponent(agentParams)
                 + '&jobSessionId=' + encodeURIComponent(jobSessionId);
            // let paramsUrlPart 
            //     = '?davcnaStevilka=' + davcnaStevilka
            //      + '&agentParams=' + encodeURIComponent("?t=bc89378a7bf08e7b32bcf58b2c8131077933d9bfb1b5bff50978dfd01dcf9ae1&h=a84cda4e946b2a574c7b8d5c2f9c1c85582c72776662f6dc97a357ff44209502")
            //      + '&jobSessionId=' + encodeURIComponent("39ca5ec9-f419-4ea5-a8d4-edc295dd696b");

            if (stevilkaZdravstvenegaZavarovanja)
                paramsUrlPart += (
                    '&stevilkaZdravstvenegaZavarovanja=' 
                    + stevilkaZdravstvenegaZavarovanja
                ); 
    
            const url 
                = process.env.REACT_APP_API_URI 
                + '/validators/products-recommendation'
                + paramsUrlPart;
            const response = await fetch(url, {
                'method': 'GET',
                'headers': new Headers({ 'content-type': 'application/json' })
            });
    
            const d = await response.json();
            if (d?.data?.zavarovanaOseba?.produkti?.length) {
                return (d?.data?.zavarovanaOseba?.produkti)
                    .map(produkt => {
                        if (produkt.naziv === 'PD') {
                            return {
                                label: 'Prva Diagnoza',
                                priporocljivo: produkt.priporocljivo,
                                id: produkt.naziv,
                            }
                        } 
                        if (produkt.naziv === 'ALLIANZ') {
                            return {
                                label: 'Allianz Nezgoda',
                                priporocljivo: produkt.priporocljivo,
                                id: produkt.naziv,
                            }
                        } 
                        else return null;
                    })
                    .filter(r => r !== null);
            }
            else
                throw Error('Can not load recommended products');
        } catch (error) {
            console.error(error);
            throw Error('Can not load recommended products');
        }
    }
}
