import { parseFieldName } from '../common';
import { PRIVOLITVENA_EUREKO_PRIVOLITEV_Q33_UPDATED } from '../types/privolitvenaEurekoType';
import { ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED } from '../types/zavarovalecType';
import { ZAVAROVANEC_IZJAVA_GDPR_Q62_UPDATED } from '../types/zavarovanaOsebaType';
import { ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED } from '../types/zavarovanaOsebaType';

const INITIAL_STATE = {
    klicPoTelefonu: false,
    posta: false,
    elektronskaPosta: false,
    SMS: false,
    drugePoti: false
};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    switch (action.type) {

        case ZAVAROVALEC_AMTRUST_IZJAVA_GDPR_Q62_UPDATED:
        case PRIVOLITVENA_EUREKO_PRIVOLITEV_Q33_UPDATED:
        case ZAVAROVANEC_IZJAVA_GDPR_Q62_UPDATED:
        case ZAVAROVANEC_PRISTOP_ABI_IZJAVA_GDPR_Q32_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;
            
        default:
            return state;
    }
};