import { parseFieldName } from '../common';
import { ZAVAROVANEC_IZJAVA_ZAVAROVANCA_UPDATED } from '../types/zavarovanaOsebaType';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    let newState = {
        ...state
    }, fieldInfo;
    
    switch (action.type) {
        case ZAVAROVANEC_IZJAVA_ZAVAROVANCA_UPDATED:
            fieldInfo = parseFieldName(action.payload.field);
            newState[fieldInfo.name] = action.payload.value;
            return newState;

        default:
            return state;
    }
};


