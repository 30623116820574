import { React, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';


export default function AgreeField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const [intValue, setIntValue] = useState(typeof(props.value) === 'boolean' ? props.value : false);

    const {t, i18n} = useTranslation(['validation', 'flashes']); 

    const handleChange = (event) => {
        setIntValue(event.target.checked);
        let dummyEvent = {
            target: {
                name: (fidPrefix + props.name),
                value: event.target.checked
            }
        };
        props.onChange(dummyEvent);
    }

    useEffect(() => {
        let dummyEvent = {
            target: {
                name: (fidPrefix + props.name),
                value: intValue
            }
        };
        props.onChange(dummyEvent);
        
    }, []);

    return (
        <p style={{marginBottom: '0px'}}>
            <label 
                htmlFor={fidPrefix + props.name} 
                data-error={i18n.t('validation:field.validate.required')} 
                data-success=""
            >
                <input 
                    id={fidPrefix + props.name} 
                    name={fidPrefix + props.name} 
                    type="checkbox" 
                    className="validate" 
                    onChange={handleChange} 
                    required={props.isRequired} 
                    value="true" 
                    checked={intValue}
                />
                <span>
                    {
                        props.children ? props.children : ''
                    }
                    {
                        props.text ? props.text : ''
                    }
                </span>
            </label>
        </p>
    );
}


