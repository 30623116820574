import { React } from 'react';
import DaNeField1 from '../../field/DaNeField1';
import PodpisField1 from '../../field/PodpisField1';
import TextInputField from '../../field/TextInputField1';
import { validateTextInput } from 'eureko-lib';
import RadioMultipleField from '../../field/RadioMultipleField';

export default function PrvaDiagnozaFormIdd2({
    model,
}) {

    return (

        <>
            <fieldset>
                <legend>Opredelitev potreb in zahtev zavarovalca</legend>
                <p>
                V skladu s smernicami EU in direktivo IDD2, ki zagotavlja varstvo potrošnikov na področju zavarovalništva.
                </p>
                <p>
                Prizadevamo si, da na podlagi pridobljenih informacij opredelimo vaše potrebe in zahteve ter vam na razumljiv način omogočimo informirano odločitev. Pri tem se zanašamo na verodostojno izpolnitev vprašalnika.
                </p>
                {
                    [ ...Object.values(model) ].map((item) => {
                        return (
                            <div 
                                key={'opredelitevPotreb' + item.name}
                            >
                                {
                                    item.formType === 'radio'
                                    ? <div>
                                        <DaNeField1
                                            isRequired={item.required}
                                            onChange={value => item.value = value}
                                            name={item.name}
                                            questionText={item.text.sl}
                                            error={item.error}
                                            value={item.value}
                                        />
                                    </div>
                                    : ''
                                }
                            </div>
                        );
                    })
                }
            </fieldset>
            <fieldset>
                <legend>Trajnostnost</legend>
                <div>
                    <RadioMultipleField
                        isRequired={model.q71q72q73.required}
                        onChange={value => model.q71q72q73.value = value}
                        name={model.q71q72q73.name}
                        questionText={model.q71q72q73.text.sl}
                        error={model.q71q72q73.error}
                        options={model.q71q72q73.options}
                        value={model.q71q72q73.value}
                    />
                </div>
                <div>
                    <p
                        style={{ fontSize: '12px' }}
                    >
                        [1] Varovanje okolja – vključuje aktivnosti, ki vplivajo na okolje in ohranjanje narave. Poleg zmanjševanja onesnaževanja in ločevanja odpadkov, ki so pri tem vidiku pomembni dejavniki, sem spadajo še zniževanje ogljičnega odtisa, učinkovita raba vodnih virov in energije, zniževanje uporabe ter odvisnosti od fosilnih goriv itd.
                    </p>
                    <p
                        style={{ fontSize: '12px' }}
                    >
                        [2] Socialni vidik – vključuje vedenje in aktivnosti, ki vplivajo na ljudi in soočanje s socialnimi izzivi. Sem spadajo rasna, spolna in verska raznolikost, skrb za zdravje in varnost, človekove pravice, zasebnost, zaščita delavcev, dobri delovni pogoji itd.
                    </p>
                    <p
                        style={{ fontSize: '12px' }}
                    >
                        [3] Korektno upravljanje poslovanja – vključuje vedenje in aktivnosti podjetja, ki vplivajo na interno delovanje in upravljanje poslovanja. V ta vidik spadajo transparentnost poslovanja, etika in vrednote, spolna raznolikost zaposlenih, korektno nagrajevanje zaposlenih, davčne strategije in računovodski standardi, preglednost in boj proti korupciji, pravice delničarjev itd.
                    </p>
                </div>
            </fieldset>
            <fieldset>
                <div>
                    <RadioMultipleField
                        isRequired={model.q1potrjujemq2nezelim.required}
                        onChange={value => model.q1potrjujemq2nezelim.value = value}
                        name={model.q1potrjujemq2nezelim.name}
                        questionText={model.q1potrjujemq2nezelim.text.sl}
                        error={model.q1potrjujemq2nezelim.error}
                        options={model.q1potrjujemq2nezelim.options}
                        value={model.q1potrjujemq2nezelim.value}
                    />
                </div>
            </fieldset>
            <fieldset>
                <div>
                    <PodpisField1
                            isRequired={model.podpisSvetovalec.required}
                            onChange={value => model.podpisSvetovalec.value = value}
                            name={model.podpisSvetovalec.name}
                            questionText={model.podpisSvetovalec.text.sl}
                            error={model.podpisSvetovalec.error}
                            value={model.podpisSvetovalec.value}
                    />
                </div>
                <div>
                    <PodpisField1
                            isRequired={model.podpisZavarovalec.required}
                            onChange={value => model.podpisZavarovalec.value = value}
                            name={model.podpisZavarovalec.name}
                            questionText={model.podpisZavarovalec.text.sl}
                            error={model.podpisZavarovalec.error}
                            value={model.podpisZavarovalec.value}
                    />
                </div>
                <div>
                    <TextInputField
                        isRequired={model.krajPodpisa.required}
                        onChange={value => model.krajPodpisa.value = value}
                        name={model.krajPodpisa.name}
                        questionText={model.krajPodpisa.text.sl}
                        error={model.krajPodpisa.error}
                        validateFn={validateTextInput.bind(null, { required: model.krajPodpisa.required })} 
                        isReadOnly={model.krajPodpisa.readonly}
                        value={model.krajPodpisa.value}                                        
                    />
                </div>
            </fieldset>
        </>
    );

}


