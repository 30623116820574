import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { reduxSelectMetaPaket } from '../redux/selectors';
import PaketBdcaRow from '../part/PaketBdcaRow';


export default function IzbiraSamostojenBdcaRoute() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectMetaPaket = useSelector(reduxSelectMetaPaket);
    const urlParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (params, p) => params.get(p)
    });


    useEffect(() => {
        if (selectMetaPaket === 'BDCA_STANDARD' || selectMetaPaket === 'BDCA_PREMIUM' || selectMetaPaket === 'BDCA_BASIC') {
            navigate('/skleni');
        }
    }, [
        selectMetaPaket,
        navigate
    ]);

    useEffect(() => {
        document.title = "Izbira paketa | Pridruži se Dr Best";
        const paket = urlParams?.paket;
        if (paket === 'BDCA_STANDARD' || paket === 'BDCA_PREMIUM' || paket === 'BDCA_BASIC') {
            dispatch({
                type: 'META_UPDATED',
                payload: {
                    field: 'meta_paket',
                    value: paket
                }
            });

            navigate('/skleni');
        }
    }, []);

    return (
        <>
            <div className="row">
                <div className="col s12 center">
                    <h4>Izbira paketa Dr Best</h4>
                </div>
            </div>
            <PaketBdcaRow />
        </>
    );

}