export default function TySklepanjeVstopSvetovalecRoute() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h3>Soglasje za sklepanje je bilo oddano</h3>

                <p>
                    Hvala za vnešene podatke.<br/><br/>
                    S pomočjo prisotnega svetovalca lahko nadaljujete sklepanje na papirju.

                </p>
            </div>
        </div>

    );
}
