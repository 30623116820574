import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PrenosPodatkov1Field from '../field/PrenosPodatkov1Field';


export default function Predogled2Table({
    allowedDocuments,
    onClick
}) {
    const [pdfStatus, setPdfStatus] = useState('');
    const [timeoutReached, setTimeoutReached] = useState(true);

    const { t, i18n } = useTranslation(['translation']);

    const handleClick = (documentType, event) => {
        event.preventDefault();
        console.log('Predogled2Table ', documentType);
        onClick(documentType);
    }


    /*const handlePreviewClick = (event) => {
        let formValidated = props.onPreviewClick(props.requestId);
        if (!formValidated) {
            setPdfStatus('');
        }

        setPdfStatus('LOADING');
        setTimeoutReached(false);
        setTimeout(() => setTimeoutReached(true), 15000);
        
    }

    useEffect(() => {
        let mounted = true;
        if (mounted && props.pdfContent !== null) {
            setPdfStatus('LOADED');
        }

        return () => {
            mounted = false;
        }
    }, [props.pdfContent]);*/

    return (
        <>
            <table 
                className="table"
            >
                <thead>
                    <tr>
                        <th>
                            Dokumenti za predogled
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allowedDocuments.map(doc => {
                            return (
                                <tr key={doc.id}>
                                    <td>
                                        <a
                                            href="#"
                                            onClick={handleClick.bind(null, doc.apiId)}
                                            role="button"
                                            
                                        >
                                            {doc.text}
                                        </a>
                                    </td>
                                </tr>

                            );
                        })
                    }
                </tbody>
            </table>
        </>
    );
} 