import { applyMiddleware, createSelector } from '@reduxjs/toolkit';
import { appLogDebug } from '../helper';

class AppStatePropertyError extends Error {
    constructor(message, options) {

    }
}

export function reduxSelectState(state) {
    return state;
}

export function reduxSelectFieldRef(state, fieldRef) {
    return fieldRef;
}

export function reduxSelectFieldValue(state, fieldRef) {
    let parts = fieldRef.current.name.split('_'), partState = state, key = '';
    /*console.debug('parts redux....');
    console.debug(parts);*/
    for (let i = 0; i < parts.length; i++) {
        key = parts[i];

        if (typeof (partState[key]) === 'undefined') {
            return null;
        }

        partState = partState[key];
    }

    return partState;

}

export const reduxSelectMetaAgentParams = (state) => {
    return state.meta.agentParams;
};

export const reduxSelectMetaJobSessionId = (state) => {
    return state.meta.jobSessionId;
};

export const reduxSelectValueFor = createSelector([state => state, (state, fieldRef) => fieldRef], reduxSelectFieldValue);



// -----------------------------------------------------------
// Eureko Ponudba - Input Selectors
// -----------------------------------------------------------

export const reduxInZavarovalecAmTrustGdprQ611 = (state) => {
    try {
        return state.zavarovalec.amTrust.izjavaGDPR.q611;
    } catch (e) {
        return undefined;
    }
};

export const reduxInZavarovanaOsebaAmTrustGdprQ611 = (state) => {
    try {
        return state.produkt.zavarovanaOseba.izjavaGDPR.q611;
    } catch (e) {
        return undefined;
    }
};

// -----------------------------------------------------------
// Eureko Ponudba - Output Selectors
// -----------------------------------------------------------

export const reduxOutGdprKanaliShow = (gdprChoice) => {
    appLogDebug("Executing selector: reduxSelectZavarovalecAmTrustGdprKanaliShow");
    if (typeof(gdprChoice) === 'undefined') {
        return false;
    }

    return ( (gdprChoice === 'D') ? true : false );
};

// -----------------------------------------------------------
// Cached
// -----------------------------------------------------------
 
export const reduxSelectZavarovalecAmTrustGdprKanaliShow =  createSelector(
    [reduxInZavarovalecAmTrustGdprQ611], 
    reduxOutGdprKanaliShow
);

export const reduxSelectZavarovanaOsebaAmTrustGdprKanaliShow = createSelector(
    [reduxInZavarovanaOsebaAmTrustGdprQ611],
    reduxOutGdprKanaliShow
);


// -----------------------------------------------------------
// Non-cached
// -----------------------------------------------------------

export const reduxSelectIsZavarovalecAmTrustGdprKanalRequired = (state) => {

    // zavarovalec_amtrust_izjavagdpr_q62_
    if (typeof(state.zavarovalec) === 'undefined') {
        return false;
    }
    else if (typeof(state.zavarovalec.amTrust) === 'undefined') {
        return false;
    }
    else if (typeof(state.zavarovalec.amTrust.izjavaGDPR) === 'undefined') {
        return false;
    }

    const gdpr = state.zavarovalec.amTrust.izjavaGDPR.q61 ?? 'N';
    const komuniciranje = state.zavarovalec.amTrust.izjavaGDPR.q62 ?? {};

    // Nothing else to check.
    if (gdpr === 'N') {
        return false;
    }

    if (komuniciranje.klicPoTelefonu === true) {
        return false;
    }

    if (komuniciranje.posta === true) {
        return false;
    }

    if (komuniciranje.elektronskaPosta === true) {
        return false;
    }

    if (komuniciranje.SMS === true) {
        return false;
    }

    if (komuniciranje.drugePoti === true) {
        return false;
    }

    return true;
};

export const reduxSelectIsZavarovanaOsebaAmTrustGdprKanalRequired = (state) => {

    // zavarovalec_amtrust_izjavagdpr_q62_
    if (typeof(state.produkt) === 'undefined') {
        return false;
    }
    else if (typeof(state.produkt.zavarovanaOseba) === 'undefined') {
        return false;
    }
    else if (typeof (state.produkt.zavarovanaOseba.izjavaGDPR) === 'undefined') {
        return false;
    }

    const gdpr = state.produkt.zavarovanaOseba.izjavaGDPR.q61 ?? 'N';
    const komuniciranje = state.produkt.zavarovanaOseba.izjavaGDPR.q62 ?? {};

    // Nothing else to check.
    if (gdpr === 'N') {
        return false;
    }

    if (komuniciranje.klicPoTelefonu) {
        return false;
    }

    if (komuniciranje.posta) {
        return false;
    }

    if (komuniciranje.elektronskaPosta) {
        return false;
    }

    if (komuniciranje.SMS) {
        return false;
    }

    if (komuniciranje.drugePoti) {
        return false;
    }

    return true;
};

export const reduxSelectMetaPaket = (state) => {
    try {
        return state.meta.paket;
    } catch (e) {
        return undefined;
    }

};