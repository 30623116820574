import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SpolField1({
    name,
    onChange,
    isRequired,
    error,
    value,
    isReadOnly,
}) {
    const { t } = useTranslation(['validation', 'flashes']);

    const [ inputValue, setInputValue ] = useState(value || undefined);

    useEffect(() => {
        setInputValue(value || undefined);
    }, [ value ]);

    const handleChange = (event) => {
        if (isReadOnly) return;

        const val = event.target.value;
        setInputValue(val);

        if (onChange)
            onChange(val);
    };

    return (
        <div
            className={'input-field' + (error?.hasError ? ' invalid' : '')}
            id={'radio-multiple-' + name}
        >
            Spol
            <p>
                <label
                    className={ error?.hasError ? 'eureko-input-boolean-required' : '' }
                >
                    <input 
                        name={name} 
                        type="radio" 
                        value="M" 
                        required={isRequired} 
                        onChange={handleChange}
                        checked={inputValue==='M'}
                        readOnly={isReadOnly}
                    />
                    <span>Moški</span>
                </label>
                <label className={"eureko-radio-label" + (error?.hasError ? ' eureko-input-boolean-required' : '')}>
                    <input 
                        name={name} 
                        type="radio" 
                        value="Ž" 
                        required={isRequired} 
                        onChange={handleChange}
                        checked={inputValue==='Ž'}
                    />
                    <span>Ženski</span>
                </label>
            </p>
            {
                error?.hasError
                ? <span 
                    className="helper-text eureko-input-boolean-required" 
                >
                    {t('validation:field.validate.gender')}
                </span>
                : '' 
            }
        </div>
    );
}

