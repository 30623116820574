import { React, useState, useEffect, useRef } from 'react';


export default function HiddenField(props) {

    const [clName, setClName] = useState(typeof(props.clName) === 'string' ? props.clName : '')
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {
        let dummyEvent = {
                target: {
                    name: (fidPrefix + props.name),
                    value: props.value
                },
        }
        props.onChange(dummyEvent);
    }, [props.value]);

    return (
        <div className="hide">
            <input 
                name={fidPrefix + props.name} 
                value={typeof (props.value) === 'string' ? props.value : ''}
                onChange={handleChange}  
                className={clName}
            />
        </div>
    );
    
}
