export default function TySklepanjeVstopRoute() {
    return (
        <div className="row">
            <div className="col s12 center">
                <h3>Soglasje za sklepanje je bilo oddano</h3>

                <p>
                    Hvala za vaše podatke. Po telefonu vas bo poklical svetovalec.<br/><br/>
                    Na e-poštni naslov pa boste prejeli dokument, ki vas seznanja z njegovimi podatki in podatki družbe, v okviru katere le - ta deluje.
                </p>
            </div>
        </div>

    );
}
