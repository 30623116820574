export function parseFieldName(fieldName) {
    let i = fieldName.lastIndexOf('_');
    let result = {
        prefix: '',
        name: fieldName
    };

    if (i !== -1) {
        result.prefix = fieldName.slice(0, (i+1));
        i++;
        result.name = fieldName.slice(i);
    }

    return result;
}

export function convertFieldIdToReduxPath(fieldName) {
    let result = parseFieldName(fieldName);

    /**
     * @todo Make adjsutments when necessary.
     */
    let path = result.prefix + result.name;
    path = path.replace('_', '.');
    return path;
}

export function getStateFieldValue(state, path) {
    let parts = path.split('.'), partState = state, key = '';
    /*console.debug('parts redux....');
    console.debug(parts);*/
    for (let i = 0; i < parts.length; i++) {
        key = parts[i];

        // Empty key not valid
        if (key === '') {
            continue;
        }

        if (typeof (partState[key]) === 'undefined') {
            return null;
        }

        partState = partState[key];
    }

    return partState;

}


export function getActionType(prefix, actionId) {
    let r = prefix.toUpperCase() + actionId.toUpperCase();
    return r;
}

export function inputValueToUpperCase(value) {
    if (typeof(value) === 'string') {
        value = value.toUpperCase();
    }

    return value;
}

export function formatDecimal(decimal) {
    console.debug(decimal);
    if (decimal === null || typeof (decimal) === 'undefined') {
        return '/';
    }

    let string = new String(decimal.toFixed(2));
    return string.replace('.', ',');
}

export function maybePopulateField(field, value) {
    switch(field.current.type) {
        case 'radio':
        case 'checkbox':
            if (value === field.current.value) {
                field.current.checked = true;
                field.current.focus();
            }
            break;
            
        default:
            if (typeof(value) === 'string' && value.length) {
                field.current.focus();
                field.current.value = value;
            }
            break;
    }
    

    return field;
}


export function datumEuToTimestamp(dateString) {
    let parts = dateString.split('.'), i = 0;
    while (i < parts.length) {
        switch (i) {
            case 0:
                // day
                if (parts[i].length !== 2) {
                    return NaN;
                }
                break;
            case 1:
                // month
                if (parts[i].length !== 2) {
                    return NaN;
                }
                break;
            case 2:
                // year
                if (parts[i].length !== 4) {
                    return NaN;
                }
                break;
        }

        i++;
    }
    let mIndex = parseInt(parts[1]) - 1;
    let date = new Date(parseInt(parts[2]), mIndex, parseInt(parts[0]));
    return date.getTime();
}