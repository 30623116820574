import { React } from 'react';
import CheckboxFieldRequiredChecked from '../../field/CheckboxFieldRequiredChecked';
import PodpisField1 from '../../field/PodpisField1';
import { validateCheckboxRequiredChecked } from 'eureko-lib';
import MessageBox from '../../other/MessageBox';



export default function PrvaDiagnozaIzjavaForm({
    model,
    izbiraPaketaModel,
}) {
    return (
        <>
            {
                izbiraPaketaModel.paket.value ?
                <div>
                    <fieldset>
                        <legend>Izjava</legend>
                        <p>
                        Spodaj podpisani izjavljam in s podpisom potrjujem, da so vsi podatki in navedbe v tej ponudbi in odgovori na zastavljena vprašanja resnicni, pravilni in popolni. Ce se naknadno ugotovi nasprotno, lahko zavarovalnica ukrepa v skladu z dolocili Obligacijskega zakonika in pogojev, ki veljajo za to zavarovanje, in lahko, skladno s pogoji za zavarovanje, zavrne placilo zavarovalnega primera. S podpisom izjavljam še, da sem seznanjen(-a) s Politiko zasebnosti Prve osebne zavarovalnice, d.d., katere izvlecek je vsebovan v splošnih pogojih, je objavljena na spletni strani www.prva.si in dostopna na sedežu zavarovalnice. Seznanjen(-a) sem, da Prva osebna zavarovalnica, d.d., vse pridobljene osebne podatke obdeluje za izvajanje pogodbe, izpolnjevanje zakonskih obveznosti in za neposredno trženje. Obdelavi osebnih podatkov za neposredno trženje lahko kadarkoli in brez vpliva na sklenjeno pogodbo ugovarjate s pisno zahtevo, poslano na naslov: Prva osebna zavarovalnica, d.d, Fajfarjeva ulica 33, 1000 Ljubljana ali na info@prva.si. Podani ugovor ne vpliva na zakonitost obdelave do trenutka njegove podaje niti na obdelavo na drugih pravnih podlagah. S podpisom pooblašcam zavarovalnico, da kadar koli in od katerega koli zdravnika, zdravstvene oziroma druge ustanove pridobi vse podatke o mojem zdravstvenem stanju ali poteku zdravljenja oziroma podatke, ki bi vplivali na oceno nevarnosti in na ugotavljanje obveznosti zavarovalnice. Potrjujem, da sem pred podpisom te ponudbe prejel(-a) informacije o zavarovalnem produktu ter izjavljam, da sem seznanjen(-a) s splošnimi, posebnimi in dodatnimi pogoji, ki veljajo za s to zavarovalno pogodbo sklenjena zavarovanja, in z drugimi sestavnimi deli zavarovalne pogodbe o zdravstvenem zavarovanju, ki jih v celoti sprejemam.
                        </p>
                    </fieldset>
                    <fieldset>
                        <legend>Podpis</legend>
                        <div
                            className='row'
                        >
                            {
                                izbiraPaketaModel.paket.value === 'BrezNapotnice' ?
                                <div
                                    className='col s12'
                                >
                                    <p>
                                    Potrjujem prejem pripadajočih dokumentov paketa: PRVA Diagnoza brez napotnice + zdravila:
                                    </p>
                                    <ul className='bullet'>
                                        <li>
                                        kopije ponudbe za sklenitev zdravstvenega zavarovanja PRVA Diagnoza,
                                        </li>
                                        <li>
                                        Splošni pogoji PRVA_02PZ08/23
                                        </li>
                                        <li>
                                        Posebni pogoji PRVA_02PZ01E_S08/23
                                        </li>
                                        <li>
                                        Posebni pogoji PRVA_02PZ01_Z08/23
                                        </li>
                                        <li>
                                        Seznam specialističnih storitev PRVA_02PZ01E_S03_22
                                        </li>
                                        <li>
                                        IPID-PD-03_2022
                                        </li>
                                        <li>
                                        IPID-PDZ-03_2022
                                        </li>
                                    </ul>
                                </div>
                                : ''
                            }
                            {
                                izbiraPaketaModel.paket.value === 'BrezNapotnice+Rehabilitacije+OperativniPosegiZV10k' ?
                                <div
                                    className='col s12'
                                >
                                    <p>
                                    Potrjujem prejem pripadajočih dokumentov paketa: PRVA Diagnoza brez napotnice + zdravila + rehabilitacija in operativni posegi 10.000 EUR
                                    </p>
                                    <ul className='bullet'>
                                        <li>
                                            kopije ponudbe za sklenitev zdravstvenega zavarovanja PRVA Diagnoza
                                        </li>
                                        <li>
                                            Splošni pogoji PRVA_02PZ08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01E_S08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_Z08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_OP08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_R08/23
                                        </li>
                                        <li>
                                            Seznam specialističnih storitev PRVA_02PZ01E_S03_22
                                        </li>
                                        <li>
                                            Seznam operativnih posegov PRVA_02PZ01E_Op08/23
                                        </li>
                                        <li>
                                            Seznam rehabilitacijskih obravnav in postopkov
                                        </li>
                                        <li>
                                            IPID-PD-03_2022
                                        </li>
                                        <li>
                                            IPID-PDZ-03_2022
                                        </li>
                                        <li>
                                            IPID-PDOp-03_2022
                                        </li>
                                        <li>
                                            IPID-PDR-03_2022
                                        </li>
                                    </ul>
                                </div>
                                : ''
                            }
                            {
                                izbiraPaketaModel.paket.value === 'BrezNapotnice+Rehabilitacije+OperativniPosegiZV40k' ?
                                <div
                                    className='col s12'
                                >
                                    <p>
                                    Potrjujem prejem pripadajočih dokumentov paketa: PRVA Diagnoza brez napotnice + zdravila + rehabilitacija in operativni posegi 40.000 EUR
                                    </p>
                                    <ul className='bullet'>
                                        <li>
                                            kopije ponudbe za sklenitev zdravstvenega zavarovanja PRVA Diagnoza
                                        </li>
                                        <li>
                                            Splošni pogoji PRVA_02PZ08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01E_S08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_Z08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_OP08/23
                                        </li>
                                        <li>
                                            Posebni pogoji PRVA_02PZ01_R08/23
                                        </li>
                                        <li>
                                            Seznam specialističnih storitev PRVA_02PZ01E_S03_22
                                        </li>
                                        <li>
                                            Seznam operativnih posegov PRVA_02PZ01E_Op08/23
                                        </li>
                                        <li>
                                            Seznam rehabilitacijskih obravnav in postopkov
                                        </li>
                                        <li>
                                            IPID-PD-03_2022
                                        </li>
                                        <li>
                                            IPID-PDZ-03_2022
                                        </li>
                                        <li>
                                            IPID-PDOp-03_2022
                                        </li>
                                        <li>
                                            IPID-PDR-03_2022
                                        </li>
                                    </ul>
                                </div>
                                : ''
                            }
                            <div
                                className='col s12'
                            >
                                <CheckboxFieldRequiredChecked
                                    isRequired={model.potrjujemPrejemDokumentov.required}
                                    isReadOnly={model.potrjujemPrejemDokumentov.readonly}
                                    onChange={value => model.potrjujemPrejemDokumentov.value = value}
                                    name={model.potrjujemPrejemDokumentov.name}
                                    questionText={model.potrjujemPrejemDokumentov.text.sl}
                                    error={model.potrjujemPrejemDokumentov.error}
                                    validateFn={validateCheckboxRequiredChecked.bind(null, { required: model.potrjujemPrejemDokumentov.required })}
                                    value={model.potrjujemPrejemDokumentov.value}
                                />
                            </div>
                            <div
                                className='col s12'
                            >
                                <PodpisField1
                                    isRequired={model.podpisSvetovalec.required}
                                    onChange={value => model.podpisSvetovalec.value = value}
                                    name={model.podpisSvetovalec.name}
                                    questionText={model.podpisSvetovalec.text.sl}
                                    error={model.podpisSvetovalec.error}
                                    value={model.podpisSvetovalec.value}
                                />
                            </div>
                            <div
                                className='col s12'
                            >
                                <PodpisField1
                                    isRequired={model.podpisZavarovanec.required}
                                    onChange={value => model.podpisZavarovanec.value = value}
                                    name={model.podpisZavarovanec.name}
                                    questionText={model.podpisZavarovanec.text.sl}
                                    error={model.podpisZavarovanec.error}
                                    value={model.podpisZavarovanec.value}
                                />
                            </div>
                            <div
                                className='col s12'
                            >
                                <PodpisField1
                                    isRequired={model.podpisZavarovalec.required}
                                    onChange={value => model.podpisZavarovalec.value = value}
                                    name={model.podpisZavarovalec.name}
                                    questionText={model.podpisZavarovalec.text.sl}
                                    error={model.podpisZavarovalec.error}
                                    value={model.podpisZavarovalec.value}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
                : <MessageBox
                    category="info" 
                    content="Najprej izberite paket"
                ></MessageBox>

            }
        </>

    );

}


