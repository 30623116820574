import { React, useState, useEffect, useRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';

export default function OsebniDokumentIzdajateljField(props) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const selectInput = useRef(null);

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    useEffect(() => {

        let fieldName = fidPrefix + "izdajatelj";
        const dummyEvent = {
            target: {
                name: fieldName,
                value: selectInput.current.value
            }
        };

        props.onChange(dummyEvent);
    }, []);

    return (
        <div className="input-field col s12">
            <select ref={selectInput} id={fidPrefix + "izdajatelj"} name={fidPrefix + "izdajatelj"} onChange={handleChange} required={true} className="validate">
                <option value='UE AJDOVŠČINA'>UE Ajdovščina</option>
                <option value='UE BREŽICE'>UE Brežice</option>
                <option value='UE CELJE'>UE Celje</option>
                <option value='UE CERKNICA'>UE Cerknica</option>
                <option value='UE ČRNOMELJ'>UE Črnomelj</option>
                <option value='UE DOMŽALE'>UE Domžale</option>
                <option value='UE DRAVOGRAD'>UE Dravograd</option>
                <option value='UE GORNJA RADGONA'>UE Gornja Radgona</option>
                <option value='UE GROSUPLJE'>UE Grosuplje</option>
                <option value='UE HRASTNIK'>UE Hrastnik</option>
                <option value='UE IDRIJA'>UE Idrija</option>
                <option value='UE ILIRSKA BISTRICA'>UE Ilirska Bistrica</option>
                <option value='UE IZOLA'>UE Izola</option>
                <option value='UE JESENICE'>UE Jesenice</option>
                <option value='UE KAMNIK'>UE Kamnik</option>
                <option value='UE KOČEVJE'>UE Kočevje</option>
                <option value='UE KRANJ'>UE Kranj</option>
                <option value='UE KOPER'>UE Koper</option>
                <option value='UE KRŠKO'>UE Krško</option>
                <option value='UE LAŠKO'>UE Laško</option>
                <option value='UE LENART'>UE Lenart</option>
                <option value='UE LENDAVA'>UE Lendava</option>
                <option value='UE LITIJA'>UE Litija</option>
                <option value='UE LJUBLJANA'>UE Ljubljana</option>
                <option value='UE LJUTOMER'>UE Ljutomer</option>
                <option value='UE LOGATEC'>UE Logatec</option>
                <option value='UE MARIBOR'>UE Maribor</option>
                <option value='UE METLIKA'>UE Metlika</option>
                <option value='UE MOZIRJE'>UE Mozirje</option>
                <option value='UE MURSKA SOBOTA'>UE Murska Sobota</option>
                <option value='UE NOVA GORICA'>UE Nova Gorica</option>
                <option value='UE NOVO MESTO'>UE Novo mesto</option>
                <option value='UE ORMOŽ'>UE Ormož</option>
                <option value='UE PESNICA'>UE Pesnica</option>
                <option value='UE PIRAN'>UE Piran</option>
                <option value='UE POSTOJNA'>UE Postojna</option>
                <option value='UE PTUJ'>UE Ptuj</option>
                <option value='UE RADLJE OB DRAVI'>UE Radlje ob Dravi</option>
                <option value='UE RADOVLJICA'>UE Radovljica</option>
                <option value='UE RAVNE NA KOROŠKEM'>UE Ravne na Koroškem</option>
                <option value='UE RIBNICA'>UE Ribnica</option>
                <option value='UE RUŠE'>UE Ruše</option>
                <option value='UE SEVNICA'>UE Sevnica</option>
                <option value='UE SEŽANA'>UE Sežana</option>
                <option value='UE SLOVENJ GRADEC'>UE Slovenj Gradec</option>
                <option value='UE SLOVENSKA BISTRICA'>UE Slovenska Bistrica</option>
                <option value='UE SLOVENSKE KONJICE'>UE Slovenske Konjice</option>
                <option value='UE ŠENTJUR PRI CELJU'>UE Šentjur pri Celju</option>
                <option value='UE ŠKOFJA LOKA'>UE Škofja Loka</option>
                <option value='UE ŠMARJE PRI JELŠAH'>UE Šmarje pri Jelšah</option>
                <option value='UE TOLMIN'>UE Tolmin</option>
                <option value='UE TRBOVLJE'>UE Trbovlje</option>
                <option value='UE TREBNJE'>UE Trebnje</option>
                <option value='UE TRŽIČ'>UE Tržič</option>
                <option value='UE VELENJE'>UE Velenje</option>
                <option value='UE VRHNIKA'>UE Vrhnika</option>
                <option value='UE ZAGORJE OB SAVI'>UE Zagorje ob Savi</option>
                <option value='UE ŽALEC'>UE Žalec</option>
            </select>
            <label>Izdajatelj osebnega dokumenta</label>
            <span className="helper-text" data-error={t('validation:field.validate.osebniDokumentIdRequired')} data-success=""></span>
        </div>


    )
}   