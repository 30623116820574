import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import DomovRoute from './route/DomovRoute';
import SklepanjeVstopSvetovalecRoute from './route/SklepanjeVstopSvetovalecRoute';
import SklepanjeVstopRoute from './route/SklepanjeVstopRoute';
import P404Route from './route/P404Route';
import IzbiraPotiRoute from './route/IzbiraPotiRoute';
import TySklepanjeVstopSvetovalecRoute from './route/TySklepanjeVstopSvetovalecRoute';
import TySklepanjeVstopRoute from './route/TySklepanjeVstopRoute';
import InvalidAgentLinkRoute from './route/InvalidAgentLinkRoute';
import AllianzNezgodaRoute from './route/AllianzNezgodaRoute';
import DrBestEurekoRoute from './route/DrBestEurekoRoute';
import TySklenjenoZavarovanjeRoute from './route/TySklenjenoZavarovanjeRoute';
import TySklenjenoZavarovanjeDrBestEurekoRoute from './route/TySklenjenoZavarovanjeDrBestEurekoRoute';
import IzbiraSamostojenBdcaRoute from './route/IzbiraSamostojenBdcaRoute';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import PrvaDiagnozaRoute from './route/PrvaDiagnozaRoute';
import MedicareIzbiraProduktaRoute from './route/MedicareIzbiraProduktaRoute';
import TySklenjenoZavarovanjePrvaDiagnozaRoute from './route/TySklenjenoZavarovanjePrvaDiagnozaRoute';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
};

if (String(process.env.REACT_APP_GTM_ID).length > 0) {
  console.log("init. GTM ", process.env.REACT_APP_GTM_ID);
  TagManager.initialize(tagManagerArgs);
}
else {
  console.log("not initializrd GTM");
}


ReactDOM.render(
  <React.StrictMode>
  
      <BrowserRouter>
        <Suspense fallback="loading">
          <Routes>
            <Route path="/" element={ <App /> }>
              <Route path="/" element={<DomovRoute />} > </Route>
              <Route path="izbira-poti" element={<IzbiraPotiRoute /> } ></Route>
              <Route path="izbira-paketa" element={<IzbiraSamostojenBdcaRoute />}></Route>
              <Route path="skleni" element={<DrBestEurekoRoute />} />
              <Route path="vstop" element={<SklepanjeVstopRoute /> }></Route>
              <Route path="vstop-s-svetovalcem" element={ <SklepanjeVstopSvetovalecRoute /> }></Route>
              <Route path="zahtevek-za-sklepanje" element={ <TySklepanjeVstopRoute /> }></Route>
              <Route path="zahtevek-za-sklepanje-2" element={ <TySklepanjeVstopSvetovalecRoute /> }></Route>
              <Route path="neveljavna-povezava" element={<InvalidAgentLinkRoute /> }></Route>
              <Route path="skljeno-zavarovanje" element={<TySklenjenoZavarovanjeRoute /> }></Route>
              <Route path="sklenjeno-zavarovanje-prvadiagnoza" element={<TySklenjenoZavarovanjePrvaDiagnozaRoute /> }></Route>
              <Route path="skljeno-zavarovanje-eureko" element={<TySklenjenoZavarovanjeDrBestEurekoRoute /> }></Route>
              <Route path="*" element={ <P404Route />} ></Route>
              <Route path="medicare-izbira-produkta" element={<MedicareIzbiraProduktaRoute />} ></Route>
              <Route path="produkti">
                <Route path="allianz-nezgoda" element={<AllianzNezgodaRoute />}></Route>
                <Route path="dr-best-eureko" element={<DrBestEurekoRoute />} />
                <Route path="prva-diagnoza" element={<PrvaDiagnozaRoute />} />
              </Route>
              
            </Route>
            
          </Routes>
        </Suspense>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
