import { React, useState, useEffect, useRef, forwardRef } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';


function ImeBankeField(props, ref) {
    const [fidPrefix, setFidPrefix] = useState(typeof (props.prefix) == 'string' ? props.prefix : '');
    const isRequired = (typeof (props.isRequired) === 'undefined' || props.isRequired === false) ? false : true;

    const { t, i18n } = useTranslation(['validation', 'flashes']);

    const handleChange = (event) => {
        props.onChange(event);
    };

    return (
        <div className="input-field col s12">
            <input ref={ref} 
                id={fidPrefix + "nazivBanke"} 
                type="text" name={fidPrefix + "nazivBanke"} 
                className="validate" onChange={handleChange} 
                required={isRequired}
            />
            <label htmlFor={fidPrefix + "nazivBanke"}><Trans>field.label.bankName</Trans></label>
            <span className="helper-text" data-error={t('validation:field.validate.bankNameRequired')} data-success=""></span>
        </div>
    );
}

export default forwardRef(ImeBankeField);

