import { React, useState, useEffect, useRef } from 'react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation, } from "react-router-dom";
import { useDispatch, useStore, useSelector } from 'react-redux';
import M from '@materializecss/materialize';
import ImeField from '../field/ImeField';
import EmailField from '../field/EmailField';
import MobitelField from '../field/MobitelField';
import FormSubmitField from '../field/FormSubmitField';
import AgreeField from '../field/AgreeField';
import HiddenField from '../field/HiddenField';
import PodpisField from '../field/PodpisField';
import FormStepButtonField from '../field/FormStepButtonField';
import NaslovField from '../field/NaslovField';
import SpolField from '../field/SpolField';
import DatumRojstvaField from '../field/DatumRojstvaField';
import DavcnaStevilkaField from '../field/DavcnaStevilkaField';
import DrzavaRojstvaTextField from '../field/DrzavaRojstvaTextField';
import PoklicField from '../field/PoklicField';
import DediscinaField from '../field/DediscinaField';
import DediscinaDrugeOsebeField from '../field/DediscinaDrugeOsebeField';
import PaketField from '../field/PaketField';
import FrekvencaField from '../field/FrekvencaField';
import CenaField from '../field/CenaField';
import IbanField from '../field/IbanField';
import ImeBankeField from '../field/ImeBankeField';
import DaNeField from '../field/DaNeField';
import PrenosPodatkovField from '../field/PrenosPodatkovField';
import KrajPodpisaField from '../field/KrajPodpisaField';
import PredogledArea from '../other/PredogledArea';
import ItemPonudbaService from '../service/ItemPonudbaService';
import { getActionType, parseFieldName, inputValueToUpperCase, formatDecimal } from '../redux/common';
import { ZAVAROVANEC_PREMIJA_UPDATED } from '../redux/types/zavarovanaOsebaType';
import AllianzNezgodaTable from '../app.medicalcare/AllianzNezgodaTable';


export default function AllianzNezgodaForm(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const selectIsKanalRequired = useSelector((state) => {
        if (typeof (state.privolitvenaEureko) === 'undefined') {
            return false;
        }
        else if (typeof (state.privolitvenaEureko.privolitev) === 'undefined') {
            return false;
        }

        const gdpr = state.privolitvenaEureko.privolitev.q31 ?? 'N';
        const trzenje = state.privolitvenaEureko.privolitev.q32 ?? 'N';
        const komuniciranje = state.privolitvenaEureko.privolitev.q33 ?? {};

        // Nothing else to check.
        if (trzenje === 'N' && gdpr === 'N') {
            return false;
        }

        if (komuniciranje.klicPoTelefonu) {
            return false;
        }

        if (komuniciranje.posta) {
            return false;
        }

        if (komuniciranje.elektronskaPosta) {
            return false;
        }

        if (komuniciranje.SMS) {
            return false;
        }

        if (komuniciranje.drugePoti) {
            return false;
        }

        return true;
    });

    const [status, setStatus] = useState('');
    const [otpId, setOtpId] = useState(null);
    const [pdfContent, setPdfContent] = useState(null);
    const [signedPdfContent, setSignedPdfContent] = useState(null);

    // We only need to use this state var on instances where <PrenosPodaktov> component needs to initially 
    // fill out the fields. We do this so we can trigger "re-copy" of data.
    const [kopirajPodatke, setKopirajPodatke] = useState(true);

    // Form steps.
    const formStepsEl = useRef(null);
    const [allSteps, setAllSteps] = useState([
        {
            step: 1,
            tab: 'idd2'
        },
        {
            step: 2,
            tab: 'zavarovalec-data'
        },
        {
            step: 3,
            tab: 'zavarovanec-data'
        },
        {
            step: 4,
            tab: 'paket'
        },
        {
            step: 5,
            tab: 'sepa'
        },
        {
            step: 6,
            tab: 'izjava'
        },
        {
            step: 7,
            tab: 'gdpr'
        },
        {
            step: 8,
            tab: 'form-final'
        }
    ]);
    const [currentStep, setCurrentStep] = useState(1);

    // Refs.
    const imeBankeInput = useRef(null);

    const agentParams = useSelector(state => state.meta.agentParams);
    const jobSessionId = useSelector(state => state.meta.jobSessionId); 

    const krajPodpisa = useSelector((state) => {
        if (typeof (state.meta) === 'undefined') {
            return '';
        }
        else if (typeof (state.meta.krajPodpisa) === 'undefined') {
            return '';
        }
        
        return state.meta.krajPodpisa;
    });

    const premijaBruto = useSelector((state) => {
        if (typeof(state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.zavarovanaOseba) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.zavarovanaOseba.premija) === 'undefined') {
            return '';
        }

        return formatDecimal(state.produkt.zavarovanaOseba.premija.bruto);
    });

    const frekvencaPlacila = useSelector((state) => {
        if (typeof (state.produkt) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.nacinPlacila) === 'undefined') {
            return '';
        }
        else if (typeof(state.produkt.nacinPlacila.frekvenca) === 'undefined') {
            return '';
        }

        return state.produkt.nacinPlacila.frekvenca;
    });

    const paket = useSelector((state) => {
        if (typeof (state.produkt) === 'undefined') {
            return null;
        }
        else if (typeof(state.produkt.zavarovanaOseba) === 'undefined') {
            return null;
        }
        else if (typeof(state.produkt.zavarovanaOseba.paket) === 'undefined') {
            return null;
        }

        return state.produkt.zavarovanaOseba.paket;
    });

    const { t, i18n } = useTranslation(['translation', 'validation', 'flashes', 'backend']);

    const handleFieldChange = (event, valueToUpperCase=true) => {

        // Find prefix.
        let fieldInfo = parseFieldName(event.target.name);
        let type = '';
        if (fieldInfo.prefix.length) {
            
            //type = prefix + '/updated';
            type = getActionType(fieldInfo.prefix, 'UPDATED');
            let normalizedValue = event.target.value;
            if (valueToUpperCase) {
                normalizedValue = inputValueToUpperCase(normalizedValue);
            }
            
            dispatch({
                type: type,
                payload: {
                    field: event.target.name,
                    value: normalizedValue
                }
            });
        }

    };

    const handleFieldChangeRaw = (event) => {
        handleFieldChange(event, false);
    }

    const handleFieldChangePrenosPodatkov = (event) => {
        handleFieldChange(event, false);
        setKopirajPodatke(event.target.value);
    }

    const handlePodpisFieldChange = (fieldName, canvasAsString) => {
       /**
         * @todo How to do this better?
         */
        // We do this so we do not have to duplicate the logic from handleField change.
        const dummyEvent = {
            target: {
                name: fieldName,
                value: canvasAsString
            }
        };

        handleFieldChange(dummyEvent, false);
    };

    const allianzNezgodaPremijaCalc = (paket, frekvencaPlacila) => {
        let premijaValues = {
            neto: null,
            davek: null,
            bruto: null
        };
        switch (paket) {
            case 'A':
                if (frekvencaPlacila === 'MESECNO') {
                    premijaValues = {
                        neto: 10.55,
                        davek: 0.9,
                        bruto: 11.45
                    };
                }
                else {
                    premijaValues = {
                        neto: 126.60,
                        davek: 10.76,
                        bruto: 137.36
                    };
                }
                break;

            case 'B':
                if (frekvencaPlacila === 'MESECNO') {
                    premijaValues = {
                        neto: 10.42,
                        davek: 0.88,
                        bruto: 11.30
                    };
                }
                else {
                    premijaValues = {
                        neto: 125.00,
                        davek: 10.63,
                        bruto: 135.63
                    };
                }
                break;

            case 'C':
                if (frekvencaPlacila === 'MESECNO') {
                    premijaValues = {
                        neto: 15.55,
                        davek: 1.32,
                        bruto: 16.87
                    };
                }
                else {
                    premijaValues = {
                        neto: 186.60,
                        davek: 15.86,
                        bruto: 202.46
                    };
                }
                break;

            default:
                break;
        }

        dispatch({
            type: ZAVAROVANEC_PREMIJA_UPDATED,
            payload: {
                field: 'neto',
                value: premijaValues.neto
            }
        });

        dispatch({
            type: ZAVAROVANEC_PREMIJA_UPDATED,
            payload: {
                field: 'davek',
                value: premijaValues.davek
            }
        });

        dispatch({
            type: ZAVAROVANEC_PREMIJA_UPDATED,
            payload: {
                field: 'bruto',
                value: premijaValues.bruto
            }
        });
    };

    /**
     * Handle Preview Click
     * 
     *  @deprecated This handler does not accpet event but just requestId which is not used anymore.
     *
     * @param {requestId} requestId 
     * @returns 
     */
    const handlePreviewClick = (requestId) => {
        if (!validateForm()) {
            M.toast({ html: t('validation:form.validate.preSubmit.failed') });
            // event.preventDefault();
            // setSignedPdfContent(null);
            return;
        }

        let requestBody = buildRequest('preview');
        let itemPobnudbaService = new ItemPonudbaService();
        itemPobnudbaService.sendItemPonudba(requestBody)
            .then((result) => {
                if (result.success) {
                    M.toast({ html: t("flashes:submission.preview.successful") });

                    setSignedPdfContent(result.data.pdf.content);
                }
                else {
                    M.toast({ html: t(result.message) });
                }
            })
            .catch(error => {
                M.toast({ html: t("flashes:form.error.preview") });
            })
            .then((result) => {
            });


    };

    const buildRequest = (requestId) => {
        let requestBody = {
            form: {
                type: 'AllianzNezgodaPoSvetovanjuForm',
                action: 'preview',
            },
            ponudba: {
                type: 'EurekoPonudba',
                data: store.getState()
            }
        };
        
        if (requestId === 'submit') {
            requestBody.form.action = 'submit';
        }

        return requestBody;
    };

    const validateFormSignatures = () => {
        let elements = document.querySelectorAll('input.eureko-signature'), el = null;
        let i = 0, isValid = true, gIsValid = true, clInvalidClass = 'eureko-signature-1-invalid', contains = null;

        
        while (i < elements.length) {
            
            el = elements[i].closest('div.eureko-signature');
            contains = el.classList.contains(clInvalidClass);
            
            
            isValid = elements[i].value === '1';
            if (gIsValid && !isValid) {
                // At least one field failed, entire form validation failed.
                gIsValid = false;
            }

            if (isValid && contains) {
                el.classList.remove(clInvalidClass);
            }
            else if (!isValid && !contains) {
                el.classList.add(clInvalidClass);
            }
            
            i++;
        }

        return gIsValid;
    };

    const validateFormInputs = () => {

        let customValidators = ['davcnaStevilka', 'IBAN', 'nazivBanke'];

        let elements = null, cssSelector, i = 0, j = 0;
        let clNameAdd, clNameRemove, formValid = true;

        /**
         * @todo improve
         */
        let tabValidClasses = 'blue-grey', tabInvalidClasses = 'red';
        let tabs = document.querySelectorAll('#form-steps li'), tabValid;
        
        while (j < allSteps.length) {
            cssSelector = '#' + allSteps[j].tab + ' input';
            elements = document.querySelectorAll(cssSelector);
            tabValid = true
            i = 0;

            while (i < elements.length) {

                clNameAdd = 'valid';
                clNameRemove = 'invalid';

                // We do not know how to trigger validators on fields with custom validation logic.
                // so for now we use this.
                if (customValidators.some((v) => elements[i].name.indexOf(v) !== -1) && elements[i].classList.contains('invalid')) {
                    
                    // Field is already marked as invalid.
                    tabValid = false;
                    formValid = false;
                    i++;
                    continue;
                }
                else if (!elements[i].checkValidity()) {
                    clNameAdd = 'invalid';
                    clNameRemove = 'valid';
                    tabValid = false;
                    formValid = false;
                }

                if (!elements[i].classList.contains(clNameAdd)) {
                    elements[i].classList.add(clNameAdd);
                }

                if (elements[i].classList.contains(clNameRemove)) {
                    elements[i].classList.remove(clNameRemove);
                }

                switch (elements[i].type) {
                    case 'radio':
                    case 'checkbox':
                        let parent = elements[i].parentElement, cl = 'eureko-input-boolean-required';
                        if (!elements[i].checkValidity()) {
                            parent.classList.add(cl);
                        }
                        else {
                            parent.classList.remove(cl);
                        }
                        break;

                    default:
                        break;
                }

                i++;
            }

            // Mark tab if validation error found.
            if (!tabValid) {
                tabs[j].classList.remove(tabValidClasses);
                
                if (!tabs[j].classList.contains(tabInvalidClasses)) {
                    tabs[j].classList.add(tabInvalidClasses);
                }
            }
            else {
                // valid tab
                tabs[j].classList.remove(tabInvalidClasses);
                if (!tabs[j].classList.contains(tabValidClasses)) {
                    tabs[j].classList.add(tabValidClasses);
                }
            }

            j++;
        }
        return formValid;
    };

    const validatePrivolitev = () => {
        const storeData = store.getState();
        const gdpr = storeData.privolitvenaEureko.privolitev.q31;
        const trzenje = storeData.privolitvenaEureko.privolitev.q32;
        const komuniciranje = storeData.privolitvenaEureko.privolitev.q33 ?? {};

        // Nothing else to check.
        if (trzenje === 'N' && gdpr === 'N') {
            return true;
        }

        if (komuniciranje.klicPoTelefonu) {
            return true;
        }

        if (komuniciranje.posta) {
            return true;
        }

        if (komuniciranje.elektronskaPosta) {
            return true;
        }

        if (komuniciranje.SMS) {
            return true;
        }

        if (komuniciranje.drugePoti) {
            return true
        }


        return false;
    };

    const validateForm = () => {
        let sig = validateFormSignatures(), inp = validateFormInputs();
        let privolitevValidated = false;

        if (sig && inp) {
            privolitevValidated = validatePrivolitev();
            if (!privolitevValidated) {
                M.toast({ html: t("validation:form.validate.eurekoPrivolitev") });
            }
        }

        return privolitevValidated;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStatus('SUBMITTING');
        if (!validateForm()) {
            setStatus('');
            M.toast({ html: t("validation:form.validate.preSubmit.failed") });
            return;
        }

        let requestBody = buildRequest('submit');
        let itemPobnudbaService = new ItemPonudbaService();
        itemPobnudbaService.sendItemPonudba(requestBody)
            .then((result) => {
                if (result.success) {
                    M.toast({ html: t("flashes:submission.successful") });
                    setStatus('SUBMITTED');

                }
                else {
                    setStatus('');
                    M.toast({ html: t("flashes:submission.error") });
                }
            })
            .then((result) => {
                
            })
            .catch(error => {
                setStatus('');
                M.toast({ html: "Another error" });
            });

    };

    const reInitPrenosPodatkov = () => {

        // Copy zavarovalec data every time we switch tabs or pages.
        if (kopirajPodatke) {
            setKopirajPodatke(false);
            setKopirajPodatke(true);
        }
    }

    // form-steps actions
    const handleStepPreviousClick = (event) => {

        console.debug('previous click');
        let cs = currentStep;
        if (cs > 1) {
            cs--;
            setCurrentStep(cs);
            let index = cs - 1;
            var instance = M.Tabs.getInstance(document.getElementById('form-steps'));
            instance.select(allSteps[index].tab);
            window.scroll(0, 0);
        }

        reInitPrenosPodatkov();
    };

    const handleStepNextClick = (event) => {
        console.debug('next click');
        let cs = currentStep;
        if (cs < allSteps.length) {
            cs++;
            setCurrentStep(cs);
            let index = cs - 1;
            console.debug(allSteps[index].tab);
            var instance = M.Tabs.getInstance(document.getElementById('form-steps'));
            instance.select(allSteps[index].tab);
            window.scroll(0, 0);
        }

        reInitPrenosPodatkov();

        // Uncomment this during dev. when necessary.
        //handleSubmit('submit')
    };

    const handleStepTabClick = (event) => {
        let tabStringId = event.target.getAttribute('href').slice(1), i = 0;

        while (i < allSteps.length && allSteps[i].tab !== tabStringId) {
            i++;
        }

        if (i < allSteps.length) {
            setCurrentStep(allSteps[i].step);
        }

        reInitPrenosPodatkov();
    }

    useEffect(() => {
        M.AutoInit();
        M.Tabs.init(document.getElementById('form-steps'), {
            swipeable: false
        });
        window.scroll(0, 0);

    }, []);

    // On successful submission redirect to ty page.
    useEffect(() => {
        if (status === 'SUBMITTED') {
            navigate('/skljeno-zavarovanje');
        }
    }, [status, navigate]);

    useEffect(() => {
        allianzNezgodaPremijaCalc(paket, frekvencaPlacila);
    }, [paket, frekvencaPlacila]);

    return (

            <form onSubmit={handleSubmit} noValidate={true}>
                <div className="row">
                    <div className="col s12">
                        <ul ref={formStepsEl} className="tabs tabs-fixed-width" id="form-steps">
                        <li className="tab blue-grey darken-1">
                                <a className="white-text" href="#idd2" onClick={handleStepTabClick}>Opredelitev potreb in zahtev</a>
                            </li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#zavarovalec-data" onClick={handleStepTabClick}>Zavarovalec</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#zavarovanec-data" onClick={handleStepTabClick}>Zavarovanec</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#paket" onClick={handleStepTabClick}>Paket in premija</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#sepa" onClick={handleStepTabClick}>SEPA</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#izjava" onClick={handleStepTabClick}>Izjava</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#gdpr" onClick={handleStepTabClick}>EUREKO - GDPR</a></li>
                            <li className="tab blue-grey darken-1"><a className="white-text" href="#form-final" onClick={handleStepTabClick}>Oddaja</a></li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div id="idd2" className="col s12">
                        <p>
                            Da bi lahko vaš distributer skladno z določili Zakona o zavarovalništvu med prodajnim razgovorom ugotovil, ali izbrani produkt ustreza vašim zahtevam in potrebam, potrebuje ustrezne informacije in vaše osebne podatke. Odgovori na spodnja vprašanja izhajajo iz opravljenega prodajnega razgovora, na njihovi podlagi pa lahko preverite, ali je izbrani produkt pravi za vas.
                        </p>
                        <fieldset>
                        
                            <legend>Vprašalnik</legend>
                            
                            <DaNeField prefix="idd2_" 
                                stringId="q1" 
                                question="Ali oseba, ki jo želite zavarovati, spada med naslednje: državljan Republike Slovenije ali tujec, ki ima v RS status rezidenta, v RS biva na podlagi delovnega vizuma ali ima tukaj stalno prebivališče?" 
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q2"
                                question="Ali menite, da bi v primeru smrti zavarovanca zaradi nezgode upravičenci iz zavarovanja potrebovali dodatno finančno podporo?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q3"
                                question="Ali menite, da bi v primeru smrti zavarovanca zaradi prometne nesreče upravičenci iz zavarovanja potrebovali dodatno finančno podporo?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q4"
                                question="Ali menite, da bi v primeru smrti zavarovanca zaradi bolezni upravičenci iz zavarovanja potrebovali dodatno finančno podporo?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q5"
                                question="Ali želite dodatno finančno podporo v primeru trajne invalidnosti zavarovanca?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q6"
                                question="Če ste vi (ali zavarovanec) stalno zaposleni za določen ali nedoločen čas, imate možnost izbrati dodatno finančno podporo v primeru začasne nezmožnosti za delo. Bi želeli tako kritje?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q7"
                                question="Ali želite dnevno nadomestilo v primeru bivanja v bolnišnici zaradi zdravljenja zavarovanca zaradi nesreče?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q8"
                                question="Ali želite dodatno finančno podporo v primeru zloma kosti?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <DaNeField prefix="idd2_"
                                stringId="q9"
                                question="Ali menite, da bi v primeru hude bolezni zavarovanec potreboval finančno podporo?"
                                onChange={handleFieldChange}>
                            </DaNeField>

                            <p>
                                S podpisom potrjujem, da sem zavarovalnemu distributerju posredoval/a informacije, na podlagi katerih so mi bile opredeljene zahteve in potrebe. Odločitev o sklenitvi zavarovanja sem sprejel/a na podlagi prejetih pojasnil in prejetih objektivnih informacij o zavarovalnem produktu.<br /><br />
                                Od distributerja nisem prejel/a nasvetov ali priporočil. Odločitev o sklenitvi zavarovalne pogodbe sem sprejel/-a sam/a, na podlagi prejetih objektivnih informacij o zavarovalnem produktu.
                            </p>
                            <PodpisField signatureStatus="CLOSED"  prefix="idd2_" signatureFor="agent" updateFormDataField="podpisSvetovalec" onPodpisFieldChange={handlePodpisFieldChange} />

                            <p>
                            Zavarovalec potrjujem, da so mi bile pred sklenitvijo zavarovanja: individualno nezgodno zavarovanje, opredeljene potrebe in zahteve ter da sem prejel/a naslednjo predpogodbeno dokumentacijo: IZJAVA ZAVAROVALNEGA DISTRIBUTERJA.
                            </p>
                            <KrajPodpisaField prefix="meta_" onChange={handleFieldChange} />
                            <PodpisField signatureStatus="CLOSED"  prefix="idd2_" signatureFor="customer" updateFormDataField="podpisZavarovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                            <HiddenField prefix="idd2_" name="krajPodpisa" onChange={handleFieldChange} value={krajPodpisa} />

                            <div className="col s12">
                                <p>
                                    <b>Izjava zavarovalca o prejeti predpogodbeni dokumentaciji</b><br /><br />
                                    Zavarovalec potrjujem, da so mi bile pred sklenitvijo zavarovanja: individualno nezgodno zavarovanje, opredeljene potrebe in zahteve ter da sem prejel/a naslednjo predpogodbeno dokumentacijo
                                </p>
                                <AgreeField prefix="meta_" name="prejetaPredpogodbenaDokumentacija" onChange={handleFieldChangeRaw}
                                    isRequired={true}
                                    text="izjavo zavarovalnega distributerja (po 545. členu ZZavar-1)"
                                />
                            </div>
                        </fieldset>
                        
                    </div>

                    <div id="zavarovalec-data" className="col s12">

                        <HiddenField name="agentParams" onChange={handleFieldChangeRaw} prefix="meta_" value={agentParams} />
                        <HiddenField name="jobSessionId" onChange={handleFieldChangeRaw} prefix="meta_" value={jobSessionId} />
                        <fieldset>
                            <legend>Osnovni podatki</legend>
                            <div className="row">
                            <ImeField prefix="zavarovalec_" onImeChange={handleFieldChange} 
                                onPriimekChange={handleFieldChange} 
                                isReadOnly={true}
                            />
                            </div>
                            <div className="row">
                                <EmailField prefix="zavarovalec_" isRequired={true} isReadOnly={true} 
                                    onChange={handleFieldChange} 
                                />
                                <MobitelField prefix="zavarovalec_" isRequired={true} isReadOnly={true}
                                    onChange={handleFieldChange} 
                                />
                            </div>
                            <div className="row">
                                <SpolField prefix="zavarovalec_" onChange={handleFieldChange} />
                            </div>
                            <DatumRojstvaField prefix="zavarovalec_" onChange={handleFieldChange} conf={{deltaMin: 18, deltaMax: null}} />
                            <DrzavaRojstvaTextField prefix="zavarovalec_"  onChange={handleFieldChange} />
                            <DavcnaStevilkaField prefix="zavarovalec_" onChange={handleFieldChange} />
                        </fieldset>
                        <fieldset>
                            <legend>Naslov prebivališča</legend>
                            <NaslovField prefix="zavarovalec_naslov_" onChange={handleFieldChange} />
                        </fieldset>
                        
                        
                    </div>

                    <div id="zavarovanec-data" className="col s12">
                    <PrenosPodatkovField 
                        prefix="meta_" 
                        name="zavarovalecZavarovan" 
                        kopirajPodatke={false} 
                        onChange={handleFieldChangeRaw} 
                        texts={{
                            'legendText': "Zavarovalec želi zavarovati",
                            'neKopirajText': "drugo osebo",
                            'kopirajText': "sebe"
                        }}

                        mapFields={[
                            {
                                src: 'zavarovalec_ime',
                                dst: 'zavarovanec_ime'
                            },
                            {
                                src: 'zavarovalec_priimek',
                                dst: 'zavarovanec_priimek'
                            },
                            {
                                src: 'zavarovalec_email',
                                dst: 'zavarovanec_email'
                            },
                            {
                                src: 'zavarovalec_mobitel',
                                dst: 'zavarovanec_mobitel'
                            },
                            {
                                src: 'zavarovalec_spol',
                                dst: 'zavarovanec_spol',
                                fun: 'name'
                            },
                            {
                                src: 'zavarovalec_datumRojstva',
                                dst: 'zavarovanec_datumRojstva'
                            },
                            {
                                src: 'zavarovalec_drzavaRojstva',
                                dst: 'zavarovanec_drzavaRojstva'
                            },
                            {
                                src: 'zavarovalec_davcnaStevilka',
                                dst: 'zavarovanec_davcnaStevilka'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovUlica',
                                dst: 'zavarovanec_naslov_naslovUlica'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovPosta',
                                dst: 'zavarovanec_naslov_naslovPosta'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovKraj',
                                dst: 'zavarovanec_naslov_naslovKraj'
                            }
                        ]}

                         />

                        <fieldset>
                            <legend>Osnovni podatki</legend>
                            <div className="row">
                                <ImeField prefix="zavarovanec_" onImeChange={handleFieldChange} onPriimekChange={handleFieldChange} />
                            </div>
                            
                            <div className="row">
                                <EmailField prefix="zavarovanec_" onChange={handleFieldChange} />
                                <MobitelField prefix="zavarovanec_" onChange={handleFieldChange} />
                            </div>
                            <div className="row">
                                <SpolField prefix="zavarovanec_" onChange={handleFieldChange} />
                            </div>
                            <DatumRojstvaField prefix="zavarovanec_" onChange={handleFieldChange} conf={{deltaMin: 14, deltaMax: 74}} />
                            <DrzavaRojstvaTextField prefix="zavarovanec_" onChange={handleFieldChange} />
                            <DavcnaStevilkaField prefix="zavarovanec_" onChange={handleFieldChange} />
                        </fieldset>
                        <fieldset>
                            <legend>Naslov prebivališča</legend>
                            <NaslovField prefix="zavarovanec_naslov_" onChange={handleFieldChange} />
                        </fieldset>
                        <fieldset>
                            <legend>Dodatni podatki zavarovanca</legend>
                            <div className="row">
                                <PoklicField prefix="zavarovanec_" onChange={handleFieldChange} />
                                <DediscinaField prefix="zavarovanec_upravicenecsmrt_" onChange={handleFieldChange} />
                                <div className="col s12">
                                    <DediscinaDrugeOsebeField prefix="zavarovanec_upravicenecsmrt_ostali_" />
                                </div>
                            </div>
                            
                        </fieldset>
                    </div>

                    <div id="paket" className="col s12 center">
                        <fieldset>
                            <legend>Ponudba paketov</legend>
                            <AllianzNezgodaTable />
                        </fieldset>
                        <fieldset>
                            <legend>Premija</legend>
                            <PaketField prefix="zavarovanec_" onChange={handleFieldChange} />
                            <HiddenField prefix="rootponudba_" onChange={handleFieldChange} name="placiloPrvePremija" value="SEPA" />
                            <FrekvencaField prefix="nacinplacila_" onChange={handleFieldChange} />
                            <CenaField prefix="zavarovanec_premija_" onChange={handleFieldChange} />
                        </fieldset>
                        
                    </div>

                    <div id="sepa" className="col s12">
                    <PrenosPodatkovField
                        prefix="meta_"
                        name="kopiranSepaPlacnik"
                        kopirajPodatke={kopirajPodatke}
                        onChange={handleFieldChangePrenosPodatkov}
                        texts={{
                            'legendText': "Izbira plačnika",
                            'neKopirajText': "druga oseba",
                            'kopirajText': "zavarovalec"
                        }}

                        mapFields={[
                            {
                                src: 'zavarovalec_ime',
                                dst: 'sepaplacnik_ime'
                            },
                            {
                                src: 'zavarovalec_priimek',
                                dst: 'sepaplacnik_priimek'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovUlica',
                                dst: 'sepaplacnik_naslov_naslovUlica'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovPosta',
                                dst: 'sepaplacnik_naslov_naslovPosta'
                            },
                            {
                                src: 'zavarovalec_naslov_naslovKraj',
                                dst: 'sepaplacnik_naslov_naslovKraj'
                            }]} />

                        <fieldset>
                            <legend>Plačnik</legend>
                            <div className="row">
                                <ImeField prefix="sepaplacnik_" onImeChange={handleFieldChange} onPriimekChange={handleFieldChange} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Naslov prebivališča</legend>
                            <NaslovField prefix="sepaplacnik_naslov_" onChange={handleFieldChange} />
                        </fieldset>
                        <fieldset>
                            <legend>Transakcijski račun</legend>
                            <IbanField prefix="sepaplacnik_" 
                                onChange={handleFieldChange} 
                                relatedInputs={
                                {'imeBankeInput': imeBankeInput}} 
                                isRequired={true} 
                            />
                            <ImeBankeField ref={imeBankeInput} 
                                prefix="sepaplacnik_" 
                                onChange={handleFieldChange} 
                                isRequired={true}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Izjava</legend>
                            <div className="center">Allianz Slovenija, zavarovalna podružnica, Dunajska cesta 156, 1000 Ljubljana<br />
                                Identifikacijska oznaka: SI10ZZZ67112510<br /><br />
                                Vrsta plačila: Periodična obremenitev 18. v mesecu, razen 1. premija kadarkoli v mesecu<br /><br />
                                S to izjavo dovoljujem odtegovanje premije preko obrazca SEPA od osebnega dohodka, in sicer {frekvencaPlacila === 'MESECNO' && <b>mesečno</b>}{frekvencaPlacila === 'LETNO' && <b>letno</b>}, v višini <b>{premijaBruto}</b> evrov z DPZP.<br /><br />
                                Izjavljam, da so vsi na pristopni izjavi podani podatki popolni in resnični.<br />Sprejemam, da ima zavarovalnica pravico do popravka računskih in drugih napak.
                                <br /><br />
                                Pristopna izjava velja kot dokazilo vstopa v nezgodno zavarovanje, vendar le, če je podpisana s strani zavarovanca/-ev in jo je zavarovalnica prejela. Ne glede na to, da je pristopna izjava podpisana in jo je zavarovalnica prejela, bo veljavna le, če je skladna s seznamom zavarovancev, ki je priloga zavarovalni polici. Ta pristopna izjava je v enem izvodu. Priporočamo, da si zavarovanec za potrebe lastne evidence to izjavo kopira in shrani.
                            </div>

                            <PodpisField signatureStatus="CLOSED"  prefix="sepaplacnik_" signatureFor="payer" updateFormDataField="podpisPlacnika" onPodpisFieldChange={handlePodpisFieldChange} />
                            <HiddenField prefix="sepaplacnik_" onChange={handleFieldChange} name="krajPodpisa" value={krajPodpisa} />
                        </fieldset>
                    </div>

                    <div id="izjava" className="col s12">
                        <div className="row">
                            <div className="col s12">
                                <fieldset>
                                    <legend>Pristopna izjava</legend>
                                    <p>
                                        S to pristopno izjavo podpisani potrjujem, da sem seznanjen z dokumentom, ki vsebuje informacije o zavarovalnem produktu IPID-Nezgodno zavarovanje oseb (IPIDONSI47107180319), Informacije o obdelavi podatkov-IOOP (SI -KP01-2020-1), Informacije za zavarovalca (SI-K02-930-3). Hkrati potrjujem, da sem seznanjen in sprejemam Splošne pogoje za prostovoljno nezgodno zavarovanje oseb (SI-471-0718), skupaj s klavzulami, ki vključujejo tudi Tabelo invalidnosti zaradi nezgode, ki so sestavni del zavarovalne pogodbe.<br /><br />
                                        Dovoljujem, da posredovane osebne podatke obdeluje Allianz Slovenija, zavarovalna podružnica in sicer za namen
                                        sklenitve in izvajanja zavarovanja skladno s to pristopno izjavo.<br /><br />
                                        Z izbiro načina obveščanja po elektronski pošti in svojim podpisom podajam izrecno soglasje Allianz Slovenija,
                                        podružnica, da me o vseh zadevah v zvezi z vsemi sklenjenimi in bodočimi zavarovalnimi pogodbami lahko obvešča izključno po elektronski pošti. To vključuje vse obveščanje, kot ga določa Zakon o zavarovalništvu, vključno z informacijami o zavarovalnem produktu (IPID), ter pošiljanjem UPN in drugih obvestil po elektronski pošti. Seznanjen sem, da moram spremembo elektronskega naslova nemudoma sporočiti zavarovalnici, ter da lahko kadarkoli prekličem soglasje za obveščanje, in sicer s pisno zahtevo, poslano po elektronski pošti na e-naslov
                                        ali po navadni pošti na sedež zavarovalnice.<br /><br />
                                        S podpisom potrjujem, da sem zavarovalnemu distributerju pravočasno posredoval/a vse informacije, na podlagi
                                        katerih je lahko opredelil moje zahteve in potrebe glede izbranega zavarovalnega produkta. Potrjujem, da je zavarovalnica izdelala ponudbo/zavarovalno polico izključno na podlagi informacij, ki jih je prejela od zavarovalnega distributerja.<br /><br />
                                        Od distributerja nisem prejel/a nasvetov ali priporočil. Odločitev o sklenitvi zavarovalne pogodbe sem sprejel/-a
                                        sam/a, na temelju prejetih objektivnih informacij o zavarovalnem produktu.<br /><br />
                                        S svojim podpisom potrjujem, da so mi bile pred pristopom k zavarovanju (sklenitvijo zavarovanja) opredeljene
                                        potrebe in zahteve ter da mi je bil pred sklenitvijo zavarovanja izročen Dokument z informacijami o zavarovalnem produktu. Prav tako potrjujem, da sem prejel/a vso ostalo, v Pristopni izjavi navedeno, (pred)pogodbeno dokumentacijo (pogoje in klavzule, ki so sestavni del te Pristopne izjave).<br /><br />
                                        S podpisom te pristopne izjave soglašam, da se za zavarovalno pogodbo uporablja slovensko pravo. Skladno z 927. členom Obligacijskega zakonika je ta pogodba sklenjena s plačilom zavarovalne premije.<br /><br />
                                        Pristopna izjava je sestavni del zavarovalne police. Podpis zavarovanca na tej pristopni izjavi nadomešča podpis
                                        zavarovanca na zavarovalni polici.
                                        Pristopna izjava velja kot dokazilo vstopa v nezgodno zavarovanje, vendar le, če je podpisana s strani zavarovanca/-ev

                                        in jo je zavarovalnica prejela. Ne glede na to, da je pristopna izjava podpisana in jo je zavarovalnica prejela, bo veljav-
                                        na le, če je skladna s seznamom zavarovancev, ki je priloga generalni polici. Ta pristopna izjava je v enem izvodu.

                                        Priporočamo, da si zavarovanec za potrebe lastne evidence to izjavo shrani in natisne.
                                    </p>
                                </fieldset>
                                <fieldset>
                                    <legend>Soglasje za obveščanje</legend>
                                    <div className="col s6">
                                        <p>
                                            V zvezi z izvajanjem sklenjene pogodbe morate izbrati naslednji način obveščanja:
                                        </p>
                                        <AgreeField prefix="zavarovanec_soglasje_" name="email" onChange={handleFieldChange}
                                            isRequired={true}
                                            text="po elektronski pošti"
                                        />
                                    </div>

                                    <div className="col s6">
                                        <div className="row">
                                            <div className="col s12">
                                                <p>
                                                    Elektronska pošta za obveščanje:
                                                </p>
                                                <EmailField
                                                    prefix="zavarovanec_soglasje_"
                                                    name="emailZaObvescanje"
                                                    onChange={handleFieldChange}
                                                    styles={{
                                                        gridClasses: ''
                                                    }}
                                                />
                                                <div className="hide">
                                                    <PrenosPodatkovField
                                                        prefix="meta_"
                                                        name="kopiranEmailZaObvescanje"
                                                        kopirajPodatke={true}
                                                        onChange={handleFieldChangeRaw}
                                                        texts={{
                                                            'legendText': "Zavarovanec želi za obvešanje uporabiti",
                                                            'neKopirajText': "drug naslov",
                                                            'kopirajText': "že podan naslov"
                                                        }}

                                                        mapFields={[
                                                            {
                                                                src: 'zavarovalec_email',
                                                                dst: 'zavarovanec_soglasje_emailZaObvescanje'
                                                            }]}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col s12">
                                        <p>
                                            V kolikor v zgornjem polju ne boste določili elektronskega naslova, se bo za obveščanje uporabilo že podan elektronski naslov zavarovalca.
                                        </p>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Podpis izjave</legend>
                                    <PodpisField signatureStatus="CLOSED"  prefix="produkt_" signatureFor="agent" updateFormDataField="podpisSvetovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                                    <PodpisField signatureStatus="CLOSED"  prefix="produkt_" signatureFor="customer" updateFormDataField="podpisZavarovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                                    <HiddenField prefix="produkt_" name="krajPodpisa" onChange={handleFieldChange} value={krajPodpisa} />
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div id="gdpr" className="col s12">
                        <fieldset>
                            <legend>Eureko privolitvena izjava</legend>
                            <DaNeField prefix="privolitvenaeureko_privolitev_" stringId="q31" onChange={handleFieldChange} question="S privolitvijo v tem poglavju sem seznanjen in soglašam, da se moji osebni podatki, navedeni na tej privolitveni izjavi in drugi osebni podatki, pridobljeni v postopku sklepanja in izvajanja zavarovalne pogodbe, razen posebnih vrst osebnih podatkov (npr. podatki o zdravstvenem stanju), obdelujejo za potrebe izvajanja bonitetnih sistemov, oz. nudenja ugodnosti ter segmentirano neposredno trženje na podlagi profilov, kar pomeni oblikovanje meni prilagojenih ponudb različnih zavarovalnih produktov, ter drugih novostih s področja zavarovalništva in finančnih storitev ter vabil na dogodke na podlagi ocenjevanja in predvidevanja mojih interesov, ekonomskega položaja, potreb, ipd." />
                            <DaNeField prefix="privolitvenaeureko_privolitev_" stringId="q32" onChange={handleFieldChange} question="Soglašam tudi, da se moji osebni podatki za isti zgoraj izbrani namen, vključno z že oblikovanimi profili, posredujejo družbam v Republiki Sloveniji, ki imajo z družbo EUREKO sklenjeno pogodbo o zavarovalnem zastopanju ali posredovanju, na podlagi česar bodo le-te družbe lahko oblikovale ter me seznanjale s svojimi, meni prilagojenimi ponudbami. Seznam vseh družb je na vpogled v družbi EUREKO." />
                            <p>
                                Za zgoraj navedene namene me lahko družba EUREKO in družbe, s katerimi ima le-ta sklenjene pogodbe o zavarovalnem zastopanju in posredovanju, kontaktirajo preko:
                            </p>
                            <AgreeField prefix="privolitvenaeureko_privolitev_q33_" name="elektronskaPosta" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="po elektronski pošti"
                            />
                            <AgreeField prefix="privolitvenaeureko_privolitev_q33_" name="SMS" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="sporočil po telefonu (SMS)"
                            />
                            <AgreeField prefix="privolitvenaeureko_privolitev_q33_" name="klicPoTelefonu" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="klica po telefonu"
                            />
                            <AgreeField prefix="privolitvenaeureko_privolitev_q33_" name="posta" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="pošte"
                            />
                            <AgreeField prefix="privolitvenaeureko_privolitev_q33_" name="drugePoti" onChange={handleFieldChange}
                                isRequired={selectIsKanalRequired}
                                text="drugih poti"
                            />

                            <PodpisField signatureStatus="CLOSED"  prefix="privolitvenaeureko_" signatureFor="customer" updateFormDataField="podpisZavarovalec" onPodpisFieldChange={handlePodpisFieldChange} />
                            <HiddenField prefix="privolitvenaeureko_" name="krajPodpisa" onChange={handleFieldChange} value={krajPodpisa} />
                        </fieldset>
                    </div>

                    <div id="form-final" className="col s12">
                        <PredogledArea onPreviewClick={handlePreviewClick} requestId="preview-with-signatures" pdfContent={signedPdfContent} />
                        <FormSubmitField formStatus={status} />
                    </div>

                </div>

                <div className="row">
                    <div className="col m4">
                        <FormStepButtonField role="previous" allSteps={allSteps} currentStep={currentStep} onClick={handleStepPreviousClick} />
                    </div>
                    <div className="col m4 offset-m3">
                        <FormStepButtonField role="next" allSteps={allSteps} currentStep={currentStep} onClick={handleStepNextClick} />
                    </div>
                </div>
            </form>
            

    );

}


